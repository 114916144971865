import { SherpathContentTypeDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { ActiveSyllabusItemTypeDto, SyllabusItemTypeDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';

export const COURSE_NAME_MAX_LENGTH = 250;

export const invalidDateErrorMessage = 'Invalid Date';
export const emptyCourseNameErrorMessage = 'Course name cannot be empty';
export const maxCourseNameErrorMessage = `Course name cannot exceed length of ${COURSE_NAME_MAX_LENGTH}`;
export const invalidWeeksMessage = 'Must be a whole number greater than zero';

export enum courseCreateMethod {
  NEW = 'NEW',
  COPY = 'COPY'
}

export enum sectionTitleMethod {
  WEEK = 'WEEK',
  UNIT = 'UNIT',
  MODULE = 'MODULE',
  CUSTOM = 'CUSTOM',
}

export enum contentCreateMethod {
  TEMPLATE = 'TEMPLATE',
  BLANK = 'BLANK',
}

export enum SherpathContentType {
  NAQ = 'naq',
}

export const SherpathContentTypeMap: Partial<Record<SherpathContentType | SherpathContentTypeDto | SyllabusItemTypeDto, {
  singular: string;
  plural: string;
}>> = {
  [SherpathContentTypeDto.CASE_STUDY]: {
    singular: 'Case Study',
    plural: 'Case Studies'
  },
  [SherpathContentTypeDto.POWERPOINT]: {
    singular: 'Powerpoint',
    plural: 'Powerpoints'
  },
  [SherpathContentTypeDto.GROUP_ACTIVITY]: {
    singular: 'Group Activity',
    plural: 'Group Activities'
  },
  [SherpathContentType.NAQ]: {
    singular: 'NAQ',
    plural: 'NAQs'
  },
  [SherpathContentTypeDto.LESSON]: {
    singular: 'Lesson',
    plural: 'Lessons'
  },
  [SherpathContentTypeDto.SIMULATION]: {
    singular: 'Simulation',
    plural: 'Simulations'
  },
  [SherpathContentTypeDto.SKILL]: {
    singular: 'Skill',
    plural: 'Skills'
  },
  [SherpathContentTypeDto.EBOOK]: {
    singular: 'Ebook Reading',
    plural: 'Ebook Readings'
  },
  [ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT]: {
    singular: 'Shadow Health',
    plural: 'Shadow Healths'
  }
};
