import { get } from 'lodash';
import { CatalogWithExternalEntitiesDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { RecContentItemDto } from '../../apis/rec-gateway/rec-gateway.dtos';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { EvolveProductDto } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { AssignmentDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  getInteractiveReview,
  getInteractiveReviewText,
  getSubtitle,
  getTitle
} from '../catalog/catalog.utilities';
import { MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH } from '../course-plan/syllabus.constants';

export const getInteractiveReviewAssignmentInfo = (
  catalog: CatalogWithExternalEntitiesDto,
  contentItem: RecContentItemDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  evolveProducts: EvolveProductDto[]
): Partial<AssignmentDto> => {
  const contentId = get(contentItem, 'attributes.contentId', null);
  const interactiveReviewItem = getInteractiveReview(contentItem, catalog);
  const topicText = getInteractiveReviewText(interactiveReviewItem, catalog);
  return {
    assignmentGoals: [{
      id: null,
      goal: interactiveReviewItem._parsedData.questionIdList ? interactiveReviewItem._parsedData.questionIdList.length : null,
      vtwId: contentId,
      text: null,
    }],
    assignmentTopics: [{
      id: null,
      text: topicText,
      vtwId: interactiveReviewItem._parsedData ? interactiveReviewItem._parsedData.topicVtwId : null,
    }],
    assignmentQuestions: interactiveReviewItem._parsedData && interactiveReviewItem._parsedData.questionIdList
      && interactiveReviewItem._parsedData.questionIdList.map((questionId, index) => ({
        questionVtwId: questionId,
        displayOrder: index,
      })),
    title: contentItem ? getTitle(contentItem, catalog, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : '',
    subtitle: contentItem ? getSubtitle(contentItem, catalog, primaryTaxonomies, evolveProducts, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : '',
    contentId
  };
};
