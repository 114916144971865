import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import { buildUpdatedItemsText } from '../../components/move-modal/moveModal.utilities';
import { getAllNestedSyllabusItemChildren } from './syllabus.utilities';
import { AssignmentDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';

export const buildSuccessRemoveToastText = (syllabusItemsToDelete: SyllabusItemDto[], assignments: AssignmentDto[]) => {
  const removedItemsCount = syllabusItemsToDelete.length;
  const removedItemsText = buildUpdatedItemsText(syllabusItemsToDelete, assignments);
  if (removedItemsCount === 1) {
    return <>{removedItemsText} was removed</>;
  }
  return <>{removedItemsText} were removed</>;
};

export const getSyllabusItemsToDelete = (allSyllabusItems: SyllabusItemDto[], rootSyllabusItemsToDelete: SyllabusItemDto[]): SyllabusItemDto[] => {
  const allNestedSyllabusItemsToDelete = rootSyllabusItemsToDelete.map(item => getAllNestedSyllabusItemChildren(allSyllabusItems, item));
  return [].concat([], ...rootSyllabusItemsToDelete, ...allNestedSyllabusItemsToDelete);
};
