import React, { ReactElement } from 'react';
import { get } from 'lodash';
import { IntlShape } from 'react-intl';
import { SyllabusItemDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import {
  getAssociatedAssignment,
  getSyllabusItemConfig
} from '../../pages/course-plan/syllabus.utilities';
import { ActiveSyllabusItemTypeDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { LANGUAGE_KEY } from '../../translations/message.constants';
import { AssignmentDto } from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { Messages } from '../../translations/message.models';

export type BuildSuccessToastTextProps = {
  updatedSyllabusItems: SyllabusItemDto[];
  title: string;
  assignments: AssignmentDto[];
  onDestinationTitleClick: () => void;
  onResourceTitleClick: () => void;
  messages: Messages;
  intl: IntlShape;
}

const getSingleResourceTitle = (
  updatedSyllabusItem: SyllabusItemDto,
  associatedAssignment: AssignmentDto,
): ReactElement => {
  return (
    <>
      {getSyllabusItemConfig(updatedSyllabusItem).displayName}
      <strong> {get(associatedAssignment, 'title', updatedSyllabusItem.title)}</strong>
    </>
  );
};

export const buildTextForHiddenSyllabusItems = (hiddenSelectedNodes: SyllabusItemDto[]) => {
  const hiddenFolders = hiddenSelectedNodes.filter(item => item.type === ActiveSyllabusItemTypeDto.FOLDER);
  const hiddenResources = hiddenSelectedNodes.filter(item => item.type !== ActiveSyllabusItemTypeDto.FOLDER);
  if (hiddenFolders.length > 0 && hiddenResources.length > 0) {
    return (
      <div className="u-els-margin-bottom">
        The action will move/reorder only the items selected. When filters are active,
        <strong> {hiddenFolders.length} hidden {hiddenFolders.length === 1 ? 'folder' : 'folders'}</strong>
        <span> and </span>
        <strong>{hiddenResources.length} hidden {hiddenResources.length === 1 ? 'resource' : 'resources'} </strong>
        in the selected folders will be moved/reordered.
      </div>
    );
  }
  if (hiddenFolders.length > 0) {
    return (
      <div className="u-els-margin-bottom">
        The action will move/reorder only the items selected. When filters are active,
        <strong> {hiddenFolders.length} hidden {hiddenFolders.length === 1 ? 'folder' : 'folders'} </strong>
        in the selected folders will be moved/reordered.
      </div>
    );
  }
  if (hiddenResources.length > 0) {
    return (
      <div className="u-els-margin-bottom">
        The action will move/reorder only the items selected. When filters are active,
        <strong> {hiddenResources.length} hidden {hiddenResources.length === 1 ? 'resource' : 'resources'} </strong>
        in the selected folders will be moved/reordered.
      </div>
    );
  }
  return <></>;
};

export const buildUpdatedItemsText = (
  movedSyllabusItems: SyllabusItemDto[],
  assignments: AssignmentDto[],
  onTitleClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  hiddenSelectedNodes: SyllabusItemDto[] = []
// eslint-disable-next-line sonarjs/cognitive-complexity
): ReactElement => {
  if (movedSyllabusItems.length === 1) {
    const assignment = getAssociatedAssignment(movedSyllabusItems[0], assignments);
    const renderContent = getSingleResourceTitle(movedSyllabusItems[0], assignment);
    return onTitleClick
      ? (
        <button type="button" className="u-els-anchorize" onClick={onTitleClick}>
          {renderContent}
        </button>
      )
      : (
        <>
          {hiddenSelectedNodes.length !== 0 && buildTextForHiddenSyllabusItems(hiddenSelectedNodes)}
          {renderContent}
        </>
      );
  }

  const folders = movedSyllabusItems.filter(x => x.type === ActiveSyllabusItemTypeDto.FOLDER);
  const resources = movedSyllabusItems.filter(x => x.type !== ActiveSyllabusItemTypeDto.FOLDER);

  if (folders.length > 0 && resources.length > 0) {
    return (
      <>
        {hiddenSelectedNodes.length !== 0 && buildTextForHiddenSyllabusItems(hiddenSelectedNodes)}
        <strong>{folders.length} {folders.length === 1 ? 'folder' : 'folders'}</strong>
        <span> and </span>
        <strong>{resources.length} {resources.length === 1 ? 'resource' : 'resources'}</strong>
      </>
    );
  }

  if (folders.length > 0) {
    return (
      <>
        {hiddenSelectedNodes.length !== 0 && buildTextForHiddenSyllabusItems(hiddenSelectedNodes)}
        <strong>{folders.length} {folders.length === 1 ? 'folder' : 'folders'}</strong>
      </>
    );
  }

  if (resources.length > 0) {
    return (
      <>
        {hiddenSelectedNodes.length !== 0 && buildTextForHiddenSyllabusItems(hiddenSelectedNodes)}
        <strong>{resources.length} {resources.length === 1 ? 'resource' : 'resources'}</strong>
      </>
    );
  }

  return <></>;

};

export const buildSuccessUnassignToastText = (
  assignmentToUnassign: AssignmentDto[],
) => {
  if (assignmentToUnassign.length === 1) {
    return (
      <>
        <strong>{assignmentToUnassign[0].title}</strong>
      </>
    );
  }
  return (
    <>
      <strong>{assignmentToUnassign.length} {assignmentToUnassign.length === 1 ? 'assignment' : 'assignments'}</strong>
    </>
  );
};

export const buildSuccessMoveToastText = (
  {
    updatedSyllabusItems,
    title,
    assignments,
    onDestinationTitleClick,
    onResourceTitleClick,
    messages,
    intl
  }: BuildSuccessToastTextProps
): ReactElement => {
  const messageArguments = {
    folderName: <button type="button" className="u-els-anchorize" onClick={onDestinationTitleClick}>{title}</button>,
    assignmentTypeAndName: buildUpdatedItemsText(updatedSyllabusItems, assignments, onResourceTitleClick),
    numberOfSyllabusItem: updatedSyllabusItems.length
  };
  const messageDescription = {
    id: LANGUAGE_KEY.TOAST_SUCCESS_MOVE_SYLLABUS,
    defaultMessage: messages.TOAST_SUCCESS_MOVE_SYLLABUS,
    description: ''
  };
  return (
    <>
      {intl.formatMessage(messageDescription, messageArguments)}
    </>
  );
};

export const buildAddedItemsText = (
  addedSyllabusItems: SyllabusItemDto[],
  assignments: AssignmentDto[],
  onTitleClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
): ReactElement => {
  if (addedSyllabusItems.length === 1) {
    const assignment = getAssociatedAssignment(addedSyllabusItems[0], assignments);
    const renderContent = getSingleResourceTitle(addedSyllabusItems[0], assignment);
    return onTitleClick
      ? (
        <button type="button" className="u-els-anchorize" onClick={onTitleClick}>
          {renderContent}
        </button>
      )
      : renderContent;
  }

  if (addedSyllabusItems.length > 1) {
    return (
      <>
        <strong>{addedSyllabusItems.length} resources</strong>
      </>
    );
  }

  return <></>;

};

export const buildSuccessAddToastText = (
  {
    updatedSyllabusItems,
    title,
    assignments,
    onDestinationTitleClick,
    onResourceTitleClick,
    messages,
    intl
  }: BuildSuccessToastTextProps
): ReactElement => {
  const messageArguments = {
    folderName: <button type="button" className="u-els-anchorize" onClick={onDestinationTitleClick}>{title}</button>,
    assignmentTypeAndName: buildAddedItemsText(updatedSyllabusItems, assignments, onResourceTitleClick),
    numberOfSyllabusItem: updatedSyllabusItems.length
  };
  const messageDescription = {
    id: LANGUAGE_KEY.TOAST_SUCCESS_ADD_SYLLABUS,
    defaultMessage: messages.TOAST_SUCCESS_ADD_SYLLABUS,
    description: ''
  };
  return (
    <>
      {intl.formatMessage(messageDescription, messageArguments)}
    </>
  );
};

export const buildSuccessUpdateToastText = (
  {
    updatedSyllabusItems,
    title,
    assignments,
    onDestinationTitleClick,
    onResourceTitleClick,
    messages,
    intl
  }: BuildSuccessToastTextProps
): ReactElement => {
  const messageArguments = {
    folderName: <button type="button" className="u-els-anchorize" onClick={onDestinationTitleClick}>{title}</button>,
    assignmentTypeAndName: buildAddedItemsText(updatedSyllabusItems, assignments, onResourceTitleClick),
    numberOfSyllabusItem: updatedSyllabusItems.length
  };
  const messageDescription = {
    id: LANGUAGE_KEY.TOAST_SUCCESS_UPDATE_SYLLABUS,
    defaultMessage: messages.TOAST_SUCCESS_UPDATE_SYLLABUS,
    description: ''
  };
  return (
    <>
      {intl.formatMessage(messageDescription, messageArguments)}
    </>
  );
};

export const buildSuccessUpdateAssignmentsToastText = (
  {
    updatedSyllabusItems,
    assignments,
    onResourceTitleClick,
    messages,
    intl
  }: Omit<BuildSuccessToastTextProps, 'title' | 'onDestinationTitleClick'>
): ReactElement => {
  const messageArguments = {
    assignmentTypeAndName: buildAddedItemsText(updatedSyllabusItems, assignments, onResourceTitleClick),
    numberOfAssignments: updatedSyllabusItems.length
  };
  const messageDescription = {
    id: LANGUAGE_KEY.TOAST_SUCCESS_UPDATE_ASSIGNMENTS,
    defaultMessage: messages.TOAST_SUCCESS_UPDATE_ASSIGNMENTS,
    description: ''
  };
  return (
    <>
      {intl.formatMessage(messageDescription, messageArguments)}
    </>
  );
};

export const folderHelperText = (syllabusNestLevelLimit) => `Folders cannot be nested beyond ${syllabusNestLevelLimit} levels`;
