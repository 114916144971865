export enum Action {
  COMPLETED = 'Completed',
  GRADED = 'Graded',
  STARTED = 'Started',
  ACTIVATED = 'Activated',
  MODIFIED = 'Modified',
  LOGGED_IN = 'LoggedIn',
  SUBMITTED = 'Submitted',
  DEACTIVATED = 'Deactivated',
  CREATED = 'Created',
  DELETED = 'Deleted',
}

export enum CaliperEntityType {
  ASSESSMENT = 'Assessment',
  ASSESSMENT_ITEM = 'AssessmentItem',
  ATTEMPT = 'Attempt',
  COURSE_SECTION = 'CourseSection',
  GROUP = 'Group',
  FILL_IN_BLANK_RESPONSE = 'FillinBlankResponse',
  MULTIPLE_CHOICE_RESPONSE = 'MultipleChoiceResponse',
  MULTIPLE_RESPONSE_RESPONSE = 'MultipleResponseResponse',
  RESPONSE = 'Response',
  PERSON = 'Person',
  SCORE = 'Score',
  SOFTWARE_APPLICATION = 'SoftwareApplication',
  MEMBERSHIP = 'Membership',
}

export enum SoftwareApplicationType {
  GRADESYNC = 'eols-gradebook-sync',
  ASSESSMENT = 'eols-assessment-service',
  EAQ = 'eaq',
  SHERPATH = 'sherpath',
}

export enum CaliperEventType {
  EVENT = 'Event',
  ASSESSMENT_EVENT = 'AssessmentEvent',
  ASSESSMENT_ITEM_EVENT = 'AssessmentItemEvent',
  GRADE_EVENT = 'GradeEvent',
  ASSIGNABLE_EVENT = 'AssignableEvent',
  SESSION_EVENT = 'SessionEvent',
}

export enum Role {
  LEARNER = 'Learner',
  INSTRUCTOR = 'Instructor',
  ADMINISTRATOR = 'Administrator',
}
