import { Application } from '../../apis/eols-app-link/eols-app-link.constants';
import { Role } from '../../apis/eols-caliper-commons/eols-caliper-commons.dtos';
import {
  EvolveProductDto,
  EvolveProductType,
  EvolveProductTypeKey
} from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';

export const pageSizeForCourseSwitcher = 75;

export const appSortOrder = [
  Application.SHER_EVOL,
  Application.SHERPATH,
  Application.EAQ,
  Application.HESI_NG,
  Application.HESI_LS,
  Application.SIMSNG,
  Application.EPM_SKILL,
  Application.PERFORMANCE,
  Application.KNOWLEDGECARD,
];

export const defaultApp = 'UNKNOWN';

export const HESI_NG_PRODUCT: EvolveProductDto = {
  bundleMemberProduct: [],
  components: [],
  eeoISBN: null,
  isbn: '9780323525695',
  productTypeKey: EvolveProductTypeKey.HESI_ASSESSMENT_NEXTGEN,
  productTypeName: 'HESI Assessment Next Generation',
  title: 'HESI Assessment Next Generation',
  type: EvolveProductType.EPRODUCT,
  vbID: null,
  vbId: null,
};

export const roleIdToRole = {
  Ins: Role.INSTRUCTOR,
  Stu: Role.LEARNER,
};
