export enum EbookFilterField {
  EBOOK_CHECKBOX = 'EBOOK_CHECKBOX',
  CHAPTER_CHECKBOX = 'CHAPTER_CHECKBOX',
  CHAPTER_DROPDOWN = 'CHAPTER_DROPDOWN',
}

export interface EbookFilterState {
  [K: string]: {
    selected: boolean;
    stageChapterSelected: boolean;
    stageChapterId: string;
    chapters: {
      id: string;
      selected: boolean;
    }[];
  };
}

export type EbookFilterChangeProps = {
  field: EbookFilterField;
  checkboxValue: boolean;
  dropdownValue: string;
  displayName: string;
  bookId: string;
  chapterId: string;
}
