import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { SupportTicketPostBodyDto } from './eols-rightnow-integration-api.dtos';

export const postGetSupportTicketUrl = (data: SupportTicketPostBodyDto): Promise<string> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<string>('/v1/supportTickets', {
      data,
    });
  });
};
