import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import {
  ResourceDto,
  SequenceDto,
  SkillStaticDataDto,
  LearningObjectDataDto,
  LearningObjectLessonAndModuleDto,
  ResourceInfoDto
} from './ocs-api-service.dtos';

export const fetchLessonTopics = (lessonId: string): Promise<string[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/lesson/vtw/${lessonId}/topics`);
  });
};

export const fetchSequence = (vtwId: string): Promise<SequenceDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/sequence/data/resource/${vtwId}`);
  });
};

export const fetchEvolveResource = (vtwId: string): Promise<ResourceDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/collectionObject/vtw/${vtwId}`);
  });
};

export const fetchEvolveResourcesData = (vtwIds: string[]): Promise<Record<string, string>> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/collectionObject/vtwIds/data', { data: vtwIds });
  });
};

export const fetchSkillStaticData = (skillVersionVtwId: string): Promise<SkillStaticDataDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/skill/vtw/${skillVersionVtwId}`);
  });
};

export const fetchLearningObjectData = (vtwId: string): Promise<LearningObjectDataDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/learningObject/vtw/${vtwId}/data`);
  });
};

export const fetchLearningObjectLessonAndModule = (vtwId: string): Promise<LearningObjectLessonAndModuleDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/learningObject/vtw/${vtwId}/lessonAndModule`);
  });
};

export const fetchResourceInfo = (vtwId: string): Promise<ResourceInfoDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/resourceInfo/vtw/${vtwId}`);
  });
};
