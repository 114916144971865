import { ELSStepperItem } from '@els/els-component-shared-ts-react';

export const SHERPATH_CASE_STUDY_EDITOR_PAGE_TITLE = 'Case Study Assignment';

export enum SherpathCaseStudyStep {
  STEP1 = 'STEP1',
}

export const SherpathCaseStudyDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Assignment',
    id: SherpathCaseStudyStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Assignment',
    title: 'Step 1',
  }
];
