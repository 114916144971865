import React from 'react';
import memoizeOne from 'memoize-one';

export const getInsertQuestionIcon = memoizeOne((iconClass: string, iconWrapperClass: string) => {
  return (
    <div className={iconWrapperClass || ''}>
      <svg
        name="insert-question"
        viewBox="0 0 128 128"
        fill="currentColor"
        width="1rem"
        height="1rem"
        className={`o-els-icon-svg o-els-icon-svg--1x u-els-display-block ${iconClass || ''}`}
      >
        <path d="M36 4v56h56V29.6L82 40.8V50H46V14h25l8.8-10zm52.8 2L65.6 32.2l-10.2-9.6-6.8 7.2 17.6 16.8 30-33.8zM36 68v56h56V68zm10 10h36v36H46z" />
      </svg>
    </div>
  );
});
