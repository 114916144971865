export const toDigitDecimal = (number: number, digit = 2) => number.toFixed(digit);

export const getHourAndMinuteFromSecond = (second: number): string => {
  if (second === 0) {
    return '0 min';
  }

  let minute = Math.floor(second / 60);
  let hour = 0;

  if (minute === 0) {
    return '1 min';
  }

  if (minute >= 60) {
    hour = Math.floor(minute / 60);
    minute -= 60 * hour;

    if (minute === 0) {
      return `${hour} hr`;
    }

    return `${hour} hr ${minute} min`;
  }

  return `${minute} min`;
};

export const convertCelsiusToFahrenheit = (celsius: string) => {
  const num = (parseFloat(celsius) * 9) / 5 + 32;
  return toDigitDecimal(num, 1);
};

export const convertFahrenheitToCelsius = (fahrenheit: string) => {
  const num = ((parseFloat(fahrenheit) - 32) * 5) / 9;
  return toDigitDecimal(num, 1);
};

export const convertInchToCm = (inch: number) => inch * 2.54;

export const convertCmToInch = (cm: number) => cm / 2.54;

export const inchToFeetInch = (inchInput: number) => {
  let feet = parseInt((inchInput / 12).toString(), 10);
  let inch = Math.round(inchInput % 12);
  if (inch === 12) {
    feet += 1;
    inch = 0;
  }
  return [feet, inch];
};

export const convertHeight = (feetText: string, inchText: string, cmText: string, feetInchToCm: boolean) => {
  if (feetInchToCm && feetText === '' && inchText === '') {
    return { heightFeet: '', heightInch: '', heightCm: '' };
  }
  if (!feetInchToCm && cmText === '') {
    return { heightFeet: '', heightInch: '', heightCm: '' };
  }
  let inch;
  let cm;
  let newFeet;
  let newInch;

  if (feetInchToCm) {
    const feet = feetText ? parseInt(feetText, 10) : 0;
    inch = inchText ? Math.round(parseFloat(inchText)) : 0;
    inch = inch < 12 ? feet * 12 + inch : inch;
    cm = convertInchToCm(inch);
    [newFeet, newInch] = inchToFeetInch(inch);
    return { heightFeet: `${newFeet}`, heightInch: `${newInch}`, heightCm: `${toDigitDecimal(cm, 1)}` };
  }

  cm = parseFloat(cmText) || 0;
  inch = convertCmToInch(cm);
  [newFeet, newInch] = inchToFeetInch(inch);
  return { heightFeet: `${newFeet}`, heightInch: `${newInch}`, heightCm: `${toDigitDecimal(cm, 1)}` };
};

export const ounceToGram = (ounce: number) => ounce * 28.349523125;

export const gramToOunce = (gram: number) => gram / 28.349523125;

export const ounceToPoundOunce = (ounceInput: number) => {
  let pound = parseInt((ounceInput / 16).toString(), 10);
  let ounce = Math.round(ounceInput % 16);
  if (ounce === 16) {
    pound += 1;
    ounce = 0;
  }
  return [pound, ounce];
};

export const ounceToKiloGram = (ounceInput: number) => {
  let gram = ounceToGram(ounceInput);
  const kg = parseInt((gram / 1000).toString(), 10);
  gram %= 1000;
  return [kg, gram];
};

export const gramToKgGram = (gramInput: number) => {
  const kg = parseInt((gramInput / 1000).toString(), 10);
  const gram = gramInput % 1000;
  return [kg, gram];
};

export const gramToPoundOunce = (gramInput: number) => {
  const ounce = gramToOunce(gramInput);
  return ounceToPoundOunce(ounce);
};

/* eslint-disable sonarjs/cognitive-complexity */
export const convertWeight = (poundText: string, ounceText: string, kgText: string, gramText: string, poundOunceToKgGram: boolean) => {
  const result = { weightPound: '', weightOunce: '', weightKg: '', weightGram: '' };
  if (poundOunceToKgGram && poundText === '' && ounceText === '') {
    return result;
  }
  if (!poundOunceToKgGram && kgText === '' && gramText === '') {
    return result;
  }
  if (poundOunceToKgGram) {
    const pound = poundText ? parseInt(poundText, 10) : 0;
    let ounce = ounceText ? Math.round(parseFloat(ounceText)) : 0;
    ounce = ounce < 16 ? pound * 16 + ounce : ounce;
    const [kg, gram] = ounceToKiloGram(ounce);
    const [newPound, newOunce] = ounceToPoundOunce(ounce);
    return { weightPound: `${newPound}`, weightOunce: `${newOunce}`, weightKg: `${kg}`, weightGram: `${toDigitDecimal(gram, 2)}` };
  }
  if (!poundOunceToKgGram) {
    const kg = kgText ? parseInt(kgText, 10) : 0;
    let gram = gramText ? parseFloat(gramText) : 0;
    gram = gram < 1000 ? kg * 1000 + gram : gram;
    const [pound, pounce] = gramToPoundOunce(gram);
    const [newKg, newGram] = gramToKgGram(gram);
    return { weightPound: `${pound}`, weightOunce: `${pounce}`, weightKg: `${newKg}`, weightGram: `${toDigitDecimal(newGram, 2)}` };
  }
  return result;
};
