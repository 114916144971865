import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import {
  EvolveTokenDto,
  ShadowHealthCourseDto
} from './evolve-user-management-client.dtos';

export const getShadowHealthRelatedCourses = (userId: string): Promise<ShadowHealthCourseDto[]> => {
  // https://elsevier.atlassian.net/browse/EVL-3671
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/evolveUser/${userId}/resourceListByProductType/shadow_health`);
  });
};

export const getEvolveToken = (): Promise<EvolveTokenDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post('/evolveUser/sso');
  });
};

export const goToShadow = (course: ShadowHealthCourseDto) => {
  //  https://elsevier.atlassian.net/browse/EVL-3768
  return getEvolveToken().then((tokenDto) => {
    return eolsBaseApi.get(
      `/evolveUser/accessDetails?identifier=${course.courseId}&role=${course.roleKey}&sessionId=${tokenDto.sessionId}`,
      null,
      { Authorization: `Bearer ${tokenDto.token.token}` }
    )
      .then((response: {
        data: {
          url: string;
        };
      }) => {
        if (response.data && response.data.url) {
          window.open(response.data.url, '_blank');
          return response.data.url;
        }
        return null;
      });

  });
};
