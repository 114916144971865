import { ELSStepperItem } from '@els/els-component-shared-ts-react';

export const SHERPATH_GROUP_ACTIVITY_EDITOR_PAGE_TITLE = 'Group Activity Assignment';

export enum SherpathGroupActivityStep {
  STEP1 = 'STEP1',
}

export const SherpathGroupActivityDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Assignment',
    id: SherpathGroupActivityStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Assignment',
    title: 'Step 1',
  }
];
