import moment from 'moment';
import {
  get,
} from 'lodash';
import {
  CatalogWithExternalEntitiesDto,
  SyllabusAssignmentDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  getLocalDateFromServicesUTC,
  isValidDate
} from '../../utilities/app.utilities';
import {
  OSMOSIS_VIDEO_EDITOR_PAGE_TITLE,
  OsmosisVieoDefaultSteps,
  OsmosisVideoStep,
} from './osmosis-video.constants';
import {
  BaseAssignmentEditorProps,
  BaseAssignmentEditorState
} from '../../hocs/with-base-assignment-editor/withBaseAssignmentEditor.hoc';
import {
  getDefaultAssignmentEditorState,
  updateStepsOnNav,
} from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.utilities';
import { AssignmentFormError } from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.models';
import {
  AssignmentDto,
  AssignmentType
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { RecContentItemDto } from '../../apis/rec-gateway/rec-gateway.dtos';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import {
  getSubtitle,
  getTitle
} from '../catalog/catalog.utilities';
import {
  MAX_ASSIGNMENT_TITLE_CHAR_LENGTH
} from '../../components/assignment-editor/assignment-editor.constant';
import { MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH } from '../course-plan/syllabus.constants';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { isGraded, isValidGradePoints } from '../../components/assignment-editor/assignment-editor.utilities';
import { EvolveProductDto } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';

export const getOsmosisVideoDefaultState = () => {
  return {
    ...getDefaultAssignmentEditorState(),
    assignmentType: AssignmentType.OSMOSIS_VIDEO,
    pageTitle: OSMOSIS_VIDEO_EDITOR_PAGE_TITLE,
    hasDueDate: true,
    showDueDate: true,
    showGrading: true,
    showDisplayName: true,
    steps: updateStepsOnNav(OsmosisVieoDefaultSteps, 0),
    isReadOnlyDisplayName: false
  };
};

export const validateOsmosisVideo = (
  syllabusAssignmentDto: SyllabusAssignmentDto,
  baseState: BaseAssignmentEditorState,
  baseProps: BaseAssignmentEditorProps
): AssignmentFormError[] => {
  const { messages } = baseProps;
  const errors = [];

  const assignmentGoals = get(syllabusAssignmentDto, 'assignment.assignmentGoals', []);

  if (!assignmentGoals.length) {
    errors.push({
      message: messages.ASSIGNMENT_CONTENT_ID_IS_EMPTY,
      stepId: OsmosisVideoStep.STEP1
    });
  }

  if (!syllabusAssignmentDto.assignment.title || !syllabusAssignmentDto.assignment.title.length) {
    errors.push({
      message: messages.ASSIGNMENT_TITLE_IS_EMPTY,
      stepId: OsmosisVideoStep.STEP1
    });
  }

  const availableDate = moment(syllabusAssignmentDto.assignment.availableDate).toDate();
  if (baseState.hasAvailableDate && !isValidDate(availableDate)) {
    errors.push({
      message: messages.INVALID_AVAILABILITY_DATE,
      stepId: OsmosisVideoStep.STEP1
    });
  }

  const dueDate = moment(syllabusAssignmentDto.assignment.dueDate).toDate();
  if (baseState.hasDueDate && !isValidDate(dueDate)) {
    errors.push({
      message: messages.INVALID_DUE_DATE,
      stepId: OsmosisVideoStep.STEP1
    });
  }

  if (baseState.hasDueDate
    && moment(getLocalDateFromServicesUTC(syllabusAssignmentDto.assignment.dueDate))
      .isSameOrBefore(moment(getLocalDateFromServicesUTC(syllabusAssignmentDto.assignment.availableDate)))) {
    errors.push({
      message: messages.DUE_DATE_BEFORE_AVAILABLE_DATE_ERROR_MESSAGE,
      stepId: OsmosisVideoStep.STEP1
    });
  }

  if (
    baseState.assignment
    && baseState.assignment.title
    && baseState.assignment.title.length > MAX_ASSIGNMENT_TITLE_CHAR_LENGTH
  ) {
    errors.push({
      message: messages.ENTRY_EXCEEDS_LENGTH_LIMIT,
      stepId: OsmosisVideoStep.STEP1,
    });
  }

  const {
    featureFlagsGrouped,
    courseSectionId
  } = baseProps;

  const showGradePoints = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.IS_CUSTOM_GRADE_POINTS_ENABLED,
    courseSectionId
  ) : false;

  if (
    baseState.assignment
    && showGradePoints
    && isGraded(baseState.assignment)
    && !isValidGradePoints(baseState.assignment.gradePoints)
  ) {
    errors.push({
      message: messages.INVALID_GRADE_POINTS,
      stepId: OsmosisVideoStep.STEP1,
    });
  }

  return errors;
};

export const getOsmosisVideoAssignmentInfo = (
  catalog: CatalogWithExternalEntitiesDto,
  contentItem: RecContentItemDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  evolveProducts: EvolveProductDto[]
): {
  assignmentGoals: AssignmentDto['assignmentGoals'];
  title: AssignmentDto['title'];
  subtitle: AssignmentDto['subtitle'];
  contentId: AssignmentDto['contentId'];
} => {
  const contentId = get(contentItem, 'attributes.contentId', null);
  return {
    assignmentGoals: [{
      id: null,
      goal: 100,
      vtwId: contentId,
      text: '',
    }],
    title: contentItem ? getTitle(contentItem, catalog, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : '',
    subtitle: contentItem ? getSubtitle(contentItem, catalog, primaryTaxonomies, evolveProducts, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : '',
    contentId
  };
};
