import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { RelatedHesiCourseDto } from './hesi-app-facade-service.dtos';

export const fetchRelatedHesiCourses = (userId: string): Promise<RelatedHesiCourseDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/hesi/app/user/${userId}/related?appId=hesi`);
  });
};
