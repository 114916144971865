import { orderBy } from 'lodash';
import moment from 'moment';
import {
  EvolveProductDto,
  EvolveProductTypeKey
} from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { Application } from '../../apis/eols-app-link/eols-app-link.constants';
import {
  Entitlement,
  UserCourse
} from './course-switcher.models';
import { RelatedHesiCourseDto } from '../../apis/hesi-app-facade-service/hesi-app-facade-service.dtos';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import {
  defaultApp,
  HESI_NG_PRODUCT,
} from './course-switcher.constants';
import { getEvolveProductsFromCourseSection } from '../../components/product-list/product-list.utilities';
import { ALL_OPTION_VALUE } from '../catalog/catalog.constants';
import { filterBySearchQuery } from '../../utilities/common.utilities';
import { ShadowHealthCourseDto } from '../../apis/evolve-user-management-client/evolve-user-management-client.dtos';

export const getEntitlements = (courseSection: CourseSectionDto): Entitlement[] => {
  return courseSection.entitlements.map((entitlement) => {

    if (!entitlement || !entitlement.data) {
      return {
        entitlement,
        evolveProduct: null
      };
    }

    return {
      entitlement,
      evolveProduct: JSON.parse(entitlement.data)
    };
  }).filter((item) => {
    return item.evolveProduct;
  });
};

export const getCourseApp = (courseSection: CourseSectionDto, hesiCourseIds: number[] = []): string => {

  if (hesiCourseIds.includes(courseSection.id)) {
    return Application.HESI_NG;
  }

  const entitlements = getEntitlements(courseSection);

  // TODO: Fix this assumption to always go to Courseware app
  // Right now we are just going to course switch all sherpath entitlements to Courseware as a POC
  // In the future we should be able to inspect the course section to determine if the course section
  // is a classic sherpath course or a courseware course
  if (entitlements.find((entitlement) => {
    return [
      EvolveProductTypeKey.SHERPATH_BOOK_ORGANIZED,
      EvolveProductTypeKey.SHERPATH_IA,
      EvolveProductTypeKey.SHERPATH_COMPONENT_NSS,
      EvolveProductTypeKey.SHERPATH_EBOOK_COMPONENT_NSS,
      EvolveProductTypeKey.SIMULATION_SIM_CHART_NG,
      EvolveProductTypeKey.SHADOW_HEALTH,
    ].includes(entitlement.evolveProduct.productTypeKey);
  })) {
    return Application.SHER_EVOL;
  }

  if (entitlements.find((entitlement) => {
    return [
      EvolveProductTypeKey.NURSING_CONCEPTS_IA
    ].includes(entitlement.evolveProduct.productTypeKey);
  })) {
    return Application.NCO;
  }

  if (entitlements.find((entitlement) => {
    return [
      EvolveProductTypeKey.EAQNG_IA,
      EvolveProductTypeKey.EAQ_IA,
    ].includes(entitlement.evolveProduct.productTypeKey);
  })) {
    return Application.EAQ;
  }

  if (entitlements.find((entitlement) => {
    return [
      EvolveProductTypeKey.HESI_CASE_STUDY,
      EvolveProductTypeKey.HESI_PATIENT_REVIEW,
      EvolveProductTypeKey.HESI_PRACTICE_TEST
    ].includes(entitlement.evolveProduct.productTypeKey);
  })) {
    return Application.HESI_LS;
  }

  return defaultApp;
};

export const ApplicationDisplayNameMap = {
  [Application.SHER_EVOL]: 'Sherpath',
  [Application.SHERPATH]: 'Sherpath',
  [Application.EAQ]: 'EAQ',
  [Application.HESI_NG]: 'HESI-NG',
  [Application.HESI_LS]: 'HESI-LS',
  [Application.NCO]: 'Nursing Concepts Online',
  [Application.SIMSNG]: 'SimChart',
  [Application.EPM_SKILL]: 'EPM Skill',
  [Application.PERFORMANCE]: 'Performance',
  [Application.KNOWLEDGECARD]: 'Knowledge Card',
  [defaultApp]: 'Unknown'
};

export const getHesiCourseIds = (relatedHesiCourses: RelatedHesiCourseDto[]) => {
  return relatedHesiCourses.reduce((courseIds, hesiCourse) => {
    const ids = hesiCourse.eolsUser.courseSections.map((courseSection) => {
      return courseSection.id;
    });
    return [
      ...courseIds,
      ...ids
    ];
  }, []);
};

export const getShadowHealthCoursesForCourseSwitcher = (
  shadowHealthCourses: ShadowHealthCourseDto[],
  shadowHealthEvolveProducts: EvolveProductDto[]
): UserCourse[] => {

  if (!shadowHealthCourses || !shadowHealthCourses.length) {
    return [];
  }

  return shadowHealthCourses.map((shadowHealthCourse): UserCourse => {
    return {
      shadowHealthCourse,
      userId: null,
      courseSection: null,
      evolveProducts: [shadowHealthEvolveProducts.find((shadowHealthEvolveProduct) => {
        return shadowHealthEvolveProduct.isbn === shadowHealthCourse.isbn;
      })],
      app: Application.SHADOW_HEALTH,
      appDisplay: ApplicationDisplayNameMap[Application.SHADOW_HEALTH],
      dateCreated: moment(shadowHealthCourse.enrollmentDate).toDate().getTime(),
      courseName: shadowHealthCourse.courseName,
    };
  });
};

export const getUniqueCourses = (
  userCourses: {
    userId: number;
    courseSections: {
      all: CourseSectionDto[];
      entitled: CourseSectionDto[];
    };
  }[],
  hesiCourseIds: number[],
  shadowHealthCourses: ShadowHealthCourseDto[],
  isHesiCoursesEnabled: boolean,
  shadowHealthEvolveProducts: EvolveProductDto[]
  // eslint-disable-next-line sonarjs/cognitive-complexity
): UserCourse[] => {

  const shadowHealthUserCourses = getShadowHealthCoursesForCourseSwitcher(shadowHealthCourses, shadowHealthEvolveProducts);

  if (!userCourses || !userCourses.length) {
    if (!shadowHealthUserCourses || !shadowHealthUserCourses.length) {
      return [];
    }
    return orderBy(shadowHealthUserCourses, (userCourse: UserCourse) => {
      return userCourse.dateCreated;
    });
  }

  const courses = userCourses.reduce((acc, userCourse) => {

    if (!userCourse.courseSections || !userCourse.courseSections.all || !userCourse.courseSections.all.length) {
      return acc;
    }

    return [
      ...acc,
      ...userCourse.courseSections.all.map((courseSection): UserCourse => {
        const app = getCourseApp(courseSection, hesiCourseIds);

        const evolveProducts = getEvolveProductsFromCourseSection(courseSection);

        if (app === Application.HESI_NG) {
          // HESI-NG courses do not have entitlements so hacking around it here by adding it
          evolveProducts.push(HESI_NG_PRODUCT);
        }

        const dateCreated = courseSection.createdAt || courseSection.updatedAt;

        return {
          shadowHealthCourse: null,
          userId: userCourse.userId,
          courseSection,
          evolveProducts,
          app,
          appDisplay: ApplicationDisplayNameMap[app],
          dateCreated: dateCreated ? moment(dateCreated).toDate().getTime() : null,
          courseName: courseSection.courseName,
        };
      }).filter((item) => {
        if (item.app === Application.HESI_NG) {
          return isHesiCoursesEnabled;
        }
        return userCourse.courseSections.entitled.find((entitledCourse) => {
          return entitledCourse.id === item.courseSection.id;
        });
      })
    ];
  }, shadowHealthUserCourses);

  return orderBy(courses, (userCourse: UserCourse) => {
    return userCourse.dateCreated;
  });
};

export const filterUserCoursesByQuery = (searchQueryWordList: string) => (course: UserCourse): boolean => {
  const courseNameWords = course.courseName.split(' ');
  const productWords = course.evolveProducts
    ? course.evolveProducts.reduce((acc, cur) => {
      return [...acc, ...cur.title.split(' '), cur.isbn];
    }, [])
    : [];
  return filterBySearchQuery(searchQueryWordList, [...courseNameWords, ...productWords]);
};

export const filterUserCoursesByApps = (appList: Application[]) => (course: UserCourse) => {
  if (appList === null || appList.length === 0) {
    return course;
  }
  return appList.includes(course.app as Application);
};

export const filterUserCoursesByProduct = (selectedProductIsbn: string) => (course: UserCourse) => {
  if (selectedProductIsbn === null || selectedProductIsbn === ALL_OPTION_VALUE) {
    return course;
  }
  return course.evolveProducts.some((product) => {
    return product.isbn === selectedProductIsbn;
  });
};
