import React from 'react';
import { prefix, DEFAULT_TAG } from './flex.constants';
import { FlexProps } from './flex.models';
import { generateFlexClasses } from './flex.utilities';

const defaultProps = {
  tag: DEFAULT_TAG // 'div'
};

export const FlexLayout = (props: FlexProps) => {
  const { children, tag, id } = props;
  const flexClasses = generateFlexClasses(props, prefix.LAYOUT);
  const layoutProps = { className: flexClasses, id };

  const layout = React.createElement(tag, layoutProps, children);
  return (
    <>
      { layout }
    </>
  );
};

FlexLayout.defaultProps = defaultProps;
