import { eolsBaseApi, withApiErrorLoggingOnly } from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import { EvolveProductTypeKey } from '../sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { UserDto } from '../sherpath-course-management-service/sherpath-course-management-service.dtos';
import { SystemType, CrosswalkUserDto, ProductDto } from './eols-user-management-service.dtos';

export const fetchUser = (userId: string): Promise<UserDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eolsUser/user/${userId}`);
  });
};

export const fetchCrosswalkUser = (userId: string, type: SystemType): Promise<CrosswalkUserDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(`/eolsUser/${userId}/type/${type}/crosswalk`);
  });
};

export const fetchUserProductList = (userId: string, types: EvolveProductTypeKey[]): Promise<ProductDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/eolsUser/${userId}/products/list`, {
      type: types
    }));
  });
};
