import { isEmpty } from 'lodash';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import {
  EvolveProductDto,
  EvolveProductTypeKey
} from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';

const hiddenProductTypes = [
  EvolveProductTypeKey.SHERPATH_EBOOK_COMPONENT_NSS,
  EvolveProductTypeKey.SHERPATH_COMPONENT_NSS,
];

export const getEvolveProductsFromCourseSection = (currentCourse: CourseSectionDto): EvolveProductDto[] => {
  if (isEmpty(currentCourse) || isEmpty(currentCourse.entitlements)) {
    return [];
  }

  return currentCourse.entitlements.reduce((acc, entitlement) => {

    if (!entitlement || !entitlement.data) {
      return acc;
    }

    const parsedEntitlement = JSON.parse(entitlement.data) as EvolveProductDto;
    if (!parsedEntitlement || !parsedEntitlement.productTypeKey || hiddenProductTypes.includes(parsedEntitlement.productTypeKey)) {
      return acc;
    }
    return [...acc, parsedEntitlement];
  }, []);
};
