import {
  contentCreateMethod,
  courseCreateMethod,
  sectionTitleMethod,
  SherpathContentType
} from '../pages/course-builder/courseBuilder.constants';
import {
  AppAction,
  Application
} from '../apis/eols-app-link/eols-app-link.constants';
import { LANGUAGE_KEY } from '../translations/message.constants';
import { NavItem } from '../components/header-nav/navigation.constants';
import { AssignmentType } from '../apis/eols-assessment-service/eols-assessment-service.dtos';
import { CourseBuilderField } from '../redux/courseware/courseware.models';
import { SyllabusItemTypeDto } from '../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { SherpathContentTypeDto } from '../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { AppLinkRedirectErrorMessage } from '../pages/app-link-redirect/app-link-redirect.constants';
import { SyllabusItemAction } from '../constants/content-type.constants';

export enum AnalyticsAction {
  COURSE_BUILT = 'COURSE_BUILT',
  RETURN_TO_COURSE_BUILDER_CLICK = 'RETURN_TO_COURSE_BUILDER_CLICK',
  COURSE_SETTINGS_UPDATED = 'COURSE_SETTINGS_UPDATED',
  RESOURCE_FILTER_ACTIVATED = 'RESOURCE_FILTER_ACTIVATED',
  SYLLABUS_ITEM_ACTION_CLICK = 'SYLLABUS_ITEM_ACTION_CLICK',
  NAV_ITEM_CLICK = 'NAV_ITEM_CLICK',
  COURSE_PLAN_TAB_CLICK = 'COURSE_PLAN_TAB_CLICK',
  SYLLABUS_ITEM_TITLE_CLICK = 'SYLLABUS_ITEM_TITLE_CLICK',
  SYLLABUS_ITEM_DATES_CLICK = 'SYLLABUS_ITEM_DATES_CLICK',
  SYLLABUS_ITEM_GRADE_CLICK = 'SYLLABUS_ITEM_GRADE_CLICK',
  FOLDER_RENAMED = 'FOLDER_RENAMED',
  VIEW_ALL_ATTEMPTS_CLICK = 'VIEW_ALL_ATTEMPTS_CLICK',
  STUDENT_SIMULATION_PERFORMANCE_MODAL_OPENED = 'STUDENT_SIMULATION_PERFORMANCE_MODAL_OPENED',
  LOGIN_LAUNCH = 'LOGIN_LAUNCH',
  APP_LINK_LAUNCH = 'APP_LINK_LAUNCH',
  APP_LINK_ERROR = 'APP_LINK_ERROR',
  APP_LINK_OUT = 'APP_LINK_OUT',
  CATALOG_ITEM_ADDED = 'CATALOG_ITEM_ADDED',
  CATALOG_ITEM_TITLE_CLICK = 'CATALOG_ITEM_TITLE_CLICK',
  EAQ_SELF_STUDY_CLICK = 'EAQ_SELF_STUDY_CLICK',
  HELP_LINK_CLICK = 'HELP_LINK_CLICK',
  SYLLABUS_ITEM_DRAGGED = 'SYLLABUS_ITEM_DRAGGED',
  COURSE_SWITCH_LINK_CLICK = 'COURSE_SWITCH_LINK_CLICK',
  COURSE_SWITCH_CLICK = 'COURSE_SWITCH_CLICK',
  BATCH_EDIT_TOGGLE_CLICK = 'BATCH_EDIT_TOGGLE_CLICK',
  DRAG_DROP_TOGGLE_CLICK = 'DRAG_DROP_TOGGLE_CLICK',
  BULK_EDITOR_APPLY_TO_ALL_CLICK = 'BULK_EDITOR_APPLY_TO_ALL_CLICK',
  BULK_EDITOR_SORT_BY_CHANGE = 'BULK_EDITOR_SORT_BY_CHANGE',
  BULK_EDITOR_SINGLE_FIELD_UPDATE = 'BULK_EDITOR_SINGLE_FIELD_UPDATE',
  BULK_EDITOR_CANCEL = 'BULK_EDITOR_CANCEL',
  BULK_EDITOR_RESET_ALL = 'BULK_EDITOR_RESET_ALL',
  BULK_EDITOR_SAVE = 'BULK_EDITOR_SAVE',
  AUTHESS_HEALTH_CHECK_SUCCESS = 'AUTHESS_HEALTH_CHECK_SUCCESS',
  AUTHESS_HEALTH_CHECK_FAIL = 'AUTHESS_HEALTH_CHECK_FAIL',
  AUTHESS_HEALTH_CHECK_ASSIGNMENT_FIXED = 'AUTHESS_HEALTH_CHECK_ASSIGNMENT_FIXED',
  API_ERROR = 'API_ERROR',
  EBOOK_CLICK = 'EBOOK_CLICK',
  EBOOK_PURCHASE_BINDER_READY_VERSION_CLICK = 'EBOOK_PURCHASE_BINDER_READY_VERSION_CLICK',
  EBOOK_PURCHASE_RELATED_PRODUCT_CLICK = 'EBOOK_PURCHASE_RELATED_PRODUCT_CLICK',
  TOGGLE_ALL_FOLDERS_CLICK = 'TOGGLE_ALL_FOLDERS_CLICK',
  TRAINING_LINK_CLICKED = 'TRAINING_LINK_CLICKED',
  MISSING_MAPPINGS = 'MISSING_MAPPINGS',
  OSMOSIS_VIDEO_PLAYER_EVENT = 'OSMOSIS_VIDEO_PLAYER_EVENT',
  MY_EVOLVE_LINK_CLICK = 'MY_EVOLVE_LINK_CLICK',
  SKILL_PLAYER_NEW_TAB_CLICK = 'SKILL_PLAYER_NEW_TAB_CLICK',
  EBOOK_ASSIGNMENT_SAVE_CLICK = 'EBOOK_ASSIGNMENT_SAVE_CLICK',
  SET_DEFAULT_TAB_CLICK = 'SET_DEFAULT_TAB_CLICK',
  SIMULATION_PLAYER_EVENT = 'SIMULATION_PLAYER_EVENT',
  STUDENT_LAUNCH_WARNING_MODAL_OPENED = 'STUDENT_LAUNCH_WARNING_MODAL_OPENED',
  STUDENT_LAUNCH_BY_INSTRUCTOR = 'STUDENT_LAUNCH_BY_INSTRUCTOR',
  SYLLABUS_ITEMS_WITH_SAME_ASSIGNMENT_FOUND = 'SYLLABUS_ITEMS_WITH_SAME_ASSIGNMENT_FOUND',
  SYLLABUS_ITEMS_WITH_DELETED_ASSIGNMENT_FOUND = 'SYLLABUS_ITEMS_WITH_DELETED_ASSIGNMENT_FOUND',
  UPDATE_NAME_PENCIL_CLICKED = 'UPDATE_NAME_PENCIL_CLICKED',
  UPDATE_NAME_LINK_OUT_TO_EVOLVE = 'UPDATE_NAME_LINK_OUT_TO_EVOLVE',
  ADD_NEW_USER_MODAL_OPENED = 'ADD_NEW_USER_MODAL_OPENED',
  REMOVE_USER_MODAL_OPENED = 'REMOVE_USER_MODAL_OPENED',
  NEW_USER_ADDED = 'NEW_USER_ADDED',
  USER_REMOVED = 'USER_REMOVED',
  COURSE_BUILD_MAKE_VISIBLE_NOW_CLICKED = 'COURSE_BUILD_MAKE_VISIBLE_NOW_CLICKED',
  COURSE_BUILD_MAKE_VISIBLE_NOW_SKIPPED = 'COURSE_BUILD_MAKE_VISIBLE_NOW_SKIPPED',
  SAME_TAB_WARNING_OPENED = 'SAME_TAB_WARNING_OPENED',
  EVOLVE_UNENROLL_FAILURE_HANDLED = 'EVOLVE_UNENROLL_FAILURE_HANDLED',
  TOGGLE_CATALOG_VIEW_CLICK = 'TOGGLE_CATALOG_VIEW_CLICK',
  CHATBOT_AI_LINK_CLICKED = 'CHATBOT_AI_LINK_CLICKED',
  CATALOG_FETCH_ACTION_TRACE = 'CATALOG_FETCH_ACTION_TRACE',
}

export enum AnalyticsRef {
  SIDEBAR = 'SIDEBAR',
  SYLLABUS = 'SYLLABUS',
  BATCH_ACTION_BAR = 'BATCH_ACTION_BAR',
}

export enum AnalyticsFilterType {
  CLINICAL_SKILLS_FILTER = 'CLINICAL_SKILLS_FILTER',
  EBOOK_FILTER = 'EBOOK_FILTER',
  STATUS_FILTER = 'STATUS_FILTER',
  TYPE_FILTER = 'TYPE_FILTER',
  GRADING_FILTER = 'GRADING_FILTER',
  HESI_FOCUS_CHAPTER = 'HESI_FOCUS_CHAPTER',
}

export type AnalyticsActionProps = {
  action: AnalyticsAction.FOLDER_RENAMED
    | AnalyticsAction.LOGIN_LAUNCH
    | AnalyticsAction.EAQ_SELF_STUDY_CLICK
    | AnalyticsAction.RETURN_TO_COURSE_BUILDER_CLICK
    | AnalyticsAction.BULK_EDITOR_RESET_ALL
    | AnalyticsAction.BULK_EDITOR_SAVE
    | AnalyticsAction.AUTHESS_HEALTH_CHECK_FAIL
    | AnalyticsAction.SAME_TAB_WARNING_OPENED
    | AnalyticsAction.MY_EVOLVE_LINK_CLICK;
  props: null;
} | {
  action: AnalyticsAction.TOGGLE_ALL_FOLDERS_CLICK;
  props: {
    hasCollapsedFolderIds: boolean;
  };
} | {
  action: AnalyticsAction.EBOOK_CLICK;
  props: {
    vbId: string;
    ref: string;
  };
} | {
  action: AnalyticsAction.EBOOK_PURCHASE_RELATED_PRODUCT_CLICK;
  props: {
    vbId: string;
    ref: string;
    legacyAction: AnalyticsAction;
    relatedProductType: string;
    relatedProductIsbn: string;
  };
} | {
  action: AnalyticsAction.API_ERROR;
  props: {
    status: string;
    action: string;
    url: string;
    method: string;
  };
} | {
  action: AnalyticsAction.APP_LINK_ERROR;
  props: {
    type: AppLinkRedirectErrorMessage;
    status: string;
    url: string;
    method: string;
  };
} | {
  action: AnalyticsAction.AUTHESS_HEALTH_CHECK_SUCCESS;
  props: {
    assignmentIds: string;
  };
} | {
  action: AnalyticsAction.AUTHESS_HEALTH_CHECK_ASSIGNMENT_FIXED;
  props: {
    assignmentId: number;
  };
} | {
  action: AnalyticsAction.BULK_EDITOR_APPLY_TO_ALL_CLICK;
  props: {
    type: string;
    grading?: string;
    pushType?: string;
    weeks?: number;
    days?: number;
    hours?: number;
    minutes?: number;
  };
} | {
  action: AnalyticsAction.BULK_EDITOR_SORT_BY_CHANGE;
  props: {
    value: string;
  };
} | {
  action: AnalyticsAction.BULK_EDITOR_SINGLE_FIELD_UPDATE;
  props: {
    type: string;
  };
} | {
  action: AnalyticsAction.BULK_EDITOR_CANCEL;
  props: {
    ref: string;
  };
} | {
  action: AnalyticsAction.COURSE_BUILT;
  props: {
    [CourseBuilderField.CONTENT_CREATE_METHOD]: contentCreateMethod;
    [CourseBuilderField.CUSTOM_SECTION_TITLE]: string;
    [CourseBuilderField.SECTION_TITLE_METHOD]: sectionTitleMethod;
    [CourseBuilderField.COPY_COURSE_START_DATE]: string;
    [CourseBuilderField.IS_INCLUDE_DURATION_IN_TITLE]: boolean;
    [CourseBuilderField.NUMBER_OF_SECTIONS]: number;
    [CourseBuilderField.START_DATE]: string;
    [CourseBuilderField.END_DATE]: string;
    [CourseBuilderField.COURSE_CREATE_METHOD]: courseCreateMethod;
    isRebuild: boolean;
    isDeepLinkCourseCopy: boolean;
  };
} | {
  action: AnalyticsAction.SYLLABUS_ITEM_ACTION_CLICK;
  props: {
    action: SyllabusItemAction;
    ref: AnalyticsRef;
    activeTab: LANGUAGE_KEY.ALL_ITEMS | LANGUAGE_KEY.UPCOMING_ASSIGNMENTS | LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS;
    numberOfVisibleSelectedItems: number;
    catalogItemSubType: string;
  };
} | {
  action: AnalyticsAction.APP_LINK_OUT
    | AnalyticsAction.APP_LINK_LAUNCH;
  props: {
    linkType: AppAction;
    srcApp: Application;
    targetApp: Application;
  };
} | {
  action: AnalyticsAction.COURSE_PLAN_TAB_CLICK;
  props: {
    tab: LANGUAGE_KEY.ALL_ITEMS | LANGUAGE_KEY.UPCOMING_ASSIGNMENTS | LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS;
  };
} | {
  action: AnalyticsAction.COURSE_SETTINGS_UPDATED;
  props: {
    [CourseBuilderField.IS_COURSE_LOCKED]: boolean;
    [CourseBuilderField.COURSE_NAME]: string;
    isLockStatusChanged: boolean;
    isNameChanged: boolean;
  };
} | {
  action: AnalyticsAction.RESOURCE_FILTER_ACTIVATED;
  props: {
    type: AnalyticsFilterType;
    option: string; // TODO: Deprecated - remove once New Relic dashboards are updated
    optionValue: string;
    optionDisplay: string;
    chapterId?: string; // Chapter id is optional to help distinguish a chapter node filter from a book node filter
    bookId?: string; // Book id is optional to help identify the book that a chapter filter is within
  };
} | {
  action: AnalyticsAction.NAV_ITEM_CLICK;
  props: {
    item: NavItem;
  };
} | {
  action: AnalyticsAction.VIEW_ALL_ATTEMPTS_CLICK;
  props: {
    assignmentType: AssignmentType;
  };
} | {
  action: AnalyticsAction.STUDENT_SIMULATION_PERFORMANCE_MODAL_OPENED;
  props: {};
} | {
  action: AnalyticsAction.SYLLABUS_ITEM_DRAGGED
    | AnalyticsAction.SYLLABUS_ITEM_GRADE_CLICK
    | AnalyticsAction.SYLLABUS_ITEM_DATES_CLICK
    | AnalyticsAction.SYLLABUS_ITEM_TITLE_CLICK;
  props: {
    syllabusItemType: SyllabusItemTypeDto;
    activeTab: LANGUAGE_KEY.ALL_ITEMS | LANGUAGE_KEY.UPCOMING_ASSIGNMENTS | LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS;
    catalogItemSubType: string;
  };
} | {
  action: AnalyticsAction.CATALOG_ITEM_TITLE_CLICK;
  props: {
    contentId: string;
    catalogItemType: string;
    catalogItemSubType: string;
  };
} | {
  action: AnalyticsAction.CATALOG_ITEM_ADDED;
  props: {
    contentId: string;
    catalogItemType: string;
    isBulkAdd: boolean;
    catalogItemSubType: string;
    catalogViewType: string;
  };
} | {
  action: AnalyticsAction.COURSE_SWITCH_CLICK;
  props: {
    targetApp: string;
  };
} | {
  action: AnalyticsAction.COURSE_SWITCH_LINK_CLICK;
  props: {
    ref: 'COURSE_PLAN' | 'USER_LOGOUT';
  };
} | {
  action: AnalyticsAction.HELP_LINK_CLICK;
  props: {
    application: string;
    link: string;
  };
} | {
  action: AnalyticsAction.BATCH_EDIT_TOGGLE_CLICK
    | AnalyticsAction.DRAG_DROP_TOGGLE_CLICK;
  props: {
    value: boolean;
    ref: AnalyticsRef;
  };
} | {
  action: AnalyticsAction.TRAINING_LINK_CLICKED;
  props: {
    application: string;
  };
} | {
  action: AnalyticsAction.OSMOSIS_VIDEO_PLAYER_EVENT;
  props: {
    type: string;
    contentId: string;
    title: string;
  };
} | {
  action: AnalyticsAction.MISSING_MAPPINGS;
  props: Partial<Record<SherpathContentType | SherpathContentTypeDto | SyllabusItemTypeDto, string>>;
} |
  {
    action: AnalyticsAction.SIMULATION_PLAYER_EVENT;
    props: {
      type: string;
      contentId: string;
      title: string;
    };
  }
  | {
  action: AnalyticsAction.SKILL_PLAYER_NEW_TAB_CLICK;
  props: {
    assignmentId: string;
    skillBlockId: string;
    syllabusItemId: string;
  };
} | {
  action: AnalyticsAction.STUDENT_LAUNCH_WARNING_MODAL_OPENED;
  props: {};
} | {
  action: AnalyticsAction.STUDENT_LAUNCH_BY_INSTRUCTOR;
  props: {
    targetStudentId: number;
  };
} | {
  action: AnalyticsAction.EBOOK_ASSIGNMENT_SAVE_CLICK;
  props: {
    assignmentId: number;
    notDefaultChapterIds: string[];
  };
} | {
  action: AnalyticsAction.SET_DEFAULT_TAB_CLICK;
  props: {
    defaultTab: LANGUAGE_KEY.ALL_ITEMS | LANGUAGE_KEY.UPCOMING_ASSIGNMENTS | LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS;
  };
} | {
  action: AnalyticsAction.SYLLABUS_ITEMS_WITH_SAME_ASSIGNMENT_FOUND;
  props: {
    syllabusItemIds: string[];
  };
} | {
  action: AnalyticsAction.SYLLABUS_ITEMS_WITH_DELETED_ASSIGNMENT_FOUND;
  props: {
    syllabusItemsFoundCount: number;
  };
} | {
  action: AnalyticsAction.UPDATE_NAME_PENCIL_CLICKED;
  props: {};
} | {
  action: AnalyticsAction.UPDATE_NAME_LINK_OUT_TO_EVOLVE;
  props: {};
} | {
  action: AnalyticsAction.ADD_NEW_USER_MODAL_OPENED;
  props: {};
} | {
  action: AnalyticsAction.REMOVE_USER_MODAL_OPENED;
  props: {};
} | {
  action: AnalyticsAction.USER_REMOVED;
  props: {};
} | {
  action: AnalyticsAction.NEW_USER_ADDED;
  props: {};
} | {
  action: AnalyticsAction.EVOLVE_UNENROLL_FAILURE_HANDLED;
  props: {
    studentEolsUserId: number;
  };
} | {
  action: AnalyticsAction.COURSE_BUILD_MAKE_VISIBLE_NOW_CLICKED;
  props: {
    types: string[];
  };
} | {
  action: AnalyticsAction.COURSE_BUILD_MAKE_VISIBLE_NOW_SKIPPED;
  props: {};
} | {
  action: AnalyticsAction.TOGGLE_CATALOG_VIEW_CLICK;
  props: {
    afterClickViewType: string;
  };
} | {
  action: AnalyticsAction.CHATBOT_AI_LINK_CLICKED;
  props: {
    ref: string;
  };
} | {
  action: AnalyticsAction.CATALOG_FETCH_ACTION_TRACE;
  props: {
    isbns: string[];
    learningTypes: string[];
    inactiveMappingTypes: string[];
  };
};
