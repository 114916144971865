import { ELSStepperItem } from '@els/els-component-shared-ts-react';

export const SHERPATH_POWERPOINT_EDITOR_PAGE_TITLE = 'PowerPoint Assignment';

export enum SherpathPowerpointStep {
  STEP1 = 'STEP1',
}

export const SherpathPowerpointDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Assignment',
    id: SherpathPowerpointStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Assignment',
    title: 'Step 1',
  }
];
