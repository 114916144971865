import { ELSStepperItem } from '@els/els-component-shared-ts-react';

export const SKILL_ASSIGNMENT_EDITOR_PAGE_TITLE = 'Skill Assignment';

export const DEFAULT_SKILL_GRADE_POINTS = 100;

export enum SkillStep {
  STEP1 = 'STEP1',
}

export const SkillDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Assignment',
    id: SkillStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Assignment',
    title: 'Step 2',
  }
];
