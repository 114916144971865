import { v4 as uuidv4 } from 'uuid';
import { IntlShape } from 'react-intl';
import {
  Dictionary,
  flattenDepth,
  isEmpty,
  isNil,
  isNull,
  now,
  orderBy,
  toString,
  uniq,
  uniqBy,
  groupBy,
  keyBy,
} from 'lodash';
import memoizeOne from 'memoize-one';
import moment from 'moment';
import React from 'react';
import { ELSLoggingService } from '@els/els-ui-common-react';
import {
  clientZoneAbbr,
  getApplicationContext,
  getLocalDateFromServicesUTC,
  getLocalMomentInsFromServicesUTC,
} from '../../utilities/app.utilities';
import {
  DATE_PRIMARY_CHUNK,
  TIME_PRIMARY
} from '../../constants/date.constants';
import {
  ActionMenuItemConfig,
  NewSyllabusFolderParams,
  NewSyllabusItemParams,
  UnassignBuckets
} from './syllabus.models';
import {
  getActiveOrderedAddActionConfigs,
  getCatalogItemConfig,
  getContentItemIncludedIn,
  getContentItemIncludes,
  getEbookLearningDurationFromCatalogItem,
  getEbookNodeTitle,
  getEbookTitle,
  getFilterTypeDisplay,
  getExternalEntity,
  getFilterTypeFromContentItem,
  getLearningDurationFromExternalEntity,
  getOsmosisTokenAndConfigPromise,
  getSubtitle,
  getSyllabusItemTypeFromCatalogItem,
  getTaxonomiesWithFlattenedBranchChildren,
  getTimeEstimateInSecondFromPageRanges,
  getTitle,
  getVbId,
  sortFilterMap,
  isEvolveResourceType,
  isTypeInSelectedTypes,
  transformTitle,
  isCatalogItemArchived,
  getExternalEntityClone,
  getContentItemIncludesClone,
  getTitleClone,
  getSubtitleClone,
  buildContentItemIncludedInMap,
} from '../catalog/catalog.utilities';
import {
  SyllabusItemDto,
  SyllabusItemExternalIdDto
} from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import {
  ActiveSyllabusItemTypeDto,
  DeprecatedSyllabusItemTypeDto,
  ExternalIdTypeDto,
  SyllabusItemTypeDto
} from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import {
  AssessmentStatusDto,
  AssessmentSubmissionDto,
  AssignmentDto,
  AssignmentGradeType,
  AssignmentType,
  StudentAssignmentDto
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { SyllabusTreeMapItem } from '../course-builder/courseBuilder.models';
import {
  flattenTreeMap,
  flattenTreeMapV2,
  getSortedSyllabusTreeMapItemsClone,
  getTreeMap
} from '../course-builder/courseBuilder.utilities';
import {
  RecContentItemDto,
  RecContentItemTypeDto,
  RecTaxonomyNodeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';
import {
  byRemovingExtraDivider,
  generateMenuItem,
  SyllabusItemActionMenuItem,
  SyllabusItemActionMenuProps
} from './SyllabusItemActionMenu.component';
import {
  adaptiveLessonMainTypes,
  adaptiveLessonSubTypes,
  AssignmentStatus,
  CoursePlanResourceStatus,
  CoursePlanResourceStatusMap,
  MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH,
  OrderedAddItemOptions
} from './syllabus.constants';
import { StudentSyllabusItemAssignmentStatusColumnProps } from './StudentSyllabusItemAssignmentStatusColumn.component';
import {
  CatalogEvolveResourceExternalEntityDtoParsed,
  CatalogExternalEntityDto,
  CatalogWithExternalEntitiesDto,
  ExternalEntityDto,
  OsmosisTokenDto,
  UserDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  EvolveProductDto,
  EvolveProductTypeKey
} from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import {
  flattenTree,
  isInstructor,
  isStudent,
  mapToIds
} from '../../utilities/common.utilities';
import {
  SyllabusItemAction,
  SyllabusItemTypeConfig,
  SyllabusItemTypeConfigMap
} from '../../constants/content-type.constants';
import { ResourceStatusMap } from '../catalog/catalog.models';
import { EbookFilterState } from '../../components/ebook-filter/ebook-filter.models';
import {
  ALL_OPTION_VALUE,
  EbookPageRangeSeparators
} from '../catalog/catalog.constants';
import { getSelectedTaxonomiesFromEbookFilterState } from '../../components/ebook-filter/ebook-filter.utilities';
import {
  CoursewareUserHistoryStateKey,
  EolsUserHistoryResponseDto
} from '../../apis/eols-user-crud/eols-user-crud.dtos';
import {
  PrimaryTaxonomy,
  PrimaryTaxonomyTaxonDto
} from '../../apis/rec-gateway/rec-gateway.models';
import {
  getAllChapterPageRangesFromAssignmentGoals,
  getBookTaxonomy,
  getChapterPageRangeMapFromAssignmentGoals,
  getEbookAssignmentTitle
} from '../ebook-assignment-editor/ebook-assignment.utilities';
import { getSyllabusItemsToDelete } from './removeModal.utilities';
import {
  checkAssignmentIsUnassigned,
  isAssignmentGraded,
  isAssignmentStarted
} from '../../components/assignment-editor/assignment-editor.utilities';
import IsRender from '../../components/is-render/IsRender.component';
import { Messages } from '../../translations/message.models';
import { LANGUAGE_KEY } from '../../translations/message.constants';
import { Application } from '../../apis/eols-app-link/eols-app-link.constants';
import { SherpathLessonOsmosisIncludesConfigs } from '../osmosis-video-editor/osmosis-video.constants';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
import {
  FEATURE_FLAG,
} from '../../apis/eols-features-api/eols-features-api.constants';
import {
  FeatureFlagDto,
  FeatureFlagsGroupedDto
} from '../../apis/eols-features-api/eols-features-api.dtos';
import {
  ResourceFilterMap
} from '../../components/resource-filter/ResourceFilter.component';
import { ClinicalSkillsFilterState } from '../../components/clinical-skills-filter/clinical-skills-filter.models';
import { getSelectedTaxonomiesFromClinicalSkillsFilterState } from '../../components/clinical-skills-filter/clinical-skills-filter.utilities';

const fileName = 'syllabus.utilities';

export const isTopLevel = (syllabusItem: SyllabusItemDto): boolean => {
  return syllabusItem && syllabusItem.parentId === null;
};

export const filterByRootNode = (syllabusItem: SyllabusItemDto): boolean => {
  return syllabusItem && syllabusItem.parentId === null;
};

export const sortDefault = (syllabusItems: SyllabusItemDto[]): SyllabusItemDto[] => {
  return orderBy(syllabusItems, [isTopLevel, 'displayOrder', 'title']);
};

export const byId = (id: string) => (item: SyllabusItemDto): boolean => item.id === id;

export const isFolder = (item: SyllabusItemDto): boolean => item.type === ActiveSyllabusItemTypeDto.FOLDER;

export const toIds = <T extends { id: string | number }>(items: T[]): string[] => items.map(item => toString(item.id));

export const getRootSyllabusItems = (syllabusItems: SyllabusItemDto[]) => {
  return syllabusItems.filter(filterByRootNode);
};

export const getSyllabusItemsFromIds = (syllabusItems: SyllabusItemDto[], syllabusItemIds: string[]): SyllabusItemDto[] => {
  return syllabusItems.filter(item => syllabusItemIds.includes(item.id));
};

export const getSyllabusItemFromId = (syllabusItems: SyllabusItemDto[], id: string) => {
  return syllabusItems.find(byId(id));
};

export const getSyllabusItemChildren = (
  syllabusItems: SyllabusItemDto[],
  syllabusItem: SyllabusItemDto,
): SyllabusItemDto[] => {
  if (!syllabusItem) {
    return null;
  }
  return syllabusItems.filter((item) => {
    return item.parentId === syllabusItem.id;
  });
};

export const getSyllabusItemTypeFromAssignment = (
  assignment: AssignmentDto,
  catalog: CatalogWithExternalEntitiesDto // Only required for Evolve Resources
): SyllabusItemTypeDto => {

  if (!assignment || !assignment.assignmentType) {
    return null;
  }

  if (assignment.assignmentType === AssignmentType.EVOLVE_RESOURCE) {
    if (!catalog || !catalog.catalog) {
      return null;
    }
    const catalogItem = catalog.catalog.data.find((contentItem) => {
      return contentItem.attributes.contentId === assignment.contentId;
    });
    return getSyllabusItemTypeFromCatalogItem(catalogItem, catalog);
  }

  const types = Object.keys(SyllabusItemTypeConfigMap).filter((syllabusItemType) => {
    return SyllabusItemTypeConfigMap[syllabusItemType].assignmentTypes
      && SyllabusItemTypeConfigMap[syllabusItemType].assignmentTypes.includes(assignment.assignmentType);
  });

  if (types.length !== 1) {
    ELSLoggingService.warn(fileName, 'Assignment type does not have associated syllabus item type config', assignment.assignmentType);
    return null;
  }

  return types[0] as SyllabusItemTypeDto;
};

export const getSyllabusItemTypeConfig = (syllabusItemType: SyllabusItemTypeDto): SyllabusItemTypeConfig => {
  const config = SyllabusItemTypeConfigMap[syllabusItemType];
  if (!config) {
    return {
      displayName: null,
      displayNamePlural: null,
      icon: null,
      iconPrefix: null,
      iconClass: null,
      title: null,
      assignmentTypes: null,
      syllabusItemAction: null,
      sortOrder: 0,
      recContentType: null,
      configType: null,
      resourceCategory: null,
    };
  }
  return config;
};

export const getSyllabusItemConfig = (syllabusItem: SyllabusItemDto): SyllabusItemTypeConfig => {
  return getSyllabusItemTypeConfig(syllabusItem.type);
};

export const getSyllabusItemTypeDisplayName = (syllabusItem: SyllabusItemDto): string => {
  if (!syllabusItem) {
    return null;
  }
  const config = getSyllabusItemTypeConfig(syllabusItem.type);
  return config ? config.displayName : null;
};

export const generateSyllabusItemLinkedWithAssignment = (props: {
  courseSectionId: string;
  parentId: string;
  assignment: AssignmentDto;
  catalog: CatalogWithExternalEntitiesDto; // Only required for Evolve Resources
}): SyllabusItemDto => {

  const {
    courseSectionId,
    parentId,
    assignment,
    catalog
  } = props;

  const type = getSyllabusItemTypeFromAssignment(assignment, catalog);

  if (!type) {
    return null;
  }

  return {
    displayOrder: 0,
    externalIdentifiers: [
      { type: ExternalIdTypeDto.ASSIGNMENT_ID, value: `${assignment.id}` }
    ],
    courseSectionIds: [parseInt(courseSectionId, 10)],
    id: uuidv4(),
    parentId: parentId || null,
    title: '',
    subtitle: null,
    type,
  };
};

export const generateNewSyllabusItem = (params: NewSyllabusItemParams): SyllabusItemDto => {
  const {
    courseSectionId,
    item,
    parentId,
    catalogDto,
    primaryTaxonomies,
    evolveProducts,
    displayOrder
  } = params;
  return {
    displayOrder,
    externalIdentifiers: [
      { type: ExternalIdTypeDto.CATALOG_ITEM_ID, value: item.id }
    ],
    courseSectionIds: [parseInt(courseSectionId, 10)],
    id: uuidv4(),
    parentId: parentId || null,
    title: getTitle(item, catalogDto, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH),
    subtitle: getSubtitle(item, catalogDto, primaryTaxonomies, evolveProducts, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH),
    type: getSyllabusItemTypeFromCatalogItem(item, catalogDto),
  };
};

export const generateNewSyllabusFolder = ({ courseSectionId, title, parentId, displayOrder }: NewSyllabusFolderParams): SyllabusItemDto => {
  return ({
    displayOrder,
    externalIdentifiers: [],
    courseSectionIds: [parseInt(courseSectionId, 10)],
    id: uuidv4(),
    parentId: parentId || null,
    title,
    subtitle: null,
    type: ActiveSyllabusItemTypeDto.FOLDER,
  });
};

export const getAssignmentExId = (syllabusItem: SyllabusItemDto): SyllabusItemExternalIdDto => {
  const assignmentExIds = syllabusItem.externalIdentifiers.filter(externalId => {
    return externalId.type === ExternalIdTypeDto.ASSIGNMENT_ID;
  });

  if (assignmentExIds.length > 1) {
    ELSLoggingService.warn(fileName, 'Syllabus item has more than 1 associated assignments', syllabusItem);
  }

  if (assignmentExIds.length === 1) {
    return assignmentExIds[0];
  }

  return null;
};

export const getAssociatedAssignmentFromAssignmentsDictionary = (syllabusItem: SyllabusItemDto, assignmentsDictionary: Dictionary<AssignmentDto>): AssignmentDto => {
  const assignmentExId = getAssignmentExId(syllabusItem);
  return assignmentExId ? assignmentsDictionary[assignmentExId.value] : null;
};

export const getAssociatedAssignment = (syllabusItem: SyllabusItemDto, assignments: AssignmentDto[]): AssignmentDto | undefined => {
  const assignmentExId = getAssignmentExId(syllabusItem);

  if (!assignmentExId) {
    return null;
  }

  const foundAssignment = assignments.find((assignment) => {
    if (!assignment || !assignment.id) {
      return false;
    }
    return assignment.id.toString() === assignmentExId.value;
  });

  return foundAssignment || null;
};

export const getAssociatedAssignmentClone = (syllabusItem: SyllabusItemDto, assignmentsMap: Dictionary<AssignmentDto>): AssignmentDto | undefined => {
  const assignmentExId = getAssignmentExId(syllabusItem);

  if (!assignmentExId) {
    return null;
  }

  const foundAssignment = assignmentsMap[assignmentExId.value];
  if (!foundAssignment) {
    return null;
  }
  return (foundAssignment);
};

export const getAssociatedSyllabusItem = (syllabusItems: SyllabusItemDto[], assignment: AssignmentDto): SyllabusItemDto | undefined => {
  return syllabusItems.find(syllabusItem => {
    const assignmentExId = syllabusItem.externalIdentifiers.find(externalId => externalId.type === ExternalIdTypeDto.ASSIGNMENT_ID);
    if (assignmentExId) {
      return assignment.id === parseInt(assignmentExId.value, 10);
    }
    return false;
  });
};

export const getAssociatedSyllabusItemByAssignmentId = (syllabusItems: SyllabusItemDto[], assignmentId: number | string): SyllabusItemDto | undefined => {
  return syllabusItems.find(syllabusItem => {
    const assignmentExId = syllabusItem.externalIdentifiers.find(externalId => externalId.type === ExternalIdTypeDto.ASSIGNMENT_ID);
    if (assignmentExId) {
      return toString(assignmentId) === assignmentExId.value;
    }
    return false;
  });
};

export const getUnlinkedAssignments = (assignments: AssignmentDto[], syllabusItems: SyllabusItemDto[]): AssignmentDto[] => {
  return assignments.filter(assignment => {
    const associatedSyllabusItem = getAssociatedSyllabusItem(syllabusItems, assignment);
    return !associatedSyllabusItem;
  });
};

export const getAssignmentWithMainTypes = (assignments: AssignmentDto[]): AssignmentDto[] => {
  return assignments.filter(assignment => {
    if (assignment.assignmentType !== AssignmentType.QUIZ_BY_QUESTION) {
      return true;
    }
    const doesContainLessonQuiz = assignment.assignmentTopics.some((item) => adaptiveLessonMainTypes.includes(item.text));
    const doesNotContainAnySubTypes = assignment.assignmentTopics.every((item) => !adaptiveLessonSubTypes.includes(item.text));
    return doesContainLessonQuiz || doesNotContainAnySubTypes;
  });
};

export const getAssignmentsFromExternalEntities = (externalEntities: ExternalEntityDto[]): AssignmentDto[] => {
  if (!externalEntities || !externalEntities.length) {
    return [];
  }
  return externalEntities.filter(externalEntity => {
    if (!externalEntity || !externalEntity.externalIdentifier) {
      return false;
    }
    return externalEntity.externalIdentifier.type === ExternalIdTypeDto.ASSIGNMENT_ID;
  }).map(externalEntity => {
    return externalEntity.entity as AssignmentDto;
  });
};

export const getTreeMapOfAllNestedSyllabusItemChildren = (syllabusItems: SyllabusItemDto[], parentSyllabusItem: SyllabusItemDto): SyllabusTreeMapItem[] => {
  const treeMapItems = getTreeMap(syllabusItems);
  const flattenTreeMapItems = flattenTreeMap(treeMapItems);
  const currentTreeMap = flattenTreeMapItems.find(treeMapItem => treeMapItem.syllabusItem.id === parentSyllabusItem.id);
  if (currentTreeMap) {
    // Remove the first element because current treemap is included in flatten result
    return flattenTreeMap([currentTreeMap]).slice(1);
  }
  return [];
};

export const getAllNestedSyllabusItemChildren = (syllabusItems: SyllabusItemDto[], parentSyllabusItem: SyllabusItemDto): SyllabusItemDto[] => {
  const treeMapOfNestSyllabusItemChildren = getTreeMapOfAllNestedSyllabusItemChildren(syllabusItems, parentSyllabusItem);
  return treeMapOfNestSyllabusItemChildren.map(item => item.syllabusItem);
};

export const getAllTopLevelFolders = (syllabusItems: SyllabusItemDto[]) => {
  return syllabusItems.filter(item => isFolder(item) && isTopLevel(item));
};

export const getSyllabusItemNestLevel = (syllabusItems: SyllabusItemDto[], syllabusItem: SyllabusItemDto) => {
  const treeMapSyllabusItems = getTreeMap(syllabusItems);
  const flattenedSyllabusItemTreeMap = flattenTreeMap(treeMapSyllabusItems);
  const item = flattenedSyllabusItemTreeMap.find(i => i.syllabusItem.id === syllabusItem.id);
  return item.level;
};

export const setSiblingDisplayOrder = (
  newSiblingItems: SyllabusItemDto[],
  existingSiblingSyllabusItems?: SyllabusItemDto[]
): SyllabusItemDto[] => {

  if (!newSiblingItems.length) {
    return newSiblingItems;
  }

  const sortedExistingSiblingSyllabusItems = sortDefault(existingSiblingSyllabusItems);
  const sortedNewSiblingItems = sortDefault(newSiblingItems);
  const allSortedSiblingItems = sortedExistingSiblingSyllabusItems.concat(sortedNewSiblingItems);
  const numberOfNonFolderSiblingItems = allSortedSiblingItems.filter(item => !isFolder(item)).length;
  let folderCount = -1;
  let itemCount = -1;
  return allSortedSiblingItems.map((item) => {
    if (!isFolder(item)) {
      itemCount += 1;
      return {
        ...item,
        displayOrder: itemCount
      };
    }
    folderCount += 1;
    return {
      ...item,
      displayOrder: numberOfNonFolderSiblingItems + folderCount
    };
  });
};

export const setItemsDisplayOrderOnAdd = (allSyllabusItems: SyllabusItemDto[], newSyllabusItems: SyllabusItemDto[]) => {
  if (!allSyllabusItems || !newSyllabusItems) {
    return null;
  }
  const newFolder = newSyllabusItems.find(item => isFolder(item));
  const newChildren = newSyllabusItems.filter(item => !isFolder(item));
  if (newFolder) {
    const newFolderParentItem = allSyllabusItems.find(byId(newFolder.parentId));
    const newFolderSiblingItems = newFolderParentItem ? getSyllabusItemChildren(allSyllabusItems, newFolderParentItem) : getAllTopLevelFolders(allSyllabusItems);
    const notDeletedNewFolderSiblingItems = newFolderSiblingItems.filter((item) => {
      return !item.isDeleted;
    });
    const reorderedNewFolderSiblingItems = setSiblingDisplayOrder([newFolder], notDeletedNewFolderSiblingItems);
    const orderedNewFolderChildItems = setSiblingDisplayOrder(newChildren);
    return reorderedNewFolderSiblingItems.concat(orderedNewFolderChildItems);
  }
  const newItemsParentItem = allSyllabusItems.find(byId(newChildren[0].parentId));
  if (!newItemsParentItem) {
    return setSiblingDisplayOrder(newSyllabusItems);
  }
  const newItemsExistingSiblings = getSyllabusItemChildren(allSyllabusItems, newItemsParentItem);
  const notDeletedNewItemsExistingSiblings = newItemsExistingSiblings.filter((item) => {
    return !item.isDeleted;
  });
  return setSiblingDisplayOrder(newSyllabusItems, notDeletedNewItemsExistingSiblings);
};

export const getNextHighestDisplayOrder = (syllabusItems: SyllabusItemDto[], folderId: string): number => {
  if (!syllabusItems || !syllabusItems.length || !folderId) {
    return 0;
  }

  const folder = getSyllabusItemFromId(syllabusItems, folderId);

  if (!folder || folder.type !== ActiveSyllabusItemTypeDto.FOLDER) {
    return 0;
  }

  const folderChildren = getSyllabusItemChildren(syllabusItems, folder);

  if (!folderChildren || !folderChildren.length) {
    return 0;
  }

  const sortedFolderChildren = sortDefault(folderChildren);
  const currentHighestDisplayOrder = sortedFolderChildren[sortedFolderChildren.length - 1].displayOrder;
  return currentHighestDisplayOrder + 1;
};

export const getSyllabusItemSiblings = (
  allSyllabusItems: SyllabusItemDto[],
  syllabusItem: SyllabusItemDto
): SyllabusItemDto[] => {
  return allSyllabusItems.filter((item) => {
    return item.parentId === syllabusItem.parentId && item.id !== syllabusItem.id;
  });
};

export const getSyllabusItemWithSiblings = (
  allSyllabusItems: SyllabusItemDto[],
  syllabusItem: SyllabusItemDto
): SyllabusItemDto[] => {
  return allSyllabusItems.filter((item) => {
    return item.parentId === syllabusItem.parentId;
  });
};

export const setSyllabusItemAndSiblingsDisplayOrder = (
  allSyllabusItems: SyllabusItemDto[],
  newSyllabusItem: SyllabusItemDto
): SyllabusItemDto[] => {
  const siblings = getSyllabusItemSiblings(allSyllabusItems, newSyllabusItem);
  return setSiblingDisplayOrder([newSyllabusItem], siblings);
};

export const getActionButtonClassName = (action: SyllabusItemAction): string => {
  return `qe-scm-course-plan-action-button-${action.toString().toLowerCase().replace('_', '-')}`;
};

export const getSyllabusItemsWithAssignments = (
  syllabusItems: SyllabusItemDto[],
  assignments: AssignmentDto[]
): SyllabusItemDto[] => {
  return syllabusItems.filter((syllabusItem) => {
    return getAssociatedAssignment(syllabusItem, assignments);
  });
};

export const isAssignmentDueDateInPast = (assignment: AssignmentDto): boolean => {
  return moment().isAfter(moment(getLocalDateFromServicesUTC(assignment.dueDate)));
};

export const assignmentHasDueDate = (assignment: AssignmentDto): boolean => {
  return Boolean(assignment.dueDate);
};

export const getSyllabusItems = (filteredSyllabusItems: SyllabusItemDto[], collapsedFolderIds: string[]): {
  hiddenItemIds: string[];
  visibleSyllabusTreeMapItems: SyllabusTreeMapItem[];
} => {

  const sortedSyllabusTreeMapItems = getSortedSyllabusTreeMapItemsClone(filteredSyllabusItems);
  const treeMapDictionary = keyBy(sortedSyllabusTreeMapItems, 'syllabusItem.id');
  const hiddenItemIdsSet = new Set<string>();

  collapsedFolderIds.forEach((curCollapsedFolderId) => {
    const folderTreeMap = treeMapDictionary[curCollapsedFolderId];
    if (folderTreeMap) {
      const allNestChildrenTreeMapItems = flattenTreeMapV2([folderTreeMap]).slice(1);
      allNestChildrenTreeMapItems.forEach(item => hiddenItemIdsSet.add(item.syllabusItem.id));
    }
  });

  const visibleSyllabusTreeMapItems = sortedSyllabusTreeMapItems.filter((treeMapItem) => {
    return !hiddenItemIdsSet.has(treeMapItem.syllabusItem.id);
  });

  return {
    hiddenItemIds: Array.from(hiddenItemIdsSet),
    visibleSyllabusTreeMapItems,
  };
};

export const sortByCoursePlan = (
  syllabusItems: SyllabusItemDto[],
  assignmentsDictionary: Dictionary<AssignmentDto>,
  syllabusItemsFromCoursePlan: SyllabusItemDto[]
): SyllabusItemDto[] => {
  const syllabusItemsByDueDate = orderBy(syllabusItems, [(syllabusItem) => {
    const assignment = getAssociatedAssignmentFromAssignmentsDictionary(syllabusItem, assignmentsDictionary);
    return moment(assignment.dueDate).startOf('minute').toDate();
  }], ['asc']);

  const seperatedByDueDate = Object.entries(syllabusItemsByDueDate.reduce((partitions, syllabusItem) => {
    const date = getAssociatedAssignmentFromAssignmentsDictionary(syllabusItem, assignmentsDictionary).dueDate;
    if (!partitions[date]) {
      // eslint-disable-next-line no-param-reassign
      partitions[date] = [];
    }
    partitions[date].push(syllabusItem);
    return partitions;
  }, {})).map((groupedAssignments) => {
    return { date: groupedAssignments[0], assignments: groupedAssignments[1] };
  });

  const syllabusItemsSortedByCoursePlan = seperatedByDueDate.map((syllabusItemGroup: { date: string; assignments: SyllabusItemDto[] }) => {
    const { date } = syllabusItemGroup;
    const sortedByCoursePlan = syllabusItemGroup.assignments.sort((a, b) => {
      return syllabusItemsFromCoursePlan.indexOf(a) - syllabusItemsFromCoursePlan.indexOf(b);
    });

    return { date, sortedByCoursePlan };
  });

  const orderedByDate = orderBy(syllabusItemsSortedByCoursePlan, [(syllabusItemGroup) => {
    return moment(syllabusItemGroup.date).startOf('minute').toDate();
  }], ['asc']);

  return orderedByDate.reduce((acc, cur) => {
    return acc.concat(cur.sortedByCoursePlan);
  }, []);
};

export const filterAndSortUpcomingAssignments = (
  syllabusItems: SyllabusItemDto[],
  assignmentsDictionary: Dictionary<AssignmentDto>,
  syllabusItemsFromCoursePlan: SyllabusItemDto[]
): SyllabusItemDto[] => {

  const upcomingItems = syllabusItems.filter((syllabusItem) => {
    const assignment = getAssociatedAssignmentFromAssignmentsDictionary(syllabusItem, assignmentsDictionary);
    return assignment && assignmentHasDueDate(assignment) && !isAssignmentDueDateInPast(assignment);
  });

  return sortByCoursePlan(upcomingItems, assignmentsDictionary, syllabusItemsFromCoursePlan);
};

export const filterAndSortPastAssignments = (
  syllabusItems: SyllabusItemDto[],
  assignmentsDictionary: Dictionary<AssignmentDto>,
): SyllabusItemDto[] => {

  const pastItems = syllabusItems.filter((syllabusItem) => {
    const assignment = getAssociatedAssignmentFromAssignmentsDictionary(syllabusItem, assignmentsDictionary);
    return assignment && assignmentHasDueDate(assignment) && isAssignmentDueDateInPast(assignment);
  });

  return orderBy(pastItems, [(syllabusItem) => {
    const assignment = getAssociatedAssignmentFromAssignmentsDictionary(syllabusItem, assignmentsDictionary);
    return moment(assignment.dueDate).startOf('minute').toDate();
  }, (syllabusItem) => {
    const assignment = getAssociatedAssignmentFromAssignmentsDictionary(syllabusItem, assignmentsDictionary);
    return moment(assignment.availableDate).startOf('minute').toDate();
  }], ['desc', 'desc']);
};

export const doesStudentHaveReAttemptAssessmentOpen = (studentAssignments: StudentAssignmentDto[]): boolean => {
  if (!studentAssignments || !studentAssignments.length || studentAssignments.length === 1) {
    return false;
  }
  let numberOfInProgressAssessments = 0;
  let numberOfCompletedAssessments = 0;

  studentAssignments.forEach(studentAssignment => {
    if (studentAssignment.status === AssessmentStatusDto.IN_PROGRESS) {
      numberOfInProgressAssessments += 1;
    }
    if (studentAssignment.status === AssessmentStatusDto.COMPLETED) {
      numberOfCompletedAssessments += 1;
    }
  });
  return numberOfInProgressAssessments > 0 && numberOfCompletedAssessments > 0;
};

export const getReAttempts = (assignment: AssignmentDto, userId: string): string[] => {
  if (!assignment) {
    return [];
  }
  const studentAssessmentMap = groupBy(assignment.studentAssignments, 'userId');

  return Object.keys(studentAssessmentMap)
    .filter((_userId) => {
      if (!userId) {
        return true;
      }
      return _userId === userId;
    })
    .filter((_userId) => {
      return doesStudentHaveReAttemptAssessmentOpen(studentAssessmentMap[_userId]);
    });
};

export const getHighestStudentAssignmentStatus = (
  assignment: AssignmentDto,
  userId: string
): AssessmentStatusDto => {

  if (!assignment) {
    return null;
  }

  const studentAssignments = assignment.studentAssignments.filter((studentAssignment) => {
    return studentAssignment.userId.toString() === userId.toString();
  });

  if (!studentAssignments || !studentAssignments.length) {
    return null;
  }

  const statusRank = [
    AssessmentStatusDto.COMPLETED,
    AssessmentStatusDto.IN_PROGRESS,
    AssessmentStatusDto.NOT_STARTED
  ];

  return orderBy(studentAssignments, (studentAssignment: StudentAssignmentDto) => {
    return statusRank.indexOf(studentAssignment.status);
  })[0].status;
};

const assignmentHasSubmissionBeforeDueDate = (
  assignment: AssignmentDto,
  assessmentSubmissions: AssessmentSubmissionDto[]
): boolean => {

  if (!assessmentSubmissions || !assessmentSubmissions.length) {
    return false;
  }
  const firstSubmission = orderBy(assessmentSubmissions, (submission: AssessmentSubmissionDto) => {
    return getLocalDateFromServicesUTC(submission.responseTime);
  })[0];
  return moment(getLocalDateFromServicesUTC(assignment.dueDate)).isAfter(moment(getLocalDateFromServicesUTC(firstSubmission.responseTime)));
};

export const getAssignmentStatus = (props: {
  assignment: AssignmentDto;
  assessmentSubmissions: AssessmentSubmissionDto[];
  userId: string;
}): AssignmentStatus => {

  const {
    assignment,
    assessmentSubmissions,
    userId
  } = props;

  if (!assignment || !assignment.dueDate) {
    return null;
  }

  const status: AssessmentStatusDto = getHighestStudentAssignmentStatus(assignment, userId);

  if (status === AssessmentStatusDto.COMPLETED) {
    if (!assessmentSubmissions) {
      return null;
    }
    if (assignmentHasSubmissionBeforeDueDate(assignment, assessmentSubmissions)) {
      return AssignmentStatus.COMPLETED_BEFORE_DUE_DATE;
    }
    return AssignmentStatus.COMPLETED_AFTER_DUE_DATE;
  }
  if (isAssignmentDueDateInPast(assignment)) {
    if (status === AssessmentStatusDto.IN_PROGRESS) {
      return AssignmentStatus.IN_PROGRESS_PAST_DUE;
    }
    return AssignmentStatus.NOT_STARTED_PAST_DUE;
  }
  if (status === AssessmentStatusDto.IN_PROGRESS) {
    return AssignmentStatus.IN_PROGRESS;
  }
  return AssignmentStatus.NOT_STARTED;
};

export const filterAndSortPastDueAssignments = (props: {
  syllabusItems: SyllabusItemDto[];
  assignmentsDictionary: Dictionary<AssignmentDto>;
  assessmentSubmissionsMap: Record<string, AssessmentSubmissionDto[]>;
  userId: string;
  userHistory: EolsUserHistoryResponseDto[];
  courseSectionId: string;
}): SyllabusItemDto[] => {

  const {
    syllabusItems,
    assignmentsDictionary,
    assessmentSubmissionsMap,
    userId,
    userHistory,
    courseSectionId
  } = props;

  if (!userHistory) {
    return null;
  }

  const thisUserHistory = userHistory.find((userHistoryItem) => {
    return userHistoryItem.stateKey === CoursewareUserHistoryStateKey.DISMISSED_LATE_ASSIGNMENTS
      && userHistoryItem.courseSectionId.toString() === courseSectionId;
  });

  const dismissedLateAssignmentIds = thisUserHistory ? JSON.parse(thisUserHistory.stateInfo) : [];

  const upcomingItems = syllabusItems
    .filter((syllabusItem) => {
      if (!thisUserHistory) {
        return true;
      }
      return !dismissedLateAssignmentIds.includes(syllabusItem.id);
    })
    .map((syllabusItem) => {
      return {
        syllabusItem,
        assignment: getAssociatedAssignmentFromAssignmentsDictionary(syllabusItem, assignmentsDictionary)
      };
    })
    .filter((syllabusItemWithAssignment) => {
      const { assignment } = syllabusItemWithAssignment;
      if (!assignment || !assignmentHasDueDate(assignment) || !isAssignmentDueDateInPast(assignment)) {
        return false;
      }
      const assessmentSubmissions = assessmentSubmissionsMap
        ? assessmentSubmissionsMap[assignment.id.toString()]
        : null;
      const status = getAssignmentStatus({
        assignment,
        assessmentSubmissions,
        userId
      });
      return [
        AssignmentStatus.NOT_STARTED_PAST_DUE,
        AssignmentStatus.IN_PROGRESS_PAST_DUE
      ].includes(status);
    });

  return orderBy(upcomingItems, (syllabusItemWithAssignment) => {
    return -syllabusItemWithAssignment.assignment.dueDate;
  }).map((syllabusItemWithAssignment) => {
    return syllabusItemWithAssignment.syllabusItem;
  });
};

export const getAncestorLineFolders = (syllabusItems: SyllabusItemDto[], syllabusItem: SyllabusItemDto, ancestorLineFoldersFromProps?: SyllabusItemDto[]): SyllabusItemDto[] => {
  const directLineAncestorFolders = ancestorLineFoldersFromProps || [];
  if (syllabusItem && syllabusItem.parentId !== null) {
    const parentSyllabusItem = syllabusItems.find(byId(syllabusItem.parentId));
    if (!parentSyllabusItem) {
      return directLineAncestorFolders;
    }
    directLineAncestorFolders.push(parentSyllabusItem);
    getAncestorLineFolders(syllabusItems, parentSyllabusItem, directLineAncestorFolders);
  }
  return directLineAncestorFolders;
};

export const getAncestorLineFolderIds = (syllabusItems: SyllabusItemDto[], syllabusItemId: string): string[] => {
  const syllabusItem = getSyllabusItemFromId(syllabusItems, syllabusItemId);
  const directLineAncestorFolders = getAncestorLineFolders(syllabusItems, syllabusItem);
  return toIds(directLineAncestorFolders);
};

export const getRootsOfSelectedNodes = (syllabusItems: SyllabusItemDto[], selectedNodes: SyllabusItemDto[]): SyllabusItemDto[] => {
  return syllabusItems.filter(item => {
    const isNodesSelected = selectedNodes.includes(item);
    if (item.parentId === null) {
      return isNodesSelected;
    }
    const isNodesParentSelected = selectedNodes.find(byId(item.parentId));
    return !isNodesParentSelected && isNodesSelected;
  });
};

export const getEmptyFolderPlaceholderMenuItems = (props: SyllabusItemActionMenuProps): SyllabusItemActionMenuItem[] => {
  const {
    featureFlagsGrouped,
    evolveProducts,
    courseSectionId,
    catalog,
    userRole,
    activeSyllabusItemTypes
  } = props;
  return getActiveOrderedAddActionConfigs(
    featureFlagsGrouped,
    OrderedAddItemOptions,
    evolveProducts,
    courseSectionId,
    catalog,
    userRole,
    activeSyllabusItemTypes
  ).reduce(byRemovingExtraDivider, []).map((actionConfig: ActionMenuItemConfig): SyllabusItemActionMenuItem => {
    return generateMenuItem({
      action: actionConfig.action,
      type: actionConfig.type
    }, props);
  });
};

export const updateCheckedSyllabusItemsOnAdd = (syllabusItems: SyllabusItemDto[], checkedSyllabusItemIds: string[], targetParentId: string) => {
  const targetFolderAncestorLineFolderIds = getAncestorLineFolderIds(syllabusItems, targetParentId);
  const itemsToUncheck = [...targetFolderAncestorLineFolderIds, targetParentId];
  return checkedSyllabusItemIds.filter(checkedId => !itemsToUncheck.includes(checkedId));
};

export const updateCheckedContentItemMapOnAdd = (syllabusItems: SyllabusItemDto[], checkedContentItemMap: Record<string, boolean>, targetParentId: string) => {
  const targetFolderAncestorLineFolderIds = getAncestorLineFolderIds(syllabusItems, targetParentId);
  const itemsToUncheck = [...targetFolderAncestorLineFolderIds, targetParentId];
  const checkedContentCopy = { ...checkedContentItemMap };
  itemsToUncheck.forEach(id => {
    checkedContentCopy[id] = false;
  });
  return checkedContentCopy;
};

export const hasUncheckedItems = (syllabusItems: SyllabusItemDto[], checkedContentItemsMap: Record<string, boolean>) => {
  const unselectedNode = toIds(syllabusItems).find((selectedId) => {
    return !checkedContentItemsMap[selectedId];
  });
  return !!unselectedNode;
};

export const getSyllabusItemTitle = (syllabusItem: SyllabusItemDto, assignments: AssignmentDto[]) => {
  const assignment = getAssociatedAssignment(syllabusItem, assignments);

  if (assignment) {
    return assignment.title;
  }

  return syllabusItem.title;
};

const notScorableTypes = [
  ActiveSyllabusItemTypeDto.EBOOK_READING,
  ActiveSyllabusItemTypeDto.FOLDER,
  ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE,
  ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE,
  ActiveSyllabusItemTypeDto.SHERPATH_POWERPOINT,
  ActiveSyllabusItemTypeDto.SHERPATH_GROUP_ACTIVITY,
  ActiveSyllabusItemTypeDto.SHERPATH_CASE_STUDY,
  ActiveSyllabusItemTypeDto.CUSTOM_LINK,
  DeprecatedSyllabusItemTypeDto.EVOLVE_RESOURCE
];

export const isScorableType = (syllabusItemType: SyllabusItemDto['type']): boolean => {
  return !notScorableTypes.includes(syllabusItemType);
};

// Make sure this list stays in sync with the scorable syllabus item types
export const isAssignmentTypeScorableMap: Record<AssignmentType, boolean> = {
  [AssignmentType.ADAPTIVE_LESSON]: true,
  [AssignmentType.COACH]: true,
  [AssignmentType.EBOOK_READING]: false,
  [AssignmentType.ELSEVIER_ADAPTIVE_QUIZZING]: true,
  [AssignmentType.EXAM]: true,
  [AssignmentType.LESSONS]: true,
  [AssignmentType.MASTERY]: true,
  [AssignmentType.MINI]: true,
  [AssignmentType.NON_ADAPTIVE_QUIZZING]: true,
  [AssignmentType.POST]: true,
  [AssignmentType.QUIZ_BY_QUESTION]: true,
  [AssignmentType.SIMULATIONS]: true,
  [AssignmentType.SINGLE_STUDENT]: true,
  [AssignmentType.SKILL]: true,
  [AssignmentType.STANDARD]: true,
  [AssignmentType.CHART]: true,
  [AssignmentType.CASE_STUDY]: true,
  [AssignmentType.AUTHESS]: true,
  [AssignmentType.POWERPOINT]: false,
  [AssignmentType.EVOLVE_RESOURCE]: false,
  [AssignmentType.SHADOW_HEALTH]: true,
  [AssignmentType.PERSONAL_ASSESSMENT_BUILDER]: true,
  [AssignmentType.SHERPATH_POWERPOINT]: false,
  [AssignmentType.SHERPATH_GROUP_ACTIVITY]: false,
  [AssignmentType.SHERPATH_CASE_STUDY]: false,
  [AssignmentType.CUSTOM_LINK]: false,
  [AssignmentType.OSMOSIS_VIDEO]: true,
  [AssignmentType.INTERACTIVE_REVIEW]: true,
};

export const isScorable = (syllabusItem: SyllabusItemDto): boolean => {
  return isScorableType(syllabusItem.type);
};

export const showSyllabusItemPerformanceReport = (syllabusItem: SyllabusItemDto, assignment: AssignmentDto) => {
  if (!assignment) {
    return false;
  }

  return isScorable(syllabusItem);

};

export const showAssignmentStatusLoader = (
  props: StudentSyllabusItemAssignmentStatusColumnProps
): boolean => {
  const {
    assignment,
    userId,
    assessmentSubmissionsMap
  } = props;

  if (!assignment) {
    return false;
  }

  if (!assignment.dueDate) {
    return false;
  }

  const status: AssessmentStatusDto = getHighestStudentAssignmentStatus(assignment, userId);

  return status === AssessmentStatusDto.COMPLETED && (!assessmentSubmissionsMap || !assessmentSubmissionsMap[assignment.id.toString()]);
};

// eslint-disable-next-line @typescript-eslint/camelcase
export const getAssignmentStatusDEPRECATED = (
  props: StudentSyllabusItemAssignmentStatusColumnProps
): AssignmentStatus => {
  const {
    assignment,
    userId,
    assessmentSubmissionsMap
  } = props;

  const assessmentSubmissions = assignment && assessmentSubmissionsMap
    ? assessmentSubmissionsMap[assignment.id.toString()]
    : null;

  return getAssignmentStatus({
    assignment,
    userId,
    assessmentSubmissions
  });
};

// export const getCatalogItemFromAssignment

export const getCatalogItemFromSyllabusItem = (catalogDto: CatalogWithExternalEntitiesDto, syllabusItem: SyllabusItemDto): RecContentItemDto => {

  const externalId = syllabusItem.externalIdentifiers.find((item) => {
    return item.type === ExternalIdTypeDto.CATALOG_ITEM_ID;
  });

  if (externalId) {
    return catalogDto.catalog.data.find((item) => {
      return item.id === externalId.value;
    });
  }

  return null;

};

export const getTaxonsTitle = (
  relatedTaxons: string[],
  primaryTaxonomies: PrimaryTaxonomy[],
  maxLength: number
) => {

  if (!primaryTaxonomies || !primaryTaxonomies.length || !relatedTaxons || !relatedTaxons.length) {
    return null;
  }

  const taxons: RecTaxonomyNodeDto[] = primaryTaxonomies.reduce((acc, cur) => {
    const primaryTaxonomyTaxons = cur.taxonomy.included.filter((taxon) => {
      return relatedTaxons.includes(taxon.id);
    });
    return [...acc, ...primaryTaxonomyTaxons.filter((taxon) => {
      return !acc.find((x: PrimaryTaxonomyTaxonDto) => x.id === taxon.id);
    })];
  }, []);

  if (taxons && taxons.length) {
    const val = taxons.sort((a, b) => {
      if (!isNil(a.attributes.displayOrder) && !isNil(b.attributes.displayOrder)) {
        return a.attributes.displayOrder - b.attributes.displayOrder;
      }
      const aSort = getEbookNodeTitle(a);
      const bSort = getEbookNodeTitle(b);
      return aSort.localeCompare(bSort);
    }).map((chapter) => {
      return getEbookNodeTitle(chapter);
    }).join('; ');
    return transformTitle(val, maxLength);
  }

  return '';
};

export const getEbookReadingPartsFromCatalogId = (id: string) => {
  return id.split('ebook-reading:')[1].split('/');
};

export const getIsbnFromEbookCatalogItemId = (id: string) => {
  return getEbookReadingPartsFromCatalogId(id)[0];
};

export const getIsbnFromEbookSyllabusItem = (syllabusItem: SyllabusItemDto): string => {
  const eid = syllabusItem.externalIdentifiers.find(x => x.type === ExternalIdTypeDto.CATALOG_ITEM_ID);
  if (!eid) {
    return null;
  }
  return getIsbnFromEbookCatalogItemId(eid.value);
};

export const getPageRangesFromEbookCatalogItemId = (id: string): string => {
  const value = getEbookReadingPartsFromCatalogId(id)[1];
  if (!value) {
    return value;
  }
  return value.replace(/;/g, EbookPageRangeSeparators.CHUNK_SEPARATOR);
};

export const getPageRangesFromEbookSyllabusItem = (syllabusItem: SyllabusItemDto): string => {
  const eid = syllabusItem.externalIdentifiers.find(x => x.type === ExternalIdTypeDto.CATALOG_ITEM_ID);
  if (!eid) {
    return null;
  }
  return getPageRangesFromEbookCatalogItemId(eid.value);
};

export const getIsbnFromSyllabusItem = (syllabusItem: SyllabusItemDto, catalog: CatalogWithExternalEntitiesDto): string => {
  if (syllabusItem.type === ActiveSyllabusItemTypeDto.EBOOK_READING) {
    return getIsbnFromEbookSyllabusItem(syllabusItem);
  }

  const catalogItem = getCatalogItemFromSyllabusItem(catalog, syllabusItem);

  if (!catalogItem) {
    return null;
  }
  return catalogItem.attributes.isbn;
};

export const getSyllabusItemTypesByProductTypeKey = (evolveProductTypeKey: EvolveProductTypeKey): ActiveSyllabusItemTypeDto[] => {
  switch (evolveProductTypeKey) {
    case EvolveProductTypeKey.SHERPATH_BOOK_ORGANIZED:
    case EvolveProductTypeKey.SHERPATH_IA: {
      return [
        ActiveSyllabusItemTypeDto.SHERPATH_LESSON,
        ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON,
        ActiveSyllabusItemTypeDto.SHERPATH_SIMULATIONS,
        ActiveSyllabusItemTypeDto.SHERPATH_SKILL,
        ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE,
        ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE,
      ];
    }
    case EvolveProductTypeKey.SHERPATH_COMPONENT_NSS:
    case EvolveProductTypeKey.EAQNG_IA: {
      return [
        ActiveSyllabusItemTypeDto.ADAPTIVE_QUIZ,
      ];
    }
    case EvolveProductTypeKey.ELSEVIER_ASSESSMENT_BUILDER: {
      return [
        ActiveSyllabusItemTypeDto.ASSESSMENT_BUILDER_ASSIGNMENT
      ];
    }
    case EvolveProductTypeKey.SHERPATH_EBOOK_COMPONENT_NSS: {
      return [
        ActiveSyllabusItemTypeDto.EBOOK_READING,
      ];
    }
    case EvolveProductTypeKey.SIMULATION_SIM_CHART_NG: {
      return [
        ActiveSyllabusItemTypeDto.SIMCHART_CHART,
        ActiveSyllabusItemTypeDto.SIMCHART_CASE_STUDY,
      ];
    }
    case EvolveProductTypeKey.SHADOW_HEALTH: {
      return [
        ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT,
      ];
    }
    default:
      return null;
  }
};

export const getSyllabusItemTypeFromAction = (syllabusItemAction: SyllabusItemAction): SyllabusItemTypeDto => {
  const type = Object.keys(SyllabusItemTypeConfigMap).find((key) => {
    return SyllabusItemTypeConfigMap[key].syllabusItemAction === syllabusItemAction;
  });
  return type as ActiveSyllabusItemTypeDto;
};

export const getActionsFromEvolveProducts = (evolveProducts: EvolveProductDto[]): SyllabusItemAction[] => {

  if (!evolveProducts || !evolveProducts.length) {
    return [];
  }

  const allEvolveProducts: EvolveProductDto[] = flattenTree(evolveProducts, 'components');
  return allEvolveProducts.reduce((acc: SyllabusItemAction[], product) => {

    const contentTypes = getSyllabusItemTypesByProductTypeKey(product.productTypeKey);

    if (!contentTypes || !contentTypes.length) {
      return acc;
    }

    const _actions = contentTypes
      .map((contentType) => {
        return SyllabusItemTypeConfigMap[contentType].syllabusItemAction;
      })
      .filter((action) => {
        if (isNil(action)) {
          return false;
        }
        return !acc.includes(action);
      });

    return [...acc, ..._actions];
  }, []);
};

export const getProductTypeKeysByAction = (action: SyllabusItemAction): EvolveProductTypeKey[] => {
  switch (action) {
    case SyllabusItemAction.ADD_EBOOK_READING: {
      return [
        EvolveProductTypeKey.SHERPATH_EBOOK_COMPONENT_NSS,
      ];
    }
    case SyllabusItemAction.ADD_ADAPTIVE_QUIZ: {
      return [
        EvolveProductTypeKey.SHERPATH_COMPONENT_NSS,
      ];
    }
    case SyllabusItemAction.ADD_ADAPTIVE_LESSON: {
      return [
        EvolveProductTypeKey.SHERPATH_BOOK_ORGANIZED,
        EvolveProductTypeKey.SHERPATH_IA,
      ];
    }
    case SyllabusItemAction.ADD_ASSESSMENT_BUILDER: {
      return [
        EvolveProductTypeKey.ELSEVIER_ASSESSMENT_BUILDER,
      ];
    }
    case SyllabusItemAction.ADD_SIMCHART: {
      return [
        EvolveProductTypeKey.SIMULATION_SIM_CHART_NG,
      ];
    }
    case SyllabusItemAction.ADD_SHADOW_HEALTH: {
      return [
        EvolveProductTypeKey.SHADOW_HEALTH,
      ];
    }
    default:
      return null;
  }
};

export const getEvolveProductsForAction = (action: SyllabusItemAction, evolveProducts: EvolveProductDto[]): EvolveProductDto[] => {
  const allEvolveProducts: EvolveProductDto[] = flattenTree(evolveProducts, 'components');
  const productTypeKeys = getProductTypeKeysByAction(action);
  return allEvolveProducts.filter((evolveProduct) => {
    return productTypeKeys && productTypeKeys.includes(evolveProduct.productTypeKey);
  });
};

export const getSyllabusItemEvolveProduct = (
  syllabusItem: SyllabusItemDto,
  assignment: AssignmentDto,
  evolveProducts: EvolveProductDto[],
  catalog: CatalogWithExternalEntitiesDto
): EvolveProductDto => {

  const allEvolveProducts: EvolveProductDto[] = flattenTree(evolveProducts, 'components');

  if (syllabusItem.type === ActiveSyllabusItemTypeDto.EBOOK_READING) {
    if (assignment) {
      return allEvolveProducts.find((evolveProduct) => {
        return getVbId(evolveProduct) === assignment.isbn;
      });
    }
    return allEvolveProducts.find((evolveProduct) => {
      return getVbId(evolveProduct) === getIsbnFromEbookSyllabusItem(syllabusItem);
    });
  }

  if (assignment) {
    return allEvolveProducts.find((evolveProduct) => {
      return evolveProduct.isbn === assignment.isbn;
    });
  }

  return allEvolveProducts.find((evolveProduct) => {
    const catalogItem = getCatalogItemFromSyllabusItem(catalog, syllabusItem);
    return catalogItem && evolveProduct.isbn === catalogItem.attributes.isbn;
  });

};

export const filterResourceByStatus = (status: string, resourceStatusMap: ResourceStatusMap): SyllabusItemDto[] => {
  return flattenDepth(Object.values(resourceStatusMap).map(resource =>
    resource[status].map(taxonomyTree => [taxonomyTree.syllabusItem])), 2);
};

export const getEbookSyllabus = (syllabusItems: SyllabusItemDto[], assignments: AssignmentDto[]): { visible: SyllabusItemDto[]; notVisible: SyllabusItemDto[] } => {
  const ebookSyllabuses = syllabusItems.filter(syllabus => syllabus.type === ActiveSyllabusItemTypeDto.EBOOK_READING);
  return ebookSyllabuses.reduce((acc, cur) => {
    const externalId = cur.externalIdentifiers.find(e => e.type === ExternalIdTypeDto.ASSIGNMENT_ID);
    if (!externalId) {
      return {
        notVisible: acc.notVisible.concat(cur),
        visible: acc.visible
      };
    }
    const ebookAssignment = assignments.find(assignment => assignment.id.toString() === externalId.value);
    if (ebookAssignment && ebookAssignment.availableDate) {
      return {
        notVisible: acc.notVisible,
        visible: acc.visible.concat(cur)
      };
    }
    return {
      notVisible: acc.notVisible.concat(cur),
      visible: acc.visible
    };
  }, { visible: [], notVisible: [] });
};

const getSyllabusItemsAndTheirAncestor = (filteredSyllabusItems: SyllabusItemDto[], syllabusItems: SyllabusItemDto[]): SyllabusItemDto[] => {
  const uniqParentIdOnResource = uniqBy(filteredSyllabusItems, 'parentId');
  const folders = syllabusItems.filter(syllabusItem => isFolder(syllabusItem));
  const parentResources = uniqParentIdOnResource.reduce((acc, cur) => acc.concat(getAncestorLineFolders(folders, cur)), []);
  return uniqBy(filteredSyllabusItems.concat(parentResources), 'id');
};

export const filterSyllabusItemsByTaxons = (syllabusItemIds: string[], props: {
  selectedTaxonomies: string[];
  syllabusItemDictionary: SyllabusItemDictionary;
  catalog: CatalogWithExternalEntitiesDto;
}): string[] => {
  const {
    selectedTaxonomies,
    syllabusItemDictionary,
    catalog
  } = props;

  if (!selectedTaxonomies || !selectedTaxonomies.length) {
    return syllabusItemIds;
  }

  const selectedTaxonomiesWithChildren = getTaxonomiesWithFlattenedBranchChildren(
    catalog.catalog.included,
    selectedTaxonomies
  ).map(mapToIds);

  return syllabusItemIds.filter((syllabusItemId) => {
    const data: SyllabusItemDictionaryItem = syllabusItemDictionary[syllabusItemId];
    if (!data || !data.taxons || !data.taxons.length) {
      return false;
    }
    return data.taxons.some((taxon) => selectedTaxonomiesWithChildren.includes(taxon));
  });
};

export const getHesiTaxonIds = (catalog: CatalogWithExternalEntitiesDto): string[] => {

  if (!catalog) {
    return [];
  }

  const hesiExams = catalog.catalog.data.filter((item) => {
    return item.type === RecContentItemTypeDto.HESI_EXAM;
  });

  if (!hesiExams || !hesiExams.length) {
    return [];
  }

  const hesiChapters = hesiExams.reduce((acc, cur) => {
    return [...acc, ...cur.relationships.taxonomies.data.map((taxon) => taxon.id)];
  }, []);

  return uniq(hesiChapters);
};

export const filterSyllabusItemsByHesiFocusChapters = (syllabusItemIds: string[], props: {
  isHesiFocusChapterFilterEnabled: boolean;
  syllabusItemDictionary: SyllabusItemDictionary;
  catalog: CatalogWithExternalEntitiesDto;
}): string[] => {
  const {
    isHesiFocusChapterFilterEnabled,
    syllabusItemDictionary,
    catalog
  } = props;

  if (!syllabusItemIds || !syllabusItemIds.length) {
    return syllabusItemIds;
  }

  if (!isHesiFocusChapterFilterEnabled) {
    return syllabusItemIds;
  }

  return filterSyllabusItemsByTaxons(syllabusItemIds, {
    syllabusItemDictionary,
    catalog,
    selectedTaxonomies: getHesiTaxonIds(catalog)
  });
};

export const filterSyllabusItemsByEbookAndClinicalSkillsFilterState = (syllabusItemIds: string[], props: {
  ebookFilterState: EbookFilterState;
  clinicalSkillsFilterState: ClinicalSkillsFilterState;
  syllabusItemDictionary: SyllabusItemDictionary;
  catalog: CatalogWithExternalEntitiesDto;
}): string[] => {
  const {
    ebookFilterState,
    clinicalSkillsFilterState,
    syllabusItemDictionary,
    catalog
  } = props;

  const selectedTaxonomies = [
    ...getSelectedTaxonomiesFromEbookFilterState(ebookFilterState),
    ...getSelectedTaxonomiesFromEbookFilterState(clinicalSkillsFilterState)
  ];
  if (!selectedTaxonomies || !selectedTaxonomies.length) {
    return syllabusItemIds;
  }

  return filterSyllabusItemsByTaxons(syllabusItemIds, {
    syllabusItemDictionary,
    catalog,
    selectedTaxonomies
  });
};

export const filterSyllabusItemsByClinicalSkillsFilterState = (syllabusItemIds: string[], props: {
  clinicalSkillsFilterState: ClinicalSkillsFilterState;
  syllabusItemDictionary: SyllabusItemDictionary;
  catalog: CatalogWithExternalEntitiesDto;
}): string[] => {
  const {
    clinicalSkillsFilterState,
    syllabusItemDictionary,
    catalog
  } = props;

  const selectedTaxonomies = getSelectedTaxonomiesFromClinicalSkillsFilterState(clinicalSkillsFilterState);
  if (!selectedTaxonomies || !selectedTaxonomies.length) {
    return syllabusItemIds;
  }

  return filterSyllabusItemsByTaxons(syllabusItemIds, {
    syllabusItemDictionary,
    catalog,
    selectedTaxonomies
  });
};

export const filterSyllabusItemsByStatus = (
  syllabusItemIds: string[],
  props: {
    resourceStatusMap: ResourceStatusMap;
    syllabusItems: SyllabusItemDto[];
    assignments: AssignmentDto[];
    resourceStatusFilterState: string[];
  }
): string[] => {
  const {
    resourceStatusMap,
    syllabusItems,
    assignments,
    resourceStatusFilterState
  } = props;
  if (!syllabusItemIds.length || !resourceStatusFilterState.length) {
    return syllabusItemIds;
  }
  const ebook = getEbookSyllabus(syllabusItems, assignments);
  const filteredResource: SyllabusItemDto[] = flattenDepth(resourceStatusFilterState
    .map(status => {
      switch (status) {
        case CoursePlanResourceStatusMap[CoursePlanResourceStatus.NOT_VISIBLE].value:
          return filterResourceByStatus('added', resourceStatusMap).concat(ebook.notVisible);
        case CoursePlanResourceStatusMap[CoursePlanResourceStatus.NOT_DUE].value: {
          const notVisibleResources = filterResourceByStatus('added', resourceStatusMap);
          const visibleResources = filterResourceByStatus('visible', resourceStatusMap);
          return [...visibleResources, ...notVisibleResources, ...ebook.visible, ...ebook.notVisible];
        }
        case CoursePlanResourceStatusMap[CoursePlanResourceStatus.VISIBLE].value: {
          const dueResources = filterResourceByStatus('assigned', resourceStatusMap);
          const visibleResources = filterResourceByStatus(status, resourceStatusMap);
          return [...dueResources, ...visibleResources, ...ebook.visible];
        }
        case CoursePlanResourceStatusMap[CoursePlanResourceStatus.DUE].value:
          return filterResourceByStatus('assigned', resourceStatusMap);
        default:
          return [];
      }
    }), 1);
  return filteredResource.filter((x) => syllabusItemIds.includes(x.id)).map(mapToIds);
};

export const filterSyllabusItemsByProduct = (syllabusItemIds: string[], props: {
  catalog: CatalogWithExternalEntitiesDto;
  syllabusItems: SyllabusItemDto[];
  assignments: AssignmentDto[];
  evolveProducts: EvolveProductDto[];
  selectedProduct: string;
}): string[] => {
  const {
    catalog,
    assignments,
    syllabusItems,
    evolveProducts,
    selectedProduct,
  } = props;
  if (!syllabusItemIds.length || !selectedProduct || selectedProduct === ALL_OPTION_VALUE) {
    return syllabusItemIds;
  }
  const currentSelectedEvolveProduct = selectedProduct && evolveProducts && evolveProducts.find(product => product.isbn === selectedProduct);

  if (!currentSelectedEvolveProduct) {
    return syllabusItemIds;
  }

  return syllabusItems
    .filter((x) => syllabusItemIds.includes(x.id))
    .filter(syllabusItem => {
      const associatedAssignment = getAssociatedAssignment(syllabusItem, assignments);

      if (associatedAssignment) {
        return flattenTree([currentSelectedEvolveProduct], 'components').some((component) => {
          return getVbId(component) === associatedAssignment.isbn || component.isbn === associatedAssignment.isbn;
        });
      }

      const isbn = getIsbnFromSyllabusItem(syllabusItem, catalog);
      if (isbn) {
        return flattenTree([currentSelectedEvolveProduct], 'components').some((component) => {
          return getVbId(component) === isbn || component.isbn === isbn;
        });
      }

      return true;

    })
    .map(mapToIds);
};

export const getFilterTypeFromSyllabusItem = (
  syllabusItem: SyllabusItemDto,
  syllabusItemDictionary: SyllabusItemDictionary,
  catalog: CatalogWithExternalEntitiesDto,
  isStudentRole = false,
  evolveInstructorResourceDataMap: Record<string, CatalogEvolveResourceExternalEntityDtoParsed['_parsedData']> = null
): {
  type: string;
  typeDisplay: string;
  parentType: string;
  parentDisplay: string;
} => {
  if (!isEvolveResourceType(syllabusItem.type as ActiveSyllabusItemTypeDto)) {
    return {
      type: syllabusItem.type,
      typeDisplay: getFilterTypeDisplay(syllabusItem.type),
      parentType: null,
      parentDisplay: null,
    };
  }

  if (!syllabusItemDictionary) {
    return {
      type: syllabusItem.type,
      typeDisplay: getFilterTypeDisplay(syllabusItem.type),
      parentType: null,
      parentDisplay: null,
    };
  }

  const dictionaryItem: SyllabusItemDictionaryItem = syllabusItemDictionary[syllabusItem.id];
  if (!syllabusItemDictionary || !dictionaryItem || !dictionaryItem.catalogItem) {
    return {
      type: syllabusItem.type,
      typeDisplay: getFilterTypeDisplay(syllabusItem.type),
      parentType: null,
      parentDisplay: null,
    };
  }
  return getFilterTypeFromContentItem(dictionaryItem.catalogItem, catalog, isStudentRole, evolveInstructorResourceDataMap);
};

export const filterSyllabusItemsByType = (syllabusItemIds: string[], props: {
  syllabusItems: SyllabusItemDto[];
  resourceTypeFilterState: ActiveSyllabusItemTypeDto[];
  syllabusItemDictionary: SyllabusItemDictionary;
  catalog: CatalogWithExternalEntitiesDto;
  evolveInstructorResourceDataMap: Record<string, CatalogEvolveResourceExternalEntityDtoParsed['_parsedData']>;
}): string[] => {
  const {
    resourceTypeFilterState,
    syllabusItems,
    evolveInstructorResourceDataMap
  } = props;
  if (!syllabusItemIds.length || !resourceTypeFilterState.length) {
    return syllabusItemIds;
  }
  return syllabusItems
    .filter((syllabusItem) => syllabusItemIds.includes(syllabusItem.id))
    .filter((syllabusItem) => {
      const filterTypeConfig = getFilterTypeFromSyllabusItem(syllabusItem, props.syllabusItemDictionary, props.catalog, true, evolveInstructorResourceDataMap);
      return isTypeInSelectedTypes(resourceTypeFilterState, filterTypeConfig.type) || isTypeInSelectedTypes(resourceTypeFilterState, filterTypeConfig.parentType);
    })
    .map(mapToIds);
};

export const filterSyllabusItemsByGrading = (syllabusItemIds: string[], props: {
  syllabusItems: SyllabusItemDto[];
  resourceGradingFilterState: string[];
  assignments: AssignmentDto[];
}): string[] => {
  const {
    syllabusItems,
    assignments,
    resourceGradingFilterState
  } = props;
  if (!syllabusItemIds.length || !resourceGradingFilterState.length) {
    return syllabusItemIds;
  }
  return syllabusItems
    .filter((x) => syllabusItemIds.includes(x.id))
    .filter(syllabusItem => {
      const associatedAssignment = getAssociatedAssignment(syllabusItem, assignments);
      return associatedAssignment !== null && resourceGradingFilterState.includes(associatedAssignment.assignmentGradeType);
    })
    .map(mapToIds);
};

export const filterSyllabusItems = (props: {
  catalog: CatalogWithExternalEntitiesDto;
  resourceStatusMap: ResourceStatusMap;
  ebookFilterState: EbookFilterState;
  clinicalSkillsFilterState: ClinicalSkillsFilterState;
  syllabusItems: SyllabusItemDto[];
  assignments: AssignmentDto[];
  evolveProducts: EvolveProductDto[];
  selectedProduct: string;
  resourceTypeFilterState: ActiveSyllabusItemTypeDto[];
  resourceStatusFilterState: string[];
  resourceGradingFilterState: string[];
  syllabusItemDictionary: SyllabusItemDictionary;
  isHesiFocusChapterFilterEnabled: boolean;
  evolveInstructorResourceDataMap: Record<string, CatalogEvolveResourceExternalEntityDtoParsed['_parsedData']>;
}): SyllabusItemDto[] => {

  const {
    catalog,
    resourceStatusMap,
    syllabusItems,
    assignments,
    resourceStatusFilterState,
    ebookFilterState,
    clinicalSkillsFilterState,
    evolveProducts,
    resourceTypeFilterState,
    selectedProduct,
    syllabusItemDictionary,
    resourceGradingFilterState,
    isHesiFocusChapterFilterEnabled,
    evolveInstructorResourceDataMap
  } = props;

  let syllabusItemIds = syllabusItems.map(mapToIds);

  syllabusItemIds = filterSyllabusItemsByEbookAndClinicalSkillsFilterState(syllabusItemIds, { clinicalSkillsFilterState, syllabusItemDictionary, ebookFilterState, catalog });
  syllabusItemIds = filterSyllabusItemsByHesiFocusChapters(syllabusItemIds, { syllabusItemDictionary, isHesiFocusChapterFilterEnabled, catalog });
  syllabusItemIds = filterSyllabusItemsByStatus(syllabusItemIds, { resourceStatusMap, syllabusItems, assignments, resourceStatusFilterState });
  syllabusItemIds = filterSyllabusItemsByProduct(syllabusItemIds, { catalog, assignments, selectedProduct, evolveProducts, syllabusItems });
  syllabusItemIds = filterSyllabusItemsByType(syllabusItemIds, { resourceTypeFilterState, syllabusItems, syllabusItemDictionary, catalog, evolveInstructorResourceDataMap });
  syllabusItemIds = filterSyllabusItemsByGrading(syllabusItemIds, { resourceGradingFilterState, syllabusItems, assignments });

  return getSyllabusItemsAndTheirAncestor(syllabusItems.filter((x) => syllabusItemIds.includes(x.id)), syllabusItems);
};

export const getResourceTypeFilterForCoursePlan = (
  syllabusItems: SyllabusItemDto[],
  syllabusItemDictionary: SyllabusItemDictionary,
  catalog: CatalogWithExternalEntitiesDto,
  isStudentRole = false,
  evolveInstructorResourceDataMap: Record<string, CatalogEvolveResourceExternalEntityDtoParsed['_parsedData']> = null
): ResourceFilterMap => {

  const unsortedResponse: ResourceFilterMap = syllabusItems.reduce((acc, syllabusItem) => {
    if (syllabusItem.type === ActiveSyllabusItemTypeDto.FOLDER) {
      return acc;
    }

    const filterType = getFilterTypeFromSyllabusItem(syllabusItem, syllabusItemDictionary, catalog, isStudentRole, evolveInstructorResourceDataMap);
    if (!filterType) {
      return acc;
    }

    if (filterType.parentType) {
      const existingChildren = acc[filterType.parentType] ? acc[filterType.parentType].children : {};
      return {
        ...acc,
        [filterType.parentType]: {
          display: filterType.parentDisplay,
          value: filterType.parentType,
          children: {
            ...existingChildren,
            [filterType.type]: {
              display: filterType.typeDisplay,
              value: filterType.type,
              parent: filterType.parentType
            }
          }
        }
      };
    }
    // If existing type then return, prevent overwrite
    if (acc[filterType.type]) {
      return acc;
    }
    return {
      ...acc,
      [filterType.type]: {
        display: filterType.typeDisplay,
        value: filterType.type,
      }
    };
  }, {});

  return sortFilterMap(unsortedResponse);
};

export const isFilteringItems = (
  selectedProduct: string,
  ebookFilterState: ClinicalSkillsFilterState,
  clinicalSkillsFilterState: ClinicalSkillsFilterState,
  resourceTypeFilterState: SyllabusItemTypeDto[],
  resourceStatusFilterState: string[],
  resourceGradingFilterState: string[],
  isHesiFocusChapterFilterEnabled: boolean,
): boolean => {

  if (isHesiFocusChapterFilterEnabled) {
    return true;
  }

  const isAnyType = isEmpty(resourceTypeFilterState);
  const isAnyProduct = !selectedProduct || selectedProduct === ALL_OPTION_VALUE;
  const isAnyStatus = isEmpty(resourceStatusFilterState);
  const selectedTaxonomies = getSelectedTaxonomiesFromEbookFilterState(ebookFilterState);
  const selectedClinicalSkills = getSelectedTaxonomiesFromEbookFilterState(clinicalSkillsFilterState);
  const isAnyTaxonomies = selectedTaxonomies.length === 0;
  const isAnyClinicalSkills = selectedClinicalSkills.length === 0;
  const isAnyGrading = isEmpty(resourceGradingFilterState);
  const isNotFiltered = !(!isAnyType || !isAnyProduct || !isAnyStatus || !isAnyTaxonomies || !isAnyGrading || !isAnyClinicalSkills);

  if (isNotFiltered) {
    return false;
  }
  return true;
};

export const memoizedGetResourceTypeFilterForCoursePlan = memoizeOne(getResourceTypeFilterForCoursePlan);

export const memoizedIsFilteringItems = memoizeOne(isFilteringItems);

export type SyllabusItemDictionaryItem = {
  taxons: string[];
  subtitle: string;
  title: string;
  includes: Partial<Record<ActiveSyllabusItemTypeDto, string[]>>;
  includedIn: Partial<Record<ActiveSyllabusItemTypeDto, string[]>>;
  learningDuration: number;
  assignment: AssignmentDto;
  catalogItem: RecContentItemDto;
  type: SyllabusItemTypeDto;
}

export type SyllabusItemDictionary = Record<string, SyllabusItemDictionaryItem>

export const assignmentTypesWithNotEditableTitle = [
  AssignmentType.EBOOK_READING,
];

export const getEvolveProductTitleByIsbn = (evolveProducts: EvolveProductDto[], isbn: string) => {
  if (!evolveProducts || !evolveProducts.length) {
    return null;
  }
  const evolveProduct = evolveProducts.find((product) => {
    return product.isbn === isbn;
  });

  if (!evolveProduct) {
    return null;
  }
  return evolveProduct.title;
};

export const getSyllabusItemDictionaryItem = (
  syllabusItem: SyllabusItemDto,
  catalogDto: CatalogWithExternalEntitiesDto,
  assignments: AssignmentDto[],
  primaryTaxonomies: PrimaryTaxonomy[],
  isOsmosisEnabled: boolean,
  evolveProducts: EvolveProductDto[]
  // eslint-disable-next-line sonarjs/cognitive-complexity
): SyllabusItemDictionaryItem => {
  if (syllabusItem.type === ActiveSyllabusItemTypeDto.FOLDER) {
    return {
      taxons: null,
      title: syllabusItem.title,
      subtitle: syllabusItem.subtitle,
      includes: null,
      includedIn: null,
      assignment: null,
      catalogItem: null,
      learningDuration: null,
      type: null
    };
  }

  const assignment = getAssociatedAssignment(syllabusItem, assignments);

  let catalogItem;
  let externalEntity;
  let learningDuration = null;
  let includes;
  let includedIn;

  if (assignment) {

    if (assignment.assignmentType === AssignmentType.EBOOK_READING) {
      const bookTaxonomy = getBookTaxonomy(assignment.isbn, primaryTaxonomies);
      const chapterPageRangeMap = getChapterPageRangeMapFromAssignmentGoals(assignment.assignmentGoals, bookTaxonomy);
      let { title } = assignment;
      let { subtitle } = assignment;

      // Here I have chosen to rebuild the assignment title from the assignment goals to include the non-truncated
      // title on the course plan
      // This is extra compute and could be reverted to use the assignment.title if we wanted to go back to showing the
      // truncated titles from the assignment
      if (assignment.assignmentGoals && assignment.assignmentGoals.length) {
        title = getEbookAssignmentTitle(chapterPageRangeMap, bookTaxonomy, false);
        if (!title) {
          title = getAllChapterPageRangesFromAssignmentGoals(assignment.assignmentGoals);
        }
      }

      if (bookTaxonomy) {
        subtitle = getEbookTitle(bookTaxonomy);
      }

      const taxons = assignment.assignmentGoals
        ? assignment.assignmentGoals.map(goal => goal.vtwId)
        : [];
      const pageRanges = Object.values(chapterPageRangeMap).join(EbookPageRangeSeparators.CHUNK_SEPARATOR);
      learningDuration = getTimeEstimateInSecondFromPageRanges(pageRanges);

      return {
        taxons,
        title,
        subtitle,
        assignment,
        catalogItem: null,
        learningDuration,
        includes: null,
        includedIn: null,
        type: syllabusItem.type
      };
    }

    catalogItem = catalogDto.catalog.data.find((contentItem) => {
      return contentItem.attributes.contentId === assignment.contentId && contentItem.attributes.isbn === assignment.isbn;
    });

    externalEntity = getExternalEntity(catalogItem, catalogDto);
    learningDuration = getLearningDurationFromExternalEntity(externalEntity, catalogItem);
    includes = getContentItemIncludes(catalogItem, catalogDto, isOsmosisEnabled);
    includedIn = getContentItemIncludedIn(catalogItem, catalogDto, isOsmosisEnabled);

    // For all cases where title is editable
    if (!assignmentTypesWithNotEditableTitle.includes(assignment.assignmentType)) {
      const evolveProductAsSubtitle = assignment.assignmentType === AssignmentType.SHADOW_HEALTH ? getEvolveProductTitleByIsbn(evolveProducts, assignment.isbn) : '';
      const taxons = catalogItem ? catalogItem.relationships.taxonomies.data.map(x => x.id) : null;
      return {
        taxons,
        title: assignment.title,
        // Here we are opting to calc the subtitle to prevent cutoff at max character length
        subtitle: evolveProductAsSubtitle || (getTaxonsTitle(taxons, primaryTaxonomies, null) || assignment.subtitle),
        assignment,
        catalogItem,
        learningDuration,
        includes,
        includedIn,
        type: syllabusItem.type
      };
    }

  } else {
    catalogItem = getCatalogItemFromSyllabusItem(catalogDto, syllabusItem);
    includes = getContentItemIncludes(catalogItem, catalogDto, isOsmosisEnabled);
    includedIn = getContentItemIncludedIn(catalogItem, catalogDto, isOsmosisEnabled);
  }

  if (!catalogItem) {

    if (assignment) {
      return {
        title: assignment.title,
        subtitle: assignment.subtitle,
        taxons: null,
        assignment,
        catalogItem,
        learningDuration,
        includes,
        includedIn,
        type: syllabusItem.type
      };
    }
    return {
      title: syllabusItem.title,
      subtitle: syllabusItem.subtitle,
      taxons: null,
      assignment,
      catalogItem,
      learningDuration,
      includes,
      includedIn,
      type: syllabusItem.type
    };
  }

  const taxons = catalogItem.relationships.taxonomies.data.map(x => x.id);
  externalEntity = getExternalEntity(catalogItem, catalogDto);
  learningDuration = getLearningDurationFromExternalEntity(externalEntity, catalogItem);

  // In cases where we have the associated catalogItem on an assignment and the externalEntity we are opting
  // to use the title from the externalEntity instead of the assignment because we are out in front of the assignment
  // name scheme changes from the AXE team
  // When the AXE team updates the assignment titles to match what we have here we should revert back to using the
  // assignment title (AXE-702)
  // In some cases the student will not have the catalog external entity (Instructor Evolve Resources) so will need to rely on the assignment title

  const title = !externalEntity && assignment ? assignment.title : getTitle(catalogItem, catalogDto, primaryTaxonomies, null);

  if (syllabusItem.type === ActiveSyllabusItemTypeDto.EBOOK_READING) {
    const isbn = getIsbnFromEbookCatalogItemId(catalogItem.id);
    const bookTaxonomy = getBookTaxonomy(isbn, primaryTaxonomies);
    learningDuration = getEbookLearningDurationFromCatalogItem(catalogItem, primaryTaxonomies);

    return {
      taxons,
      title,
      subtitle: getEbookTitle(bookTaxonomy),
      assignment,
      learningDuration,
      catalogItem,
      includes,
      includedIn,
      type: syllabusItem.type
    };
  }

  // Note that since the update to allow editor for most all assignment types (see assignmentTypesWithNotEditableTitle variable)
  // As of this writing the only assignment type that could get to this point is Adaptive Lessons
  const subtitle = getSubtitle(catalogItem, catalogDto, primaryTaxonomies, evolveProducts, null);
  const assignmentSubtitle = assignment && assignment.subtitle ? assignment.subtitle : '';

  return {
    taxons,
    title,
    // Here we are opting to calc the subtitle to prevent cutoff at max character length
    subtitle: subtitle || assignmentSubtitle,
    assignment,
    catalogItem,
    learningDuration,
    includes,
    includedIn,
    type: syllabusItem.type
  };
};

// This is a clone of getSyllabusItemDictionaryItem but it uses the externalEntitiesMap to get the external entity
// instead of iterating through catalogDto multiple times
// Can remove the original function once all calls to getSyllabusItemDictionaryItem are updated to use this new function
export const getSyllabusItemDictionaryItemClone = (
  syllabusItem: SyllabusItemDto,
  catalogDto: CatalogWithExternalEntitiesDto,
  assignments: AssignmentDto[],
  primaryTaxonomies: PrimaryTaxonomy[],
  isOsmosisEnabled: boolean,
  evolveProducts: EvolveProductDto[],
  externalEntitiesMap: Map<string, CatalogExternalEntityDto>,
  catalogDataMap: Map<string, RecContentItemDto[]>,
  contentItemIncludedInMap: Map<string, Partial<Record<ActiveSyllabusItemTypeDto, string[]>>>
  // eslint-disable-next-line sonarjs/cognitive-complexity
): SyllabusItemDictionaryItem => {
  if (syllabusItem.type === ActiveSyllabusItemTypeDto.FOLDER) {
    return {
      taxons: null,
      title: syllabusItem.title,
      subtitle: syllabusItem.subtitle,
      includes: null,
      includedIn: null,
      assignment: null,
      catalogItem: null,
      learningDuration: null,
      type: null
    };
  }

  const assignment = getAssociatedAssignment(syllabusItem, assignments);

  let catalogItem;
  let externalEntity;
  let learningDuration = null;
  let includes;
  let includedIn;

  if (assignment) {

    if (assignment.assignmentType === AssignmentType.EBOOK_READING) {
      const bookTaxonomy = getBookTaxonomy(assignment.isbn, primaryTaxonomies);
      const chapterPageRangeMap = getChapterPageRangeMapFromAssignmentGoals(assignment.assignmentGoals, bookTaxonomy);
      let { title } = assignment;
      let { subtitle } = assignment;

      // Here I have chosen to rebuild the assignment title from the assignment goals to include the non-truncated
      // title on the course plan
      // This is extra compute and could be reverted to use the assignment.title if we wanted to go back to showing the
      // truncated titles from the assignment
      if (assignment.assignmentGoals && assignment.assignmentGoals.length) {
        title = getEbookAssignmentTitle(chapterPageRangeMap, bookTaxonomy, false);
        if (!title) {
          title = getAllChapterPageRangesFromAssignmentGoals(assignment.assignmentGoals);
        }
      }

      if (bookTaxonomy) {
        subtitle = getEbookTitle(bookTaxonomy);
      }

      const taxons = assignment.assignmentGoals
        ? assignment.assignmentGoals.map(goal => goal.vtwId)
        : [];
      const pageRanges = Object.values(chapterPageRangeMap).join(EbookPageRangeSeparators.CHUNK_SEPARATOR);
      learningDuration = getTimeEstimateInSecondFromPageRanges(pageRanges);

      return {
        taxons,
        title,
        subtitle,
        assignment,
        catalogItem: null,
        learningDuration,
        includes: null,
        includedIn: null,
        type: syllabusItem.type
      };
    }

    catalogItem = catalogDataMap.get(assignment.contentId)?.find(contentItem => contentItem.attributes.isbn === assignment.isbn);

    externalEntity = getExternalEntityClone(catalogItem, externalEntitiesMap);
    learningDuration = getLearningDurationFromExternalEntity(externalEntity, catalogItem);
    includes = getContentItemIncludesClone(catalogItem, isOsmosisEnabled, externalEntitiesMap, catalogDataMap);
    includedIn = contentItemIncludedInMap.get(catalogItem?.attributes?.contentId) || null;
    // For all cases where title is editable
    if (!assignmentTypesWithNotEditableTitle.includes(assignment.assignmentType)) {
      const evolveProductAsSubtitle = assignment.assignmentType === AssignmentType.SHADOW_HEALTH ? getEvolveProductTitleByIsbn(evolveProducts, assignment.isbn) : '';
      const taxons = catalogItem ? catalogItem.relationships.taxonomies.data.map(x => x.id) : null;
      const evolveResourceTypeSubtitle = assignment.assignmentType === AssignmentType.EVOLVE_RESOURCE
        ? getSubtitleClone(catalogItem, externalEntitiesMap, primaryTaxonomies, evolveProducts, null)
        : '';
      return {
        taxons,
        title: assignment.title,
        // Here we are opting to calc the subtitle to prevent cutoff at max character length
        subtitle: evolveProductAsSubtitle || evolveResourceTypeSubtitle || (getTaxonsTitle(taxons, primaryTaxonomies, null) || assignment.subtitle),
        assignment,
        catalogItem,
        learningDuration,
        includes,
        includedIn,
        type: syllabusItem.type
      };
    }
  } else {
    catalogItem = getCatalogItemFromSyllabusItem(catalogDto, syllabusItem);
    includes = getContentItemIncludesClone(catalogItem, isOsmosisEnabled, externalEntitiesMap, catalogDataMap);
    includedIn = contentItemIncludedInMap.get(catalogItem?.attributes?.contentId) || null;
  }

  if (!catalogItem) {

    if (assignment) {
      return {
        title: assignment.title,
        subtitle: assignment.subtitle,
        taxons: null,
        assignment,
        catalogItem,
        learningDuration,
        includes,
        includedIn,
        type: syllabusItem.type
      };
    }
    return {
      title: syllabusItem.title,
      subtitle: syllabusItem.subtitle,
      taxons: null,
      assignment,
      catalogItem,
      learningDuration,
      includes,
      includedIn,
      type: syllabusItem.type
    };
  }

  const taxons = catalogItem.relationships.taxonomies.data.map(x => x.id);
  externalEntity = getExternalEntityClone(catalogItem, externalEntitiesMap);
  learningDuration = getLearningDurationFromExternalEntity(externalEntity, catalogItem);

  // In cases where we have the associated catalogItem on an assignment and the externalEntity we are opting
  // to use the title from the externalEntity instead of the assignment because we are out in front of the assignment
  // name scheme changes from the AXE team
  // When the AXE team updates the assignment titles to match what we have here we should revert back to using the
  // assignment title (AXE-702)
  // In some cases the student will not have the catalog external entity (Instructor Evolve Resources) so will need to rely on the assignment title

  const title = !externalEntity && assignment ? assignment.title : getTitleClone(catalogItem, externalEntitiesMap, primaryTaxonomies, null);

  if (syllabusItem.type === ActiveSyllabusItemTypeDto.EBOOK_READING) {
    const isbn = getIsbnFromEbookCatalogItemId(catalogItem.id);
    const bookTaxonomy = getBookTaxonomy(isbn, primaryTaxonomies);
    learningDuration = getEbookLearningDurationFromCatalogItem(catalogItem, primaryTaxonomies);

    return {
      taxons,
      title,
      subtitle: getEbookTitle(bookTaxonomy),
      assignment,
      learningDuration,
      catalogItem,
      includes,
      includedIn,
      type: syllabusItem.type
    };
  }

  // Note that since the update to allow editor for most all assignment types (see assignmentTypesWithNotEditableTitle variable)
  // As of this writing the only assignment type that could get to this point is Adaptive Lessons
  const subtitle = getSubtitleClone(catalogItem, externalEntitiesMap, primaryTaxonomies, evolveProducts, null);
  const assignmentSubtitle = assignment && assignment.subtitle ? assignment.subtitle : '';

  return {
    taxons,
    title,
    // Here we are opting to calc the subtitle to prevent cutoff at max character length
    subtitle: subtitle || assignmentSubtitle,
    assignment,
    catalogItem,
    learningDuration,
    includes,
    includedIn,
    type: syllabusItem.type
  };
};

export const getSyllabusDictionary = (
  catalogDto: CatalogWithExternalEntitiesDto,
  syllabusItems: SyllabusItemDto[],
  assignments: AssignmentDto[],
  primaryTaxonomies: PrimaryTaxonomy[],
  evolveProducts: EvolveProductDto[],
  isOsmosisEnabled: boolean
): SyllabusItemDictionary => {

  // The main point of this function is to normalize the subtitles displayed on the course plan
  // instead of trying to create them on each assignment as the time of assignment creation
  // We are also rebuilding assignment titles here but as mentioned in a comment below we could remove this
  // in the future

  return syllabusItems.reduce((syllabusItemDictionaryMap: SyllabusItemDictionary, syllabusItem): SyllabusItemDictionary => {
    return {
      ...syllabusItemDictionaryMap,
      [syllabusItem.id]: getSyllabusItemDictionaryItem(syllabusItem, catalogDto, assignments, primaryTaxonomies, isOsmosisEnabled, evolveProducts)
    };
  }, {});

};

export const getSyllabusDictionaryClone = (
  catalogDto: CatalogWithExternalEntitiesDto,
  syllabusItems: SyllabusItemDto[],
  assignments: AssignmentDto[],
  primaryTaxonomies: PrimaryTaxonomy[],
  evolveProducts: EvolveProductDto[],
  isOsmosisEnabled: boolean,
  externalEntitiesMap: Map<string, CatalogExternalEntityDto>,
  catalogDataMap: Map<string, RecContentItemDto[]>
): SyllabusItemDictionary => {

  // The main point of this function is to normalize the subtitles displayed on the course plan
  // instead of trying to create them on each assignment as the time of assignment creation
  // We are also rebuilding assignment titles here but as mentioned in a comment below we could remove this
  // in the future

  const contentItemIncludedInMap = buildContentItemIncludedInMap(catalogDto, isOsmosisEnabled, externalEntitiesMap);
  const syllabusItemDictionaryMap: SyllabusItemDictionary = {};

  syllabusItems.forEach(syllabusItem => {
    syllabusItemDictionaryMap[syllabusItem.id] = getSyllabusItemDictionaryItemClone(
      syllabusItem,
      catalogDto,
      assignments,
      primaryTaxonomies,
      isOsmosisEnabled,
      evolveProducts,
      externalEntitiesMap,
      catalogDataMap,
      contentItemIncludedInMap
    );
  });

  return syllabusItemDictionaryMap;

};

export const isHighlightableAssignment = (
  dueDate: string,
): boolean => {
  return moment(now()).add(7, 'days').isSameOrAfter(dueDate);
};

enum EAQAssignmentTypes {
  MASTERY = AssignmentType.MASTERY,
  STANDARD = AssignmentType.STANDARD,
  QUIZ_BY_QUESTION = AssignmentType.QUIZ_BY_QUESTION,
  AUTHESS = AssignmentType.AUTHESS,
}

const assignmentEAQQuizTypeToText: Record<EAQAssignmentTypes, string> = {
  [EAQAssignmentTypes.MASTERY]: 'MASTERY',
  [EAQAssignmentTypes.STANDARD]: 'CUSTOM',
  [EAQAssignmentTypes.QUIZ_BY_QUESTION]: 'CUSTOM QUESTIONS',
  [EAQAssignmentTypes.AUTHESS]: 'NGN',
};

export const mapMasteryGoalToText: Partial<Record<number, string>> = {
  1: 'Novice',
  2: 'Intermediate',
  3: 'Proficient'
};

export const displayEAQType = (assignment: AssignmentDto): string => {
  if (!assignment || !assignment.assignmentType || !assignment.assignmentGoals) {
    return '';
  }

  const type = assignment.assignmentType;
  const typeText = `${assignmentEAQQuizTypeToText[type]} |`;
  if (type === AssignmentType.MASTERY) {
    return `${typeText} ${mapMasteryGoalToText[assignment.assignmentGoals[0].goal]}`;
  }
  return `${typeText} ${assignment.assignmentGoals[0].goal} questions`;
};

export const displayEAQTopics = (assignment: AssignmentDto): string => {
  if (!assignment || !assignment.assignmentTopics || !assignment.assignmentType || assignment.assignmentType === AssignmentType.QUIZ_BY_QUESTION) {
    return '';
  }

  if (assignment.assignmentTopics.length === 1) {
    return assignment.assignmentTopics[0].text;
  }

  let topics = '';
  assignment.assignmentTopics.forEach(topic => {
    if (topic === assignment.assignmentTopics[assignment.assignmentTopics.length - 1]) {
      topics = topics.concat(topic.text);
    } else {
      topics = topics.concat(topic.text).concat(', ');
    }
  });
  return topics;
};

export const isEAQ = (syllabusItem: SyllabusItemDto): boolean => syllabusItem.type === ActiveSyllabusItemTypeDto.ADAPTIVE_QUIZ;

export const canDeleteAssignment = (assignment: AssignmentDto): boolean => {
  if (!assignment) {
    return null;
  }

  if (!assignment.studentAssignments || !assignment.studentAssignments.length) {
    return true;
  }

  const startedStudentAssignment = assignment.studentAssignments.find((studentAssignment) => {
    return studentAssignment.status === AssessmentStatusDto.IN_PROGRESS
      || studentAssignment.status === AssessmentStatusDto.COMPLETED;
  });
  return !startedStudentAssignment;
};

export const isAlreadyUnassigned = (assignment: AssignmentDto): boolean => {
  if (!assignment) {
    return true;
  }

  if (assignment.assignmentGradeType !== AssignmentGradeType.NOT_GRADED) {
    return false;
  }

  return !assignment.availableDate;
};

export const getUnassignBuckets = (syllabusItems: SyllabusItemDto[], assignments: AssignmentDto[]): UnassignBuckets => {
  const defaultBuckets = {
    unassignable: [],
    skippedUnassigned: [],
    skippedStartedAndGraded: []
  };

  if (!syllabusItems) {
    return defaultBuckets;
  }

  return syllabusItems.reduce((acc, cur) => {
    const assignment = getAssociatedAssignment(cur, assignments);

    if (isAlreadyUnassigned(assignment)) {
      return {
        ...acc,
        skippedUnassigned: [...acc.skippedUnassigned, cur]
      };
    }

    if (isAssignmentStarted(assignment) && isAssignmentGraded(assignment)) {
      return {
        ...acc,
        skippedStartedAndGraded: [...acc.skippedStartedAndGraded, cur]
      };
    }

    return {
      ...acc,
      unassignable: [...acc.unassignable, cur]
    };
  }, defaultBuckets);
};

export const canDeleteSyllabusItem = (syllabusItem: SyllabusItemDto, assignments: AssignmentDto[], syllabusItems: SyllabusItemDto[]): boolean => {
  const allPossibleDeletedItems = getSyllabusItemsToDelete(syllabusItems, [syllabusItem]);
  const lockedItem = allPossibleDeletedItems.find((_syllabusItem) => {
    const assignment = getAssociatedAssignment(_syllabusItem, assignments);
    if (!assignment) {
      return false;
    }
    return !canDeleteAssignment(assignment);
  });
  return !lockedItem;
};

export const getAssignmentIdsFromSyllabusItems = (syllabusItems: SyllabusItemDto[]): string[] => {
  return syllabusItems.map(syllabusItem => {
    const assignmentExId = getAssignmentExId(syllabusItem);
    return assignmentExId ? assignmentExId.value : null;
  }).filter(value => value !== null);
};

export const getCanMakeVisible = (syllabusItem: SyllabusItemDto, assignmentsDictionary: Dictionary<AssignmentDto>, _now: moment.Moment): boolean => {
  const associatedAssignment = getAssociatedAssignmentFromAssignmentsDictionary(syllabusItem, assignmentsDictionary);
  return associatedAssignment === null
    || checkAssignmentIsUnassigned(associatedAssignment)
    || _now.isBefore(moment(getLocalDateFromServicesUTC(associatedAssignment.availableDate)));
};

export const isNotSupportedByMakeVisibleNow = (syllabusItem: SyllabusItemDto, assignmentsDictionary: Dictionary<AssignmentDto>): boolean => {
  if (![
    ActiveSyllabusItemTypeDto.SIMCHART_CASE_STUDY,
    ActiveSyllabusItemTypeDto.SIMCHART_CHART
  ].includes(syllabusItem.type as ActiveSyllabusItemTypeDto)) {
    return false;
  }
  const associatedAssignment = getAssociatedAssignmentFromAssignmentsDictionary(syllabusItem, assignmentsDictionary);
  return !associatedAssignment || !associatedAssignment.dueDate;
};

export const getMakeVisibleNowBuckets = (syllabusItems: SyllabusItemDto[], assignmentsDictionary: Dictionary<AssignmentDto>, syllabusItemDictionary: SyllabusItemDictionary = {}): {
  makeVisibleNowableItems: SyllabusItemDto[];
  alreadyVisibleItems: SyllabusItemDto[];
  unsupportedItems: SyllabusItemDto[];
  archivedItems: SyllabusItemDto[];
} => {

  const _now = moment();

  return syllabusItems.reduce((acc, cur) => {

    if (isNotSupportedByMakeVisibleNow(cur, assignmentsDictionary)) {
      return {
        ...acc,
        unsupportedItems: [...acc.unsupportedItems, cur]
      };
    }
    const syllabusItemDictionaryItem = syllabusItemDictionary[cur.id];
    const isArchivedItem = syllabusItemDictionaryItem && isCatalogItemArchived(syllabusItemDictionaryItem.catalogItem);

    if (isArchivedItem) {
      return {
        ...acc,
        archivedItems: [...acc.archivedItems, cur]
      };
    }

    if (getCanMakeVisible(cur, assignmentsDictionary, _now)) {
      return {
        ...acc,
        makeVisibleNowableItems: [...acc.makeVisibleNowableItems, cur]
      };
    }

    return {
      ...acc,
      alreadyVisibleItems: [...acc.alreadyVisibleItems, cur]
    };

  }, {
    makeVisibleNowableItems: [],
    alreadyVisibleItems: [],
    unsupportedItems: [],
    archivedItems: []
  });
};

export const getEaqTopics = (
  syllabusItem: SyllabusItemDto,
  assignment: AssignmentDto,
  messages: Messages,
) => {
  return isEAQ(syllabusItem) && displayEAQTopics(assignment) ? `${messages.TOPICS} ${displayEAQTopics(assignment)}` : null;
};

export const getEaqTopicsTitleDisplay = (
  syllabusItem: SyllabusItemDto,
  assignment: AssignmentDto,
  messages: Messages,
) => {
  const EAQTopics = getEaqTopics(syllabusItem, assignment, messages);
  return (
    <>
      {
        isEAQ(syllabusItem) && (
          <div className="c-scm-syllabus-item__subtitle">
            {displayEAQType(assignment)}
          </div>
        )
      }
      <IsRender isRender={!isNull(EAQTopics)}>
        <div className="c-scm-syllabus-item__subtitle">
          {EAQTopics}
        </div>
      </IsRender>
    </>
  );
};

export const getFirstVisibleResource = (sortedSyllabusTreeMapItems: SyllabusTreeMapItem[], hiddenItemIds: string[]) => {
  if (!sortedSyllabusTreeMapItems || !sortedSyllabusTreeMapItems.length) {
    return null;
  }
  return sortedSyllabusTreeMapItems.find((item) => {
    if (hiddenItemIds && hiddenItemIds.includes(item.syllabusItem.id)) {
      return false;
    }
    return item.syllabusItem.type !== ActiveSyllabusItemTypeDto.FOLDER;
  });
};

export const getActiveTab = (activeTabIndex: number): LANGUAGE_KEY.ALL_ITEMS | LANGUAGE_KEY.UPCOMING_ASSIGNMENTS | LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS => {
  return [LANGUAGE_KEY.ALL_ITEMS, LANGUAGE_KEY.UPCOMING_ASSIGNMENTS, LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS][activeTabIndex] as
    LANGUAGE_KEY.ALL_ITEMS | LANGUAGE_KEY.UPCOMING_ASSIGNMENTS | LANGUAGE_KEY.PAST_DUE_ASSIGNMENTS;
};

export const isOpenAuthessInNewTab = (props: {
  isNewTabEnabled: boolean;
  assignment: AssignmentDto;
  userRole: string;
  isAuthessEnabled: boolean;
}): boolean => {
  const {
    isNewTabEnabled,
    assignment,
    userRole,
    isAuthessEnabled
  } = props;
  return Boolean(isNewTabEnabled
    && !isAuthessEnabled
    && isStudent(userRole)
    && assignment
    && assignment.assignmentType === AssignmentType.AUTHESS);
};

export const getAvailableTimeMessage = (
  assignment: AssignmentDto,
  messages: Messages,
  intl: IntlShape,
  isNotPublished: boolean,
) => {

  const isPastVisibleDate = moment.utc().isAfter(moment.utc(assignment.availableDate));
  if (isNotPublished && isPastVisibleDate) {
    return messages.VISIBLE_UPON_RELEASE;
  }

  const momentIns = getLocalMomentInsFromServicesUTC(assignment.availableDate, moment.ISO_8601);
  const availableDate = assignment ? momentIns.format(DATE_PRIMARY_CHUNK) : '';
  const availableTime = assignment ? momentIns.format(TIME_PRIMARY) : '';

  return intl.formatMessage(
    {
      id: LANGUAGE_KEY.VISIBLE_ON_DATE_AT_TIME,
      defaultMessage: messages.VISIBLE_ON_DATE_AT_TIME,
      description: ''
    },
    {
      availableDate,
      availableTime,
      clientZoneAbbr,
    }
  );

};

export const getDueDateMessage = (
  assignment: AssignmentDto,
  messages: Messages,
  intl: IntlShape,
) => {
  const momentIns = getLocalMomentInsFromServicesUTC(assignment.dueDate, moment.ISO_8601);
  const dueDate = assignment ? momentIns.format(DATE_PRIMARY_CHUNK) : '';
  const dueTime = assignment ? momentIns.format(TIME_PRIMARY) : '';
  return intl.formatMessage(
    {
      id: LANGUAGE_KEY.DUE_ON_DATE_AT_TIME, defaultMessage: messages.DUE_ON_DATE_AT_TIME, description: ''
    },
    { dueDate, dueTime, clientZoneAbbr }
  );
};

export const getAssignmentIdsForStudentStatuses = (assignments: AssignmentDto[]): string[] => {
  if (!assignments || !assignments.length) {
    return [];
  }
  return assignments.filter((assignment) => {
    return isAssignmentTypeScorableMap[assignment.assignmentType];
  }).map((assignment) => {
    return assignment.id.toString();
  });
};

export const getOsmosisTokenAndConfigPromiseForSyllabus = (props: {
  fetchOsmosisTokenAction: Function;
  isOsmosisVideosEnabled: boolean;
  syllabusItem: SyllabusItemDto;
  catalog: CatalogWithExternalEntitiesDto;
  assignments: AssignmentDto[];
  primaryTaxonomies: PrimaryTaxonomy[];
  evolveProducts: EvolveProductDto[];
}): Promise<{
  osmosisToken: OsmosisTokenDto;
  osmosisIncludesConfigs: SherpathLessonOsmosisIncludesConfigs;
}> => {

  const {
    syllabusItem,
    catalog,
    assignments,
    primaryTaxonomies,
    isOsmosisVideosEnabled,
    fetchOsmosisTokenAction,
    evolveProducts
  } = props;

  if (!isOsmosisVideosEnabled) {
    return Promise.resolve(null);
  }

  const dictionaryItem = getSyllabusItemDictionaryItem(syllabusItem, catalog, assignments, primaryTaxonomies, isOsmosisVideosEnabled, evolveProducts);
  return getOsmosisTokenAndConfigPromise({
    contentItem: dictionaryItem.catalogItem,
    catalog,
    isOsmosisVideosEnabled,
    fetchOsmosisTokenAction
  });
};

export const getAssignmentTypeFromSyllabusItemType = (syllabusItemType: SyllabusItemTypeDto): AssignmentType => {
  if (!syllabusItemType) {
    return null;
  }
  if (!SyllabusItemTypeConfigMap[syllabusItemType].assignmentTypes
    || SyllabusItemTypeConfigMap[syllabusItemType].assignmentTypes.length !== 1) {
    throw new Error('Do not call function with syllabus item types that map do not map to 1 assignment type only');
  }
  return SyllabusItemTypeConfigMap[syllabusItemType].assignmentTypes[0];
};

export const isAnyFolderOpen = (syllabusItems: SyllabusItemDto[], collapsedFolderIds: string[]): boolean => {
  if (!syllabusItems) {
    return false;
  }
  return syllabusItems.filter((syllabusItem) => {
    return syllabusItem.type === ActiveSyllabusItemTypeDto.FOLDER && !syllabusItem.isDeleted;
  }).some((syllabusItem) => {
    return !collapsedFolderIds.includes(syllabusItem.id);
  });
};

export const getSyllabusItemsWithSameAssignment = (syllabusItems: SyllabusItemDto[]): string[] => {
  if (!syllabusItems || !syllabusItems.length) {
    return [];
  }

  const associatedAssignmentIds = new Map<string, string[]>();

  syllabusItems.forEach((item) => {
    const assignmentExId = getAssignmentExId(item);
    if (assignmentExId) {
      const id = assignmentExId.value;
      if (!associatedAssignmentIds.has(id)) {
        associatedAssignmentIds.set(id, []);
      }
      associatedAssignmentIds.get(id).push(item.id);
    }
  });

  const result: string[] = [];
  associatedAssignmentIds.forEach((ids) => {
    if (ids.length > 1) {
      result.push(...ids);
    }
  });

  return result;
};

export const getActiveSyllabusItemsWithDeletedAssignmentIds = (syllabusItems: SyllabusItemDto[], assignments: AssignmentDto[]): SyllabusItemDto[] => {

  if (!syllabusItems || !syllabusItems.length) {
    return [];
  }

  if (!assignments) {
    return [];
  }

  return syllabusItems.filter((syllabusItem: SyllabusItemDto) => {

    if (syllabusItem.isDeleted) {
      return false;
    }

    const assignmentExId = getAssignmentExId(syllabusItem);

    if (!assignmentExId) {
      return false;
    }

    const foundAssignment = getAssociatedAssignment(syllabusItem, assignments);

    return !foundAssignment;
  });

};

export const getEABTitle = (evolveProducts: EvolveProductDto[], messages: Messages) => {

  if (getApplicationContext(evolveProducts) === Application.SHER_EVOL) {
    return messages.ADD_MANAGE_EAB_TEST_BANK_AND_ASSESSMENT;
  }
  return messages.ADD_MANAGE_EAB_BANK_AND_ASSESSMENT;
};

export const getEnrolledStudentAssignments = (users: UserDto[], assignment: AssignmentDto) => {
  if (isNil(users) || !users.length || isNil(assignment)) {
    return null;
  }
  if (isNil(assignment.students) || isNil(assignment.studentAssignments)) {
    return null;
  }
  const enrolledUserIds = users.map(user => {
    return user.id;
  });
  return assignment.studentAssignments.filter(studentAssignment => enrolledUserIds.includes(studentAssignment.userId));
};

export const getIncludesDisplay = (props: {
  prefix: string;
  key: SyllabusItemTypeDto;
  list: string[];
}) => {
  const {
    prefix,
    key,
    list
  } = props;
  const ret = `${prefix} ${SyllabusItemTypeConfigMap[key].displayName}(s)`;
  if (!list || !list.length) {
    return ret;
  }
  return `${ret}: ${list.join(', ')}`;
};

export const getCatalogItemConfigFromSyllabusItem = (
  syllabusItem: SyllabusItemDto,
  catalog: CatalogWithExternalEntitiesDto,
  courseSectionId: string,
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  primaryTaxonomies: PrimaryTaxonomy[],
  evolveProducts: EvolveProductDto[]
) => {
  if (!syllabusItem) {
    return null;
  }
  const catalogItem = getCatalogItemFromSyllabusItem(catalog, syllabusItem);
  const isOsmosisEnabled = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.ENABLE_OSMOSIS_VIDEOS,
    courseSectionId
  ) : false;
  return getCatalogItemConfig(catalogItem, catalog, primaryTaxonomies, evolveProducts, isOsmosisEnabled);
};

export const checkAndSetChatBotCanaryFlag = (
  featureFlagsGrouped: FeatureFlagsGroupedDto[],
  users: UserDto[],
  userRole: string,
  courseSectionId: string,
  isbns: string[],
  checkCanaryTest: (
    featureFlagsGrouped: FeatureFlagsGroupedDto[],
    courseSectionId: string,
    canaryFeatureFlag: FEATURE_FLAG,
    canaryFeatureGroup: string,
    actualFeatureFlag: string
  ) => Promise<FeatureFlagDto>
): Promise<void[]> => {

  const promises = [];

  if (!isbns || !isbns.length) {
    return Promise.resolve(null);
  }

  if (!isInstructor(userRole)) {
    return Promise.resolve(null);
  }

  if (!users || users.length > 100) {
    return Promise.resolve(null);
  }

  isbns.forEach((isbn) => {
    promises.push(
      checkCanaryTest(
        featureFlagsGrouped,
        courseSectionId,
        FEATURE_FLAG.CHATBOT_CANARY_CONFIG,
        isbn,
        `${FEATURE_FLAG.ENABLE_CHATBOT}_${isbn}`
      )
    );
  });

  return Promise.all(promises);
};
