/* eslint-disable react/react-in-jsx-scope */
import { lazy } from 'react';
import { RoutePath } from './app.constants';
import { ELSCommonUIConstants } from '../els.components';

export const AppRoutes = {
  getRoutes: () => [
    {
      path: RoutePath.LOGIN,
      component: lazy(() => import(/* webpackChunkName: "Login.page" */ '../../pages/login/Login.page')),
      isPrivate: false,
      exact: false,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.login,
          pageName: 'login',
        }
      }
    },
    {
      path: RoutePath.CW_LOGOUT,
      component: lazy(() => import(/* webpackChunkName: "CoursewareLogout.page" */ '../../pages/logout/CoursewareLogout.page')),
      isPrivate: false,
      exact: false,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'logout',
        }
      }
    },
    {
      path: RoutePath.CATALOG,
      component: lazy(() => import(/* webpackChunkName: "ResourceLibrary.page" */ '../../pages/catalog/Catalog.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.generalSearch,
          pageName: 'catalog',
        }
      }
    },
    {
      path: RoutePath.HOME,
      component: lazy(() => import(/* webpackChunkName: "Home.page" */ '../../pages/home/Home.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.home,
          pageName: 'home',
        }
      }
    },
    {
      path: RoutePath.CASE_STUDY,
      component: lazy(() => import(/* webpackChunkName: "CaseStudy.page" */ '../../pages/case-study/CaseStudyPlayer.page')),
    },
    {
      path: RoutePath.GROUP_ACTIVITY,
      component: lazy(() => import(/* webpackChunkName: "GroupActivityPlayer.page" */ '../../pages/group-activity-player/GroupActivityPlayer.page')),
    },
    {
      path: RoutePath.COURSE,
      component: lazy(() => import(/* webpackChunkName: "CoursePlan.page" */ '../../pages/course-plan/CoursePlan.page')),
      isPrivate: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.home,
          pageName: 'course-plan',
        }
      }
    },
    {
      path: RoutePath.SETTINGS,
      component: lazy(() => import(/* webpackChunkName: "Settings.page" */ '../../pages/settings/Settings.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'settings',
        }
      }
    },
    {
      path: RoutePath.EBOOK_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "EbookAssignmentEditor.page" */ '../../pages/ebook-assignment-editor/EbookAssignmentEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'assignment-editor:ebook',
        }
      }
    },
    {
      path: RoutePath.SKILL_ASSIGNMENT_PLAYER,
      component: lazy(() => import(/* webpackChunkName: "SkillAssignmentPlayer.page" */ '../../pages/skill-assignment-player/SkillAssignmentPlayer.page')),
    },
    {
      path: RoutePath.SKILL_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SkillAssignmentEditor.page" */ '../../pages/skill-assignment-editor/SkillAssignmentEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'assignment-editor:skills',
        }
      }
    },
    {
      path: RoutePath.SIMULATION_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SimulationEditor.page" */ '../../pages/simulation-editor/SimulationEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'assignment-editor:simulations',
        }
      }
    },
    {
      path: RoutePath.EVOLVE_RESOURCE_ASSIGNMENT_EDITOR,
      component: lazy(() => {
        return import(/* webpackChunkName: "EvolveResourceAssignmentEditor.page" */ '../../pages/evolve-resource-assignment-editor/EvolveResourceAssignmentEditor.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'assignment-editor:evolve-resource',
        }
      }
    },
    {
      path: RoutePath.EXTERNAL_LINK_ASSIGNMENT_EDITOR,
      component: lazy(() => {
        return import(/* webpackChunkName: "ExternalLinkAssignmentEditor.page" */ '../../pages/external-link-assignment-editor/ExternalLinkAssignmentEditor.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'assignment-editor:external-link',
        }
      }
    },
    {
      path: RoutePath.SHERPATH_LESSON_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SherpathLessonEditor.page" */ '../../pages/sherpath-lesson-editor/SherpathLessonEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'assignment-editor:sherpath-lessons',
        }
      }
    },
    {
      path: RoutePath.SHERPATH_GROUP_ACTIVITY_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SherpathGroupActivityEditor.page" */ '../../pages/sherpath-group-activity-editor/SherpathGroupActivityEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'assignment-editor:sherpath-group-activity',
        }
      }
    },
    {
      path: RoutePath.SHERPATH_POWERPOINT_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SherpathPowerpointEditor.page" */ '../../pages/sherpath-powerpoint-editor/SherpathPowerpointEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'assignment-editor:sherpath-powerpoint',
        }
      }
    },
    {
      path: RoutePath.SHERPATH_CASE_STUDY_ASSIGNMENT_EDITOR,
      component: lazy(() => import(/* webpackChunkName: "SherpathCaseStudyEditor.page" */ '../../pages/sherpath-case-study-editor/SherpathCaseStudyEditor.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'assignment-editor:sherpath-case-study',
        }
      }
    },
    {
      path: RoutePath.OSMOSIS_VIDEO_EDITOR,
      component: lazy(() => {
        return import(/* webpackChunkName: "OsmosisVideoEditor.page" */ '../../pages/osmosis-video-editor/OsmosisVideoEditor.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'osmosis-video-editor',
        }
      }
    },
    {
      path: RoutePath.COURSE_BUILDER,
      component: lazy(() => import(/* webpackChunkName: "CourseBuilder.page" */ '../../pages/course-builder/CourseBuilder.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'course-builder',
        }
      }
    },
    {
      path: RoutePath.APP_LINK_REDIRECT,
      component: lazy(() => import(/* webpackChunkName: "AppLinkRedirect.page" */ '../../pages/app-link-redirect/AppLinkRedirect.page')),
      isPrivate: false,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.genericNav,
          pageName: 'inter-app-redirect',
        }
      }
    }, {
      path: RoutePath.ADMIN,
      component: lazy(() => import(/* webpackChunkName: "Admin.page" */ '../../pages/admin/Admin.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'admin',
        }
      }
    }, {
      path: RoutePath.SIMULATION_ASSIGNMENT_PLAYER,
      component: lazy(() => import(/* webpackChunkName: "SimulationPlayer.page" */ '../../pages/simulation-player/SimulationPlayer.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'simulation-assignment-player',
        }
      }
    }, {
      path: RoutePath.OSMOSIS_ASSIGNMENT_PLAYER,
      component: lazy(() => import(/* webpackChunkName: "OsmosisAssignmentPlayer.page" */ '../../pages/osmosis-assignment-player/OsmosisAssignmentPlayer.page')),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'osmosis-assignment-player',
        }
      }
    }, {
      path: RoutePath.ASSIGNMENT_PERFORMANCE_REPORT,
      component: lazy(() => {
        return import(/* webpackChunkName: "AssignmentPerformance.page" */ '../../pages/assignment-performance/AssignmentPerformance.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'performance-report:assignment',
        }
      }
    }, {
      path: RoutePath.SKILL_ATTEMPT_RESULT,
      component: lazy(() => {
        return import(/* webpackChunkName: "SkillAttemptResult.page" */ '../../pages/assignment-performance/SkillAttemptResult.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'performance-report:skill',
        }
      }
    }, {
      path: RoutePath.COURSE_SWITCHER,
      component: lazy(() => {
        return import(/* webpackChunkName: "CourseSwitcher.page" */ '../../pages/course-switcher/CourseSwitcher.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'course-switcher',
        }
      }
    }, {
      path: RoutePath.SHADOW_HEALTH_ASSIGNMENT_EDITOR,
      component: lazy(() => {
        return import(/* webpackChunkName: "ShadowHealthAssignmentEditor.page" */ '../../pages/shadow-health-assignment-editor/ShadowHealthAssignmentEditor.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'shadow-health-assignment-editor',
        }
      }
    }, {
      path: RoutePath.BULK_EDIT_SETTINGS,
      component: lazy(() => {
        return import(/* webpackChunkName: "BulkEditSettings.page" */ '../../pages/bulk-edit-settings/BulkEditSettings.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'bulk-edit-settings',
        }
      }
    }, {
      path: RoutePath.ASSESSMENT_PERFORMANCE,
      component: lazy(() => {
        return import(/* webpackChunkName: "AssessmentResult.page" */ '../../pages/assessment-performance/AssessmentPerformance.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'assessment-performance',
        }
      }
    }, {
      path: RoutePath.SUPPORT,
      component: lazy(() => {
        return import(/* webpackChunkName: "Support.page" */ '../../pages/support/Support.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'support',
        }
      }
    }, {
      path: RoutePath.HELP,
      component: lazy(() => {
        return import(/* webpackChunkName: "Support.page" */ '../../pages/help/Help.page');
      }),
      isPrivate: true,
      exact: true,
      data: {
        analytics: {
          pageType: ELSCommonUIConstants.analytics.pageTypes.contentRendering,
          pageName: 'help',
        }
      }
    }
  ]
};
