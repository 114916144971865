import { ELSStepperItem } from '@els/els-component-shared-ts-react';

export const SIMULATION_EDITOR_PAGE_TITLE = 'Simulation Assignment';

export enum SimulationStep {
  STEP1 = 'STEP1',
}

export const SimulationDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Assignment',
    id: SimulationStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Assignment',
    title: 'Step 2',
  }
];

export const isUseAssignmentIdForPerformanceIndicator = 'PERFORMANCE_BY_ASSIGNMENT_ID';
