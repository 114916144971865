import React from 'react';
import memoizeOne from 'memoize-one';

const PlayVideoIcon = 'M35 89.538l47.292-26.539L35 36.461zm10-36L61.858 63 45 72.46zM92 18H18C8.611 18 1 25.611 1\n'
  + '35v56c0 9.389 7.611 17 17 17h74c9.389 0 17-7.611 17-17V35c0-9.389-7.611-17-17-17zm7 73a7 7 0 0 1-7 7H18a7 7 0 0\n'
  + '1-7-7V35a7 7 0 0 1 7-7h74a7 7 0 0 1 7 7z';

export const getPlayVideoIcon = memoizeOne((iconClass: string, iconWrapperClass: string) => {
  return (
    <div className={iconWrapperClass || ''}>
      <svg
        name="play-video-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 110 128"
        fill="currentColor"
        className={`o-els-icon-svg--1x ${!iconWrapperClass ? 'u-els-margin-top-1o4' : ''} ${iconClass || ''}`}
      >
        <path d={PlayVideoIcon} />
      </svg>
    </div>
  );
});
