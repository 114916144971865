/* eslint-disable sonarjs/no-duplicate-string */

import { ELSStepperItem } from '@els/els-component-shared-ts-react';

export const SHADOW_HEALTH_ASSIGNMENT_EDITOR_PAGE_TITLE = 'Shadow Health Assignment';

export enum ShadowHealthStep {
  STEP1 = 'STEP1',
}

export const ShadowHealthDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Assignment',
    id: ShadowHealthStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Assignment',
    title: 'Step 1',
  }
];
