import {
  ActiveSyllabusItemTypeDto,
  DeprecatedSyllabusItemTypeDto,
  SyllabusItemTypeDto
} from '../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { RecContentItemTypeDto } from '../apis/rec-gateway/rec-gateway.dtos';
import { AssignmentType } from '../apis/eols-assessment-service/eols-assessment-service.dtos';

export enum ResourceCategory {
  QUIZZES_ASSESSMENTS = 'QUIZZES_ASSESSMENTS',
  CONTENT_RESOURCES = 'CONTENT_RESOURCES',
  CLINICAL_RESOURCES = 'CLINICAL_RESOURCES',
}

export enum SyllabusItemAction {
  ADD_A_FOLDER = 'ADD_A_FOLDER',
  ADD_ADAPTIVE_LESSON = 'ADD_ADAPTIVE_LESSON',
  ADD_ADAPTIVE_QUIZ = 'ADD_ADAPTIVE_QUIZ',
  ADD_EBOOK_READING = 'ADD_EBOOK_READING',
  ADD_MORE_RESOURCES = 'ADD_MORE_RESOURCES',
  ADD_SHERPATH_LESSONS = 'ADD_SHERPATH_LESSONS',
  ADD_SHERPATH_SIMULATIONS = 'ADD_SHERPATH_SIMULATIONS',
  ADD_SHERPATH_SKILLS = 'ADD_SHERPATH_SKILLS',
  ADD_INTERACTIVE_REVIEW = 'ADD_INTERACTIVE_REVIEW',
  ADD_EVOLVE_INSTRUCTOR_RESOURCES = 'ADD_EVOLVE_INSTRUCTOR_RESOURCES',
  ADD_EVOLVE_STUDENT_RESOURCES = 'ADD_EVOLVE_STUDENT_RESOURCES',
  ADD_SHERPATH_GROUP_ACTIVITY = 'ADD_SHERPATH_GROUP_ACTIVITY',
  ADD_SIMCHART = 'ADD_SIMCHART',
  ADD_SHADOW_HEALTH = 'ADD_SHADOW_HEALTH',
  ADD_ASSESSMENT_BUILDER = 'ADD_ASSESSMENT_BUILDER',
  ADD_SHERPATH_POWERPOINT = 'ADD_SHERPATH_POWERPOINT',
  ADD_SHERPATH_CASE_STUDY = 'ADD_SHERPATH_CASE_STUDY',
  ADD_EXTERNAL_LINK = 'ADD_EXTERNAL_LINK',
  ADD_OSMOSIS_VIDEO = 'ADD_OSMOSIS_VIDEO',
  BULK_MOVE_REORDER = 'BULK_MOVE_REORDER',
  BULK_REMOVE = 'BULK_REMOVE',
  BULK_UNASSIGN = 'BULK_UNASSIGN',
  BULK_EDIT_SETTING = 'BULK_EDIT_SETTING',
  BULK_MAKE_VISIBLE_NOW = 'BULK_MAKE_VISIBLE_NOW',
  DEV_ADD_ASSIGNMENT = 'DEV_ADD_ASSIGNMENT',
  MOVE = 'MOVE',
  REMOVE = 'REMOVE',
  RENAME = 'RENAME',
  SETTINGS = 'SETTINGS',
  UNASSIGN = 'UNASSIGN',
  VIEW = 'VIEW',
  MAKE_VISIBLE_NOW = 'MAKE_VISIBLE_NOW',
  VIEW_PERFORMANCE = 'VIEW_PERFORMANCE',
  CREATE_DEEP_LINK = 'CREATE_DEEP_LINK',
  SELECT_ALL = 'SELECT_ALL',
  DESELECT_ALL = 'DESELECT_ALL',
}

export enum SyllabusItemConfigType {
  STATIC = 'STATIC', // Items that have a consistent contentId and can be found in the resource library
  CONFIGURABLE = 'CONFIGURABLE' // Items that are only addable and completely configurable by the user
}

export type SyllabusItemTypeConfig = {
  icon: string;
  displayName: string;
  displayNamePlural: string;
  iconPrefix: 'gizmo' | 'hmds';
  iconClass: string;
  title: string;
  sortOrder: number;
  recContentType: RecContentItemTypeDto;
  assignmentTypes: AssignmentType[];
  syllabusItemAction: SyllabusItemAction;
  configType: SyllabusItemConfigType;
  resourceCategory: ResourceCategory;
}

const caseStudy = 'case-study';

export const SyllabusItemTypeConfigMap: Record<SyllabusItemTypeDto, SyllabusItemTypeConfig> = {

  [ActiveSyllabusItemTypeDto.FOLDER]: {
    sortOrder: 1,
    recContentType: null,
    icon: 'open-folder',
    displayName: 'Folder',
    displayNamePlural: 'Folders',
    iconClass: null,
    iconPrefix: 'gizmo',
    title: 'Folder',
    assignmentTypes: null,
    syllabusItemAction: null,
    configType: null,
    resourceCategory: null
  },
  [ActiveSyllabusItemTypeDto.ADAPTIVE_QUIZ]: {
    sortOrder: 2,
    recContentType: null,
    icon: 'eugene',
    displayName: 'Adaptive Quiz (EAQ)',
    displayNamePlural: 'Adaptive Quizzes (EAQ)',
    iconClass: 'eaq',
    iconPrefix: 'hmds',
    title: 'Adaptive Quiz (EAQ)',
    assignmentTypes: [
      AssignmentType.MASTERY,
      AssignmentType.QUIZ_BY_QUESTION,
      AssignmentType.STANDARD,
      AssignmentType.AUTHESS,
    ],
    syllabusItemAction: SyllabusItemAction.ADD_ADAPTIVE_QUIZ,
    configType: SyllabusItemConfigType.CONFIGURABLE,
    resourceCategory: ResourceCategory.QUIZZES_ASSESSMENTS
  },
  [ActiveSyllabusItemTypeDto.ASSESSMENT_BUILDER_ASSIGNMENT]: {
    sortOrder: 3,
    recContentType: null,
    icon: 'empowering-knowledge',
    displayName: 'Assessment Builder Assignment',
    displayNamePlural: 'Assessment Builder Assignments',
    iconPrefix: 'gizmo',
    iconClass: 'eab',
    title: 'Assessment Builder Assignment',
    assignmentTypes: [AssignmentType.PERSONAL_ASSESSMENT_BUILDER],
    syllabusItemAction: SyllabusItemAction.ADD_ASSESSMENT_BUILDER,
    configType: SyllabusItemConfigType.CONFIGURABLE,
    resourceCategory: ResourceCategory.QUIZZES_ASSESSMENTS
  },
  [ActiveSyllabusItemTypeDto.SHERPATH_CASE_STUDY]: {
    sortOrder: 4,
    recContentType: RecContentItemTypeDto.SHERPATH_CASE_STUDY,
    icon: caseStudy,
    displayName: 'Case Study',
    displayNamePlural: 'Case Studies',
    iconPrefix: 'hmds',
    iconClass: caseStudy,
    title: 'Case Study',
    assignmentTypes: [AssignmentType.SHERPATH_CASE_STUDY],
    syllabusItemAction: SyllabusItemAction.ADD_SHERPATH_CASE_STUDY,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CONTENT_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.CUSTOM_LINK]: {
    sortOrder: 5,
    recContentType: null,
    icon: 'link',
    displayName: 'External Link',
    displayNamePlural: 'External Links',
    iconPrefix: 'gizmo',
    iconClass: 'custom-link',
    title: 'External Link',
    assignmentTypes: [AssignmentType.CUSTOM_LINK],
    syllabusItemAction: SyllabusItemAction.ADD_EXTERNAL_LINK,
    configType: SyllabusItemConfigType.CONFIGURABLE,
    resourceCategory: ResourceCategory.CONTENT_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.EBOOK_READING]: {
    sortOrder: 6,
    recContentType: RecContentItemTypeDto.EBOOK_READING,
    icon: 'ebook-reading',
    displayName: 'eBook Reading',
    displayNamePlural: 'eBook Readings',
    iconPrefix: 'hmds',
    iconClass: 'ebook-reading',
    title: 'eBook Reading',
    assignmentTypes: [AssignmentType.EBOOK_READING],
    syllabusItemAction: SyllabusItemAction.ADD_EBOOK_READING,
    configType: SyllabusItemConfigType.CONFIGURABLE,
    resourceCategory: ResourceCategory.CONTENT_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE]: {
    sortOrder: 7,
    recContentType: RecContentItemTypeDto.EVOLVE_RESOURCE,
    icon: 'books',
    displayName: 'Evolve Instructor Resource',
    displayNamePlural: 'Evolve Instructor Resources',
    iconPrefix: 'gizmo',
    iconClass: 'evolve',
    title: 'Evolve Instructor Resource',
    assignmentTypes: [AssignmentType.EVOLVE_RESOURCE],
    syllabusItemAction: SyllabusItemAction.ADD_EVOLVE_INSTRUCTOR_RESOURCES,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CONTENT_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE]: {
    sortOrder: 8,
    recContentType: RecContentItemTypeDto.EVOLVE_RESOURCE,
    icon: 'books',
    displayName: 'Evolve Student Resource',
    displayNamePlural: 'Evolve Student Resources',
    iconPrefix: 'gizmo',
    iconClass: 'evolve',
    title: 'Evolve Student Resource',
    assignmentTypes: [AssignmentType.EVOLVE_RESOURCE],
    syllabusItemAction: SyllabusItemAction.ADD_EVOLVE_STUDENT_RESOURCES,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CONTENT_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.SHERPATH_GROUP_ACTIVITY]: {
    sortOrder: 9,
    recContentType: RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY,
    icon: 'couple-of-people',
    displayName: 'Group Activity',
    displayNamePlural: 'Group Activities',
    iconPrefix: 'gizmo',
    iconClass: 'group-activity',
    title: 'Group Activity',
    assignmentTypes: [AssignmentType.SHERPATH_GROUP_ACTIVITY],
    syllabusItemAction: SyllabusItemAction.ADD_SHERPATH_GROUP_ACTIVITY,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CONTENT_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON]: {
    sortOrder: 10,
    recContentType: RecContentItemTypeDto.ADAPTIVE_LESSON,
    icon: 'lesson',
    displayName: 'Lesson',
    displayNamePlural: 'Lessons',
    iconPrefix: 'hmds',
    iconClass: 'lesson',
    title: 'Adaptive Lesson',
    assignmentTypes: [AssignmentType.ADAPTIVE_LESSON],
    syllabusItemAction: SyllabusItemAction.ADD_ADAPTIVE_LESSON,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CONTENT_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.SHERPATH_LESSON]: {
    sortOrder: 11,
    recContentType: RecContentItemTypeDto.SHERPATH_LESSON,
    icon: 'lesson',
    displayName: 'Lesson',
    displayNamePlural: 'Lessons',
    iconPrefix: 'hmds',
    iconClass: 'lesson',
    title: 'Sherpath Lesson',
    assignmentTypes: [AssignmentType.LESSONS],
    syllabusItemAction: SyllabusItemAction.ADD_SHERPATH_LESSONS,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CONTENT_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO]: {
    sortOrder: 12,
    recContentType: RecContentItemTypeDto.OSMOSIS_VIDEO,
    icon: 'play-video',
    displayName: 'Osmosis Video',
    displayNamePlural: 'Osmosis Videos',
    iconPrefix: 'gizmo',
    iconClass: 'osmosis-video',
    title: 'Osmosis Video',
    assignmentTypes: [AssignmentType.OSMOSIS_VIDEO],
    syllabusItemAction: SyllabusItemAction.ADD_OSMOSIS_VIDEO,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CONTENT_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.SHERPATH_POWERPOINT]: {
    sortOrder: 13,
    recContentType: RecContentItemTypeDto.SHERPATH_POWERPOINT,
    icon: 'ppt',
    displayName: 'PowerPoint',
    displayNamePlural: 'PowerPoints',
    iconPrefix: 'gizmo',
    iconClass: 'ppt',
    title: 'PowerPoint',
    assignmentTypes: [AssignmentType.SHERPATH_POWERPOINT],
    syllabusItemAction: SyllabusItemAction.ADD_SHERPATH_POWERPOINT,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CONTENT_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT]: {
    sortOrder: 14,
    recContentType: RecContentItemTypeDto.SHADOW_HEALTH,
    icon: 'lesson',
    displayName: 'Shadow Health Assignment',
    displayNamePlural: 'Shadow Health Assignments',
    iconPrefix: 'hmds',
    iconClass: 'shadow-health',
    title: 'Shadow Health Assignment',
    assignmentTypes: [AssignmentType.SHADOW_HEALTH],
    syllabusItemAction: SyllabusItemAction.ADD_SHADOW_HEALTH,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CLINICAL_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.SIMCHART_CASE_STUDY]: {
    sortOrder: 15,
    recContentType: RecContentItemTypeDto.SIM_CHART,
    icon: caseStudy,
    displayName: 'SimChart Case Study',
    displayNamePlural: 'SimChart Case Studies',
    iconPrefix: 'hmds',
    iconClass: caseStudy,
    title: 'SimChart Case Study',
    assignmentTypes: [AssignmentType.CASE_STUDY],
    syllabusItemAction: SyllabusItemAction.ADD_SIMCHART,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CLINICAL_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.SIMCHART_CHART]: {
    sortOrder: 16,
    recContentType: null,
    icon: 'simulation-nursing',
    displayName: 'SimChart Empty EHR',
    displayNamePlural: 'SimChart Empty EHRs',
    iconPrefix: 'hmds',
    iconClass: 'simchart',
    title: 'SimChart Empty EHR',
    assignmentTypes: [AssignmentType.CHART],
    syllabusItemAction: SyllabusItemAction.ADD_SIMCHART,
    configType: SyllabusItemConfigType.CONFIGURABLE,
    resourceCategory: ResourceCategory.CLINICAL_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.SHERPATH_SIMULATIONS]: {
    sortOrder: 17,
    recContentType: RecContentItemTypeDto.SHERPATH_SIMULATION,
    icon: 'stethoscope',
    displayName: 'Simulation',
    displayNamePlural: 'Simulations',
    iconPrefix: 'gizmo',
    iconClass: 'simulation',
    title: 'Simulation',
    assignmentTypes: [AssignmentType.SIMULATIONS],
    syllabusItemAction: SyllabusItemAction.ADD_SHERPATH_SIMULATIONS,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CLINICAL_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.SHERPATH_SKILL]: {
    sortOrder: 18,
    recContentType: RecContentItemTypeDto.SHERPATH_SKILL,
    icon: 'hand',
    displayName: 'Skill',
    displayNamePlural: 'Skills',
    iconPrefix: 'hmds',
    iconClass: 'skill',
    title: 'Skill',
    assignmentTypes: [AssignmentType.SKILL],
    syllabusItemAction: SyllabusItemAction.ADD_SHERPATH_SKILLS,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.CLINICAL_RESOURCES
  },
  [ActiveSyllabusItemTypeDto.INTERACTIVE_REVIEW]: {
    sortOrder: 19,
    recContentType: RecContentItemTypeDto.REVIEW_QUIZ,
    icon: 'insert-question',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    displayName: 'Interactive Review Questions',
    displayNamePlural: 'Interactive Review Questions',
    iconPrefix: 'hmds',
    iconClass: 'interactive-review',
    title: 'Interactive Review Questions',
    assignmentTypes: [AssignmentType.INTERACTIVE_REVIEW],
    syllabusItemAction: SyllabusItemAction.ADD_INTERACTIVE_REVIEW,
    configType: SyllabusItemConfigType.STATIC,
    resourceCategory: ResourceCategory.QUIZZES_ASSESSMENTS
  },
  [DeprecatedSyllabusItemTypeDto.EVOLVE_RESOURCE]: {
    sortOrder: 100,
    recContentType: null,
    icon: 'books',
    displayName: 'Evolve Resource',
    displayNamePlural: 'Evolve Resources',
    iconPrefix: 'gizmo',
    iconClass: 'evolve',
    title: 'Evolve Resource',
    assignmentTypes: null,
    syllabusItemAction: null,
    configType: null,
    resourceCategory: null
  },
  [DeprecatedSyllabusItemTypeDto.SIMCHART]: {
    sortOrder: 100,
    recContentType: null,
    icon: 'simulation-nursing',
    displayName: 'SimChart',
    displayNamePlural: 'SimCharts',
    iconPrefix: 'hmds',
    iconClass: 'simchart',
    title: 'SimChart',
    assignmentTypes: null,
    syllabusItemAction: null,
    configType: null,
    resourceCategory: null
  },
};

export enum UnSupportedEvolveResourceTypeDEPRECATED {
  LMS = 'LMS',
  DOWNLOAD_BY_RESOURCE_TYPE = 'Download by Resource Type',
  DOWNLOADS = 'downloads'
}

export enum DefaultCoursePlanEvolveResourceTypeDEPRECATED {
  CASE_STUDIES = 'Case studies',
  CASE_STUDY = 'Case study',
  TEACH = 'TEACH',
  PPT = 'PPT',
}
