import {
  get,
  isNil,
  orderBy,
  partition,
  uniqBy,
} from 'lodash';
import moment from 'moment';
import { ELSLoggingService } from '@els/els-ui-common-react';
import { SyllabusAssignmentDto, } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  EbookDefaultSteps,
  EbookStep
} from './ebook-assignment.constants';
import { ELSFormFieldValidationItem } from '../../models/els.models';
import {
  EvolveProductDto,
  EvolveProductType
} from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import {
  flattenTree,
  isOnlyNumber,
  truncateTitle
} from '../../utilities/common.utilities';
import { isValidDate } from '../../utilities/app.utilities';
import {
  getDefaultAssignmentEditorState,
  getGoalIdFromVtwId,
  updateStepsOnNav
} from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.utilities';
import {
  BaseAssignmentEditorProps,
  BaseAssignmentEditorState
} from '../../hocs/with-base-assignment-editor/withBaseAssignmentEditor.hoc';
import { AssignmentFormError } from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.models';
import {
  AssignmentDto,
  AssignmentGoalDto,
  AssignmentType,
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import {
  PrimaryTaxonomy,
  PrimaryTaxonomyDto,
  PrimaryTaxonomyNodeType,
  PrimaryTaxonomyTaxonDto
} from '../../apis/rec-gateway/rec-gateway.models';
import {
  getEbookNodeTitle,
  getEbookTitle,
  getTimeEstimateDisplayFromPageRanges,
  getVbId
} from '../catalog/catalog.utilities';
import { EbookPageRangeSeparators } from '../catalog/catalog.constants';
import { ActiveSyllabusItemTypeDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { MAX_ASSIGNMENT_TITLE_CHAR_LENGTH } from '../../components/assignment-editor/assignment-editor.constant';

const fileName = 'ebook-assignment.utilities';

const byInvalidRangeElement = (rangeElement) => {
  if (isNil(rangeElement)) {
    return true;
  }

  if (!rangeElement.trim().length) {
    return true;
  }
  if (!isOnlyNumber(rangeElement.trim())) {
    return true;
  }

  const number = parseInt(rangeElement.trim(), 10);

  if (number < 1) {
    return true;
  }

  return isNaN(number);
};

const isPageRangeChunkValid = (pageRangeChunk: string) => {

  const range = pageRangeChunk.split(EbookPageRangeSeparators.RANGE_SEPARATOR);

  if (![1, 2].includes(range.length)) {
    return false;
  }

  const invalidRangeElement = range.find(byInvalidRangeElement);

  if (!isNil(invalidRangeElement)) {
    return false;
  }

  return !(range.length === 2 && (parseInt(range[1].trim(), 10) <= parseInt(range[0].trim(), 10)));
};

export const isPageRangeValid = (pageRange: string) => {

  if (!pageRange) {
    return false;
  }

  const pageRangeChunks = pageRange.split(EbookPageRangeSeparators.CHUNK_SEPARATOR);

  const invalidPageRangeChunk = pageRangeChunks.find((pageRangeChunk) => {
    return !isPageRangeChunkValid(pageRangeChunk);
  });

  return isNil(invalidPageRangeChunk);
};

export const getPageCountFromPageRanges = (pageRange: string): number => {
  if (!isPageRangeValid(pageRange)) {
    return 0;
  }
  return pageRange.split(EbookPageRangeSeparators.CHUNK_SEPARATOR).reduce((acc, cur) => {
    const range = cur.split(EbookPageRangeSeparators.RANGE_SEPARATOR);
    if (range.length === 1) {
      return acc + 1;
    }
    return acc + Math.abs(parseInt(range[0], 10) - parseInt(range[1], 10)) + 1;
  }, 0);
};

export const isPageRangeInRange = (pageRange: string, chapter: PrimaryTaxonomyTaxonDto) => {

  if (!isPageRangeValid(pageRange)) {
    return true;
  }

  if (!chapter.attributes.pageRange) {
    ELSLoggingService.info(fileName, 'Chapter is missing pageRange.  Allowing invalid page ranges');
    return true;
  }

  const numbers = pageRange.split(EbookPageRangeSeparators.CHUNK_SEPARATOR).reduce((acc, cur) => {
    return [...acc, ...cur.split(EbookPageRangeSeparators.RANGE_SEPARATOR)];
  }, []).map((number) => {
    return parseInt(number, 10);
  });

  const invalidNumber = numbers.find((number) => {
    return number > chapter.attributes.pageRange.endRange || number < chapter.attributes.pageRange.startRange;
  });

  return isNil(invalidNumber);

};

export const validatePageRange = (value: string, message: string) => (): ELSFormFieldValidationItem => {
  return {
    id: 'validPageRange',
    value,
    content: message,
    isValid: isPageRangeValid(value),
  };
};

export const validatePageRangeInRange = (value: string, chapter: PrimaryTaxonomyTaxonDto, message: string) => (): ELSFormFieldValidationItem => {
  return {
    id: 'validPageRangeInRange',
    value,
    content: message,
    isValid: isPageRangeInRange(value, chapter),
  };
};

export const getChapterPageRange = (chapter: PrimaryTaxonomyTaxonDto) => {

  if (!chapter.attributes.pageRange) {
    return '';
  }

  return `${chapter.attributes.pageRange.startRange}-${chapter.attributes.pageRange.endRange}`;
};

export const getChapterRangeDisplay = (chapter: PrimaryTaxonomyTaxonDto) => {
  const range = getChapterPageRange(chapter);
  if (!range) {
    return '';
  }
  return `(pp. ${range})`;
};

export const getOrderedChapters = (primaryTaxonomy: PrimaryTaxonomyDto): PrimaryTaxonomyTaxonDto[] => {
  const unsorted = primaryTaxonomy.included.filter((taxon) => {
    return taxon.attributes.nodeType === PrimaryTaxonomyNodeType.CHAPTER;
  });

  return orderBy(unsorted, (taxon: PrimaryTaxonomyTaxonDto) => {
    return taxon.attributes.displayOrder;
  });
};

export const getDefaultChapterPageRanges = (primaryTaxonomy: PrimaryTaxonomyDto): {} => {
  return getOrderedChapters(primaryTaxonomy).reduce((acc, cur) => {
    return {
      ...acc,
      [cur.id]: getChapterPageRange(cur)
    };
  }, {});
};

export const getChapterPageRangeMatrix = (pageRanges: string): number[][] => {
  return pageRanges
    .split(EbookPageRangeSeparators.CHUNK_SEPARATOR)
    .map((pageRangeChunk) => {
      return pageRangeChunk.split(EbookPageRangeSeparators.RANGE_SEPARATOR)
        .map(page => parseInt(page, 10));
    });
};

export const getChapterPageRangesFromPageRanges = (pageRanges: string, primaryTaxonomy: PrimaryTaxonomyDto): {} => {

  if (!pageRanges || !primaryTaxonomy) {
    return [];
  }

  const pageRangeMatrix = getChapterPageRangeMatrix(pageRanges);

  return getOrderedChapters(primaryTaxonomy).reduce((acc, cur) => {
    if (!cur.attributes.pageRange) {
      ELSLoggingService.info(fileName, 'Chapter taxonomy is missing pageRange');
      return acc;
    }
    return pageRangeMatrix.reduce((_acc, matrix) => {
      const isInPageRange = matrix.every((page) => {
        return cur.attributes.pageRange.startRange <= page && cur.attributes.pageRange.endRange >= page;
      });
      if (!isInPageRange) {
        return _acc;
      }
      const pageRange = matrix.length === 1 ? matrix[0] : matrix.join(EbookPageRangeSeparators.RANGE_SEPARATOR);
      return {
        ..._acc,
        [cur.id]: _acc[cur.id] ? `${_acc[cur.id]}, ${pageRange}` : pageRange.toString()
      };
    }, acc);
  }, {});
};

export const getChapterIdsFromPageRanges = (pageRanges: string, primaryTaxonomy: PrimaryTaxonomyDto): string[] => {

  if (!pageRanges) {
    return [];
  }

  const pageRangeMap = getChapterPageRangesFromPageRanges(pageRanges, primaryTaxonomy);

  return Object.keys(pageRangeMap);
};

export const formatPageRange = (pageRange: string): string => {
  if (!isPageRangeValid(pageRange)) {
    return pageRange;
  }
  return pageRange.split(EbookPageRangeSeparators.CHUNK_SEPARATOR).map((pageRangeChunk) => {
    return pageRangeChunk.replace(/\s/g, '');
  }).join(', ');
};

export type EbookAssignmentTitlePart = {
  display: string;
  chapter: PrimaryTaxonomyTaxonDto;
  timeEstimate: string;
}

export const getEbookAssignmentTitleParts = (chapterPageRangeMap: {}, bookTaxonomy: PrimaryTaxonomyDto): EbookAssignmentTitlePart[] => {

  if (!bookTaxonomy) {
    return null;
  }

  return Object.keys(chapterPageRangeMap).map((chapterId) => {
    const chapter = bookTaxonomy.included.find(x => x.id === chapterId);

    let chapterDisplay;
    if (chapter) {
      chapterDisplay = getEbookNodeTitle(chapter);
    } else {
      chapterDisplay = 'Unknown Chapter Title';
    }

    const pagePrefix = (chapterPageRangeMap[chapterId].includes(EbookPageRangeSeparators.RANGE_SEPARATOR)
      || chapterPageRangeMap[chapterId].includes(EbookPageRangeSeparators.CHUNK_SEPARATOR))
      ? 'pp.' : 'p.';

    return {
      display: `${chapterDisplay}: ${pagePrefix} ${chapterPageRangeMap[chapterId]}`,
      chapter,
      timeEstimate: getTimeEstimateDisplayFromPageRanges(chapterPageRangeMap[chapterId])
    };
  });
};

export const getEbookAssignmentTitle = (chapterPageRangeMap: {}, bookTaxonomy: PrimaryTaxonomyDto, isIncludeDuration = true) => {
  if (!bookTaxonomy) {
    return null;
  }

  const pageRanges = Object.values(chapterPageRangeMap).join(EbookPageRangeSeparators.CHUNK_SEPARATOR);

  const parts = getEbookAssignmentTitleParts(chapterPageRangeMap, bookTaxonomy);

  if (!parts || !parts.length) {
    return null;
  }
  const chapterReadings = orderBy(parts, (part: EbookAssignmentTitlePart) => {
    if (!part.chapter) {
      return 0;
    }
    return part.chapter.attributes.displayOrder;
  }).map((part) => {
    return part.display;
  });
  return `${chapterReadings.join('; ')}${isIncludeDuration ? ` ${getTimeEstimateDisplayFromPageRanges(pageRanges)}` : ''}`;
};

export const getEbookComponents = (evolveProducts: EvolveProductDto[]): EvolveProductDto[] => {
  if (!evolveProducts) {
    return [];
  }
  const flatArray: EvolveProductDto[] = flattenTree(evolveProducts, 'components');
  const validEbooks = flatArray.filter((product) => {
    return product.type === EvolveProductType.EBOOK && (product.vbId || product.vbID);
  });
  if (!validEbooks || !validEbooks.length) {
    return [];
  }
  return uniqBy(validEbooks, (item: EvolveProductDto) => {
    return item.isbn;
  });
};

export const getDefaultEbookIsbn = (evolveProducts: EvolveProductDto[]): string => {
  const ebookComponents = getEbookComponents(evolveProducts);
  if (ebookComponents.length === 1) {
    return getVbId(ebookComponents[0]);
  }
  return null;
};

export const getBookTaxonomy = (isbn: string, primaryTaxonomies: PrimaryTaxonomy[]): PrimaryTaxonomyDto => {
  const _primaryTaxonomy = primaryTaxonomies.find((primaryTaxonomy) => {
    return primaryTaxonomy.isbn === isbn;
  });

  if (!_primaryTaxonomy) {
    return null;
  }

  return _primaryTaxonomy.taxonomy;
};

export const validateEbookReading = (
  syllabusAssignmentDto: SyllabusAssignmentDto,
  baseState: BaseAssignmentEditorState,
  baseProps: BaseAssignmentEditorProps
): AssignmentFormError[] => {
  const errors = [];
  const assignmentGoals = get(syllabusAssignmentDto, 'assignment.assignmentGoals', []);
  const { messages } = baseProps;

  if (!assignmentGoals.length) {
    errors.push({
      message: 'Page ranges is empty',
      stepId: EbookStep.STEP1
    });
  }

  assignmentGoals.forEach((assignmentGoal) => {
    if (!isPageRangeValid(assignmentGoal.text)) {
      errors.push({
        message: `Invalid page rage: ${assignmentGoal.text}`,
        stepId: EbookStep.STEP1
      });
    }
  });

  if (!syllabusAssignmentDto.assignment.title || !syllabusAssignmentDto.assignment.title.length) {
    errors.push({
      message: messages.ASSIGNMENT_TITLE_IS_EMPTY,
      stepId: EbookStep.STEP2
    });
  }

  if (!syllabusAssignmentDto.assignment.isbn || !syllabusAssignmentDto.assignment.isbn.length) {
    errors.push({
      message: 'Assignment isbn is empty',
      stepId: EbookStep.STEP1
    });
  }

  const availableDate = moment(syllabusAssignmentDto.assignment.availableDate).toDate();
  if (baseState.hasAvailableDate && !isValidDate(availableDate)) {
    errors.push({
      message: messages.INVALID_AVAILABILITY_DATE,
      stepId: EbookStep.STEP2
    });
  }

  return errors;
};

export const getEbookReadingDefaultState = (): BaseAssignmentEditorState => {
  return {
    ...getDefaultAssignmentEditorState(),
    assignmentType: AssignmentType.EBOOK_READING,
    pageTitle: 'Ebook Assignment',
    steps: updateStepsOnNav(EbookDefaultSteps, 0),
    newSyllabusItemType: ActiveSyllabusItemTypeDto.EBOOK_READING
  };
};

export const getAllChapterPageRangesFromAssignmentGoals = (
  assignmentGoals: AssignmentGoalDto[],
): string => {
  if (!assignmentGoals) {
    return null;
  }
  return assignmentGoals.map((goal) => {
    return goal.text;
  }, {}).join(EbookPageRangeSeparators.CHUNK_SEPARATOR);
};

export const getChapterPageRangeMapFromAssignmentGoals = (
  assignmentGoals: AssignmentGoalDto[],
  bookTaxonomy: PrimaryTaxonomyDto
): {} => {
  const pageRanges = getAllChapterPageRangesFromAssignmentGoals(assignmentGoals);
  return getChapterPageRangesFromPageRanges(pageRanges, bookTaxonomy);
};

export const getFirstPage = (readings: string[]): number => {
  const pages = readings.reduce((acc, cur) => {

    if (!isPageRangeValid(cur)) {
      return acc;
    }

    const _pages = cur.split(EbookPageRangeSeparators.CHUNK_SEPARATOR).reduce((_acc, _cur) => {
      return [..._acc, ..._cur.split(EbookPageRangeSeparators.RANGE_SEPARATOR).map(page => parseInt(page, 10))];
    }, []);
    return [...acc, ..._pages];
  }, []);

  if (pages.length) {
    return pages.sort((a, b) => a - b)[0];
  }
  return 1;
};

export const asyncGetElement = (id: string, attempt = 10): Promise<HTMLElement> => {
  return new Promise((resolve) => {
    if (attempt <= 0) {
      resolve(null);
      return;
    }
    const element: HTMLElement = document.querySelector(id);
    if (element) {
      resolve(element);
      return;
    }
    setTimeout(() => {
      return asyncGetElement(id, attempt - 1).then(resolve);
    }, 100);
  });
};

export const scrollToElementInScrollBox = (elementId: string, contentElementId: string): Promise<void> => {
  if (!elementId || !contentElementId) {
    return Promise.resolve();
  }
  return Promise.all([
    asyncGetElement(elementId),
    asyncGetElement(contentElementId),
  ]).then((response) => {
    const element: HTMLElement = response[0];
    const contentElement: HTMLElement = response[1];
    if (element && contentElement) {
      contentElement.scrollTo(0, element.offsetTop - contentElement.offsetTop);
    }
  });

};

export const getPageDefault = (checkedChapterIds: string[], chapterPageRanges: {}): number => {
  if (checkedChapterIds.length === 0) {
    return 1;
  }
  const lowestDefaultChapter = checkedChapterIds.sort()[0];
  const pageRange = get(chapterPageRanges, lowestDefaultChapter, '');
  return pageRange ? parseInt(pageRange.split('-')[0], 10) : 1;
};

export const getAssignmentGoalsFromPageRanges = (
  orderedCheckedChapters: PrimaryTaxonomyTaxonDto[],
  assignment: Partial<AssignmentDto>,
  chapterPageRanges: {},
): AssignmentGoalDto[] => {
  if (orderedCheckedChapters.length === 0) {
    return [];
  }

  const [validChapters, invalidChapters] = partition(orderedCheckedChapters, (chapter) => {
    return isPageRangeValid(chapterPageRanges[chapter.id])
      && isPageRangeInRange(chapterPageRanges[chapter.id], chapter);
  });

  return validChapters
    .map((chapter, idx) => {
      return {
        id: getGoalIdFromVtwId(assignment, chapter.id),
        goal: idx,
        text: formatPageRange(chapterPageRanges[chapter.id]),
        vtwId: chapter.id
      };
    })
    .concat(
      invalidChapters.map((chapter, idx) => ({
        id: getGoalIdFromVtwId(assignment, chapter.id),
        goal: idx,
        text: 'false',
        vtwId: chapter.id
      }))
    );
};

export const getOrderedCheckedChapters = (taxonomy: PrimaryTaxonomyDto, checkedChapterIds: string[]): PrimaryTaxonomyTaxonDto[] => {
  if (!checkedChapterIds || !checkedChapterIds.length) {
    return [];
  }

  return getOrderedChapters(taxonomy).filter((chapter) => {
    return checkedChapterIds.includes(chapter.id);
  });
};

export const getEbookAssignmentInfo = (
  taxonomy: PrimaryTaxonomyDto,
  checkedChapterIds: string[],
  assignment: Partial<AssignmentDto>,
  chapterPageRanges: {},
): {
  assignmentGoals: AssignmentDto['assignmentGoals'];
  title: AssignmentDto['title'];
  subtitle: AssignmentDto['subtitle'];
} => {
  const orderedCheckedChapters = getOrderedCheckedChapters(taxonomy, checkedChapterIds);
  const assignmentGoals = getAssignmentGoalsFromPageRanges(orderedCheckedChapters, assignment, chapterPageRanges);
  const chapterPageRangeMap = getChapterPageRangeMapFromAssignmentGoals(assignmentGoals, taxonomy);
  const title = getEbookTitle(taxonomy);
  return {
    assignmentGoals,
    title: truncateTitle(getEbookAssignmentTitle(chapterPageRangeMap, taxonomy), MAX_ASSIGNMENT_TITLE_CHAR_LENGTH),
    subtitle: truncateTitle(title, MAX_ASSIGNMENT_TITLE_CHAR_LENGTH),
  };
};
