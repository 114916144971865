import React, { ReactNode } from 'react';

export interface AssignmentNoticeProps {
  children: ReactNode;
}

export const AssignmentNotice = (props: AssignmentNoticeProps) => {

  return (
    <div className="c-els-card c-els-card--border-primary">
      <div className="c-els-card__content">
        {props.children}
      </div>
    </div>
  );
};
