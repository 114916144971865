import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { LearningContextDto } from './eols-learning-content-service.dtos';

export const postLearningContext = (data: LearningContextDto): Promise<LearningContextDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.post<LearningContextDto>('/learningContext', {
      data,
    });
  });
};
