import { ELSStepperItem } from '@els/els-component-shared-ts-react';
import {
  CatalogOsmosisVideoExternalEntityDtoParsed,
  CatalogSherpathLessonExternalEntityDtoParsed
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';

export const OSMOSIS_VIDEO_EDITOR_PAGE_TITLE = 'Osmosis Video Assignment';

export enum OsmosisVideoStep {
  STEP1 = 'STEP1',
}

export type SherpathLessonOsmosisIncludesConfigs =
  (CatalogSherpathLessonExternalEntityDtoParsed['_parsedData']['osmosisVideos'][0] & { _entity: CatalogOsmosisVideoExternalEntityDtoParsed })[];

export const OsmosisVieoDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Assignment',
    id: OsmosisVideoStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Assignment',
    title: 'Step 1',
  }
];
