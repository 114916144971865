import {
  AB_TEST,
  AB_TEST_CONFIG_MAP,
  FEATURE_FLAG,
  TRUE_VALUE
} from '../apis/eols-features-api/eols-features-api.constants';
import {
  FeatureFlagDto,
  FeatureFlagsGroupedDto
} from '../apis/eols-features-api/eols-features-api.dtos';

export const getGroupFeatureFlagWithFallbackToGlobal = (
  flags: FeatureFlagsGroupedDto[],
  featureFlag: FEATURE_FLAG,
  group: string,
  doPartialFlagMatch = false
): FeatureFlagsGroupedDto => {

  if (!flags) {
    return null;
  }

  const groupMatch = flags.find((flag) => {
    if (doPartialFlagMatch) {
      return flag.featureName.includes(featureFlag) && group && flag.groups.includes(group);
    }
    return flag.featureName === featureFlag && group && flag.groups.includes(group);
  });
  if (groupMatch) {
    return groupMatch;
  }
  return flags.find((flag) => {
    if (doPartialFlagMatch) {
      return flag.featureName.includes(featureFlag) && flag.groups.length === 0;
    }
    return flag.featureName === featureFlag && flag.groups.length === 0;
  });
};

export const getBooleanFromGroupFeatureFlagWithFallbackToGlobal = (
  flags: FeatureFlagsGroupedDto[],
  featureFlag: FEATURE_FLAG,
  group: string,
  doPartialFlagMatch = false
): boolean => {
  const flag = getGroupFeatureFlagWithFallbackToGlobal(flags, featureFlag, group, doPartialFlagMatch);
  if (!flag) {
    // Return null here to force explicit setting of feature flags
    return null;
  }
  return flag.featureValue === TRUE_VALUE;
};

export const getFeatureFlagGroups = (flags: FeatureFlagsGroupedDto[], featureName: FEATURE_FLAG, featureValue: string = TRUE_VALUE): string[] => {

  if (!flags || !flags.length) {
    return [];
  }

  const flag = flags.find((featureFlag) => {
    return featureFlag.featureName === featureName
      && featureFlag.featureValue === featureValue
      && featureFlag.groups
      && featureFlag.groups.length;
  });

  if (!flag || !flag.groups) {
    return [];
  }

  return flag.groups;
};

export const getRandomABTestFlavor = (flag: FeatureFlagsGroupedDto, rand = Math.random()) => {
  const possibleFlavors = AB_TEST_CONFIG_MAP[flag.featureName];
  if (!possibleFlavors || !possibleFlavors.length) {
    return null;
  }
  return possibleFlavors[Math.floor(rand * possibleFlavors.length)];
};

export const getABTestFlavor = (props: {
  flag: FEATURE_FLAG;
  appFlags: FeatureFlagsGroupedDto[];
  abFlags: FeatureFlagDto[];
  group: string;
  defaultFlavor: string;
}): string => {

  const {
    flag,
    appFlags,
    abFlags,
    group,
    defaultFlavor
  } = props;

  const appFlag = getGroupFeatureFlagWithFallbackToGlobal(appFlags, flag, group);
  if (appFlag && appFlag.featureValue !== AB_TEST) {
    return appFlag.featureValue;
  }
  const abFlag = abFlags.find((_abFlag) => {
    return _abFlag.featureName === flag;
  });

  if (abFlag) {
    return abFlag.featureValue;
  }
  return defaultFlavor;
};

export const getABPropsForAnalytics = (abTestFlavors: FeatureFlagDto[]): Record<string, string> => {
  if (!abTestFlavors || !abTestFlavors.length) {
    return {};
  }
  return abTestFlavors.reduce((acc, cur) => {
    return {
      ...acc,
      [cur.featureName]: cur.featureValue
    };
  }, {});
};

export const getActiveABTestFlags = (flags: FeatureFlagsGroupedDto[], group: string): FeatureFlagsGroupedDto[] => {

  if (!flags) {
    return null;
  }

  const groupMatches = flags.filter((flag) => {
    return flag.featureValue === AB_TEST && group && flag.groups.includes(group);
  });

  const globalMatches = flags.filter((flag) => {
    if (
      groupMatches.some((_flag) => {
        return _flag.featureName === flag.featureName;
      })
    ) {
      return false;
    }
    return flag.featureValue === AB_TEST && flag.groups.length === 0;
  });

  return [...groupMatches, ...globalMatches];
};
