import { ELSStepperItem } from '@els/els-component-shared-ts-react';

export enum EbookStep {
  STEP1 = 'STEP1',
  STEP2 = 'STEP2',
}

export const EbookDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Step 1 heading',
    id: EbookStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Choose Reading',
    title: 'Step 1',
  },
  {
    heading: 'Assignment',
    id: EbookStep.STEP2,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Assignment',
    title: 'Step 2',
  }
];
