import moment from 'moment';
import {
  get,
} from 'lodash';
import {
  CatalogWithExternalEntitiesDto,
  SyllabusAssignmentDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  getLocalDateFromServicesUTC,
  isValidDate,
} from '../../utilities/app.utilities';
import {
  SHERPATH_POWERPOINT_EDITOR_PAGE_TITLE,
  SherpathPowerpointDefaultSteps,
  SherpathPowerpointStep,
} from './sherpath-powerpoint.constants';
import { BaseAssignmentEditorProps, BaseAssignmentEditorState } from '../../hocs/with-base-assignment-editor/withBaseAssignmentEditor.hoc';
import {
  getDefaultAssignmentEditorState,
  updateStepsOnNav
} from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.utilities';
import { AssignmentFormError } from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.models';
import {
  AssignmentDto,
  AssignmentType
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { RecContentItemDto } from '../../apis/rec-gateway/rec-gateway.dtos';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import {
  getSubtitle,
  getTitle
} from '../catalog/catalog.utilities';
import {
  MAX_ASSIGNMENT_TITLE_CHAR_LENGTH
} from '../../components/assignment-editor/assignment-editor.constant';
import { MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH } from '../course-plan/syllabus.constants';
import { EvolveProductDto } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';

export const validateSherpathPowerpoint = (
  syllabusAssignmentDto: SyllabusAssignmentDto,
  baseState: BaseAssignmentEditorState,
  baseProps: BaseAssignmentEditorProps,
): AssignmentFormError[] => {
  const { messages } = baseProps;

  const errors = [];

  const assignmentGoals = get(syllabusAssignmentDto, 'assignment.assignmentGoals', []);

  if (!assignmentGoals.length) {
    errors.push({
      message: messages.ASSIGNMENT_CONTENT_ID_IS_EMPTY,
      stepId: SherpathPowerpointStep.STEP1
    });
  }

  if (!syllabusAssignmentDto.assignment.title || !syllabusAssignmentDto.assignment.title.length) {
    errors.push({
      message: messages.ASSIGNMENT_TITLE_IS_EMPTY,
      stepId: SherpathPowerpointStep.STEP1
    });
  }

  const availableDate = moment(syllabusAssignmentDto.assignment.availableDate).toDate();
  if (baseState.hasAvailableDate && !isValidDate(availableDate)) {
    errors.push({
      message: messages.INVALID_AVAILABILITY_DATE,
      stepId: SherpathPowerpointStep.STEP1
    });
  }

  const dueDate = moment(syllabusAssignmentDto.assignment.dueDate).toDate();
  if (baseState.hasDueDate && !isValidDate(dueDate)) {
    errors.push({
      message: messages.INVALID_DUE_DATE,
      stepId: SherpathPowerpointStep.STEP1
    });
  }

  if (baseState.hasDueDate
    && moment(getLocalDateFromServicesUTC(syllabusAssignmentDto.assignment.dueDate))
      .isSameOrBefore(moment(getLocalDateFromServicesUTC(syllabusAssignmentDto.assignment.availableDate)))) {
    errors.push({
      message: messages.DUE_DATE_BEFORE_AVAILABLE_DATE_ERROR_MESSAGE,
      stepId: SherpathPowerpointStep.STEP1
    });
  }

  if (
    baseState.assignment
    && baseState.assignment.title
    && baseState.assignment.title.length > MAX_ASSIGNMENT_TITLE_CHAR_LENGTH
  ) {
    errors.push({
      message: messages.ENTRY_EXCEEDS_LENGTH_LIMIT,
      stepId: SherpathPowerpointStep.STEP1,
    });
  }

  return errors;
};

export const getSherpathPowerpointDefaultState = (): BaseAssignmentEditorState => {
  return {
    ...getDefaultAssignmentEditorState(),
    assignmentType: AssignmentType.SHERPATH_POWERPOINT,
    hasDueDate: false,
    showDueDate: false,
    showGrading: false,
    showDisplayName: true,
    pageTitle: SHERPATH_POWERPOINT_EDITOR_PAGE_TITLE,
    steps: updateStepsOnNav(SherpathPowerpointDefaultSteps, 0),
    isReadOnlyDisplayName: false,
  };
};

export const getSherpathPowerpointAssignmentInfo = (
  catalog: CatalogWithExternalEntitiesDto,
  contentItem: RecContentItemDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  evolveProducts: EvolveProductDto[]
): {
  assignmentGoals: AssignmentDto['assignmentGoals'];
  title: AssignmentDto['title'];
  subtitle: AssignmentDto['subtitle'];
  contentId: AssignmentDto['contentId'];
} => {
  const contentId = get(contentItem, 'attributes.contentId', null);
  return {
    assignmentGoals: [{
      id: null,
      goal: 100,
      vtwId: contentId,
      text: null,
    }],
    title: contentItem ? getTitle(contentItem, catalog, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : '',
    subtitle: contentItem ? getSubtitle(contentItem, catalog, primaryTaxonomies, evolveProducts, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : '',
    contentId
  };
};
