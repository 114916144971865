import { ELSDropDownOption } from '../els.components';
import { LANGUAGE_KEY } from '../../translations/message.constants';
import {
  NavItemTextMap,
  NavItem
} from '../header-nav/navigation.constants';

export const MoveToRootFolderOption: ELSDropDownOption = {
  name: NavItemTextMap[NavItem.MY_COURSE_PLAN],
  value: 'ROOT',
};

export const SelectFolderOption: ELSDropDownOption = {
  name: '--Select Folder--',
  value: 'SELECT'
};

export enum TARGET_DROP_STATUS {
  EXCEED_NEST_LIMIT,
  ITS_OWN_CHILDREN,
  ITSELF,
  VALID,
  OVER_ROOT
}

export const targetDropStatusMessageKeyMap = {
  [TARGET_DROP_STATUS.ITS_OWN_CHILDREN]: LANGUAGE_KEY.UNABLE_TO_DROP_INTO_ITS_CHILDREN,
  [TARGET_DROP_STATUS.ITSELF]: LANGUAGE_KEY.UNABLE_TO_DROP_ITSELF,
  [TARGET_DROP_STATUS.EXCEED_NEST_LIMIT]: LANGUAGE_KEY.UNABLE_TO_DROP_EXCEED_LIMIT,
  [TARGET_DROP_STATUS.OVER_ROOT]: LANGUAGE_KEY.ONLY_FOLDERS_CAN_BE_DRAGGED_TO_THE_ROOT_LEVEL
};

export const CURRENT_LOCATION_OPTION_VALUE = 'CURRENT';
