import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { AuthessHealthResponse } from './eaq-app-facade-service.dtos';

export const fetchAuthessHealthCheck = (courseSectionId: string): Promise<AuthessHealthResponse> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<AuthessHealthResponse>(
      `/eaq/authess/courseSection/${courseSectionId}/healthcheck`
    );
  });
};
