import { ELSCommonConfig } from '../els.components';

const stageGatewayURL = 'https://eolsapi-stage.apps.els-ols.com';
const qaContentBaseURL = 'https://content-qa.els-ols.com';
const lowerEnvCookiesDomain = '.apps.els-ols.com';
const lowerEvolveCartURL = 'https://evolvetest.elsevier.com/cs/lms';
const evolveCartURL = 'https://evolve.elsevier.com/cs/lms';
const myEvolveUrlLower = 'https://evolvetest.elsevier.com/cs/myEvolve';
const myEvolveUrlProd = 'https://evolve.elsevier.com/cs/myEvolve';
const myEvolveAccountUrlLower = 'https://evolvetest.elsevier.com/cs/account';
const myEvolveAccountUrlProd = 'https://evolve.elsevier.com/cs/account';

export const ServerConstants = {
  DataSource: ELSCommonConfig.appProfile, // Replace with the preferred source from below.

  dev: {
    //  'Dev'
    gatewayBaseURL: 'https://eolsapi-dev.apps.els-ols.com',
    contentBaseURL: 'https://content-dev.els-ols.com/dev',
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  qa: {
    //  'QA'
    gatewayBaseURL: 'https://qa-apigateway-eols.elsevier.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  demo: {
    //  'Demo'
    gatewayBaseURL: 'https://eolsapi-demo.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  test1: {
    gatewayBaseURL: 'https://eolsapi-test1.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  test2: {
    gatewayBaseURL: 'https://eolsapi-test2.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  test3: {
    gatewayBaseURL: 'https://eolsapi-test3.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  test4: {
    gatewayBaseURL: 'https://eolsapi-test4.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  test5: {
    gatewayBaseURL: 'https://eolsapi-test5.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  test6: {
    gatewayBaseURL: 'https://eolsapi-test6.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  test7: {
    gatewayBaseURL: 'https://eolsapi-test7.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  'ck-cert': {
    // test7
    gatewayBaseURL: 'https://eolsapi-test7.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  'cd-staging': {
    // stage
    gatewayBaseURL: stageGatewayURL,
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  staging: {
    // stage
    gatewayBaseURL: stageGatewayURL,
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  stage: {
    gatewayBaseURL: stageGatewayURL,
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: 'https://evolvestage.elsevier.com/cs/myEvolve',
    myEvolveAccountUrl: 'https://evolvestage.elsevier.com/cs/account',
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  stage1: {
    gatewayBaseURL: 'https://eolsapi-stage1.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  stage2: {
    gatewayBaseURL: 'https://eolsapi-stage2.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  validate: {
    gatewayBaseURL: 'https://eolsapi-validate.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: 'validate',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  vendor: {
    gatewayBaseURL: 'https://eolsapi-vendor.apps.els-ols.com',
    contentBaseURL: qaContentBaseURL,
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  perf: {
    //  'Perf environment
    gatewayBaseURL: 'https://eolsapi-perf.apps.elsevier.education',
    contentBaseURL: 'https://content-perf.apps.elsevier.education',
    cookiesDomain: lowerEnvCookiesDomain,
    evolveCartURL: lowerEvolveCartURL,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  betaprod: {
    //  'Prod
    gatewayBaseURL: 'https://eolsapi-betaprod.apps.elsevier.education',
    contentBaseURL: 'https://content-betaprod.apps.elsevier.education',
    evolveCartURL: lowerEvolveCartURL,
    cookiesDomain: lowerEnvCookiesDomain,
    myEvolveUrl: myEvolveUrlLower,
    myEvolveAccountUrl: myEvolveAccountUrlLower,
    eolsEnv: '',
    config: {
      PushEventsEnabled: true,
      PushEventsUsesGateway: true,
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  },
  prod: {
    //  'Prod
    gatewayBaseURL: 'https://eolsapi.elsevier.com',
    contentBaseURL: 'https://eolscontent.elsevier.com',
    evolveCartURL,
    myEvolveUrl: myEvolveUrlProd,
    myEvolveAccountUrl: myEvolveAccountUrlProd,
    cookiesDomain: '.elsevier.com',
    eolsEnv: '',
    config: {
      TokenServiceEnabled: true, // toggle for enabling token service integration
      CORSAllowCredentials: true, // toggle to turn on allow-credentials needed for Amazon S3 Cloud Front Cookies
      EnableLogging: true
    }
  }
};
