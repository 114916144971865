import { eolsBaseApi, withApiErrorLoggingOnly } from '../../utilities/api.utilities';
import { RecTaxonomyNodeDto } from '../rec-gateway/rec-gateway.dtos';

// This currently will not work since this API is not exposed in the gateway yet
// https://elsevier-healthsolutions.atlassian.net/browse/REC-1945
export const fetchTaxonomy = (taxonomyId: string): Promise<RecTaxonomyNodeDto[]> => {
  return withApiErrorLoggingOnly(() => {
    const url = `/taxonomies?filter[is_root]=true&filter[rootId]=${taxonomyId}`;
    return eolsBaseApi.get(url);
  });
};
