export enum ExternalIdTypeDto {
  ASSIGNMENT_ID = 'assignmentId',
  CATALOG_ITEM_ID = 'catalogItemId',
  // COURSE_SECTION_ID = 'courseSectionId', // Deprecated but still exists in database
  TAXONOMY_NODE_ID = 'taxonomyNodeId',
}

export enum ActiveSyllabusItemTypeDto {
  ADAPTIVE_LESSON = 'ADAPTIVE_LESSON',
  ADAPTIVE_QUIZ = 'ADAPTIVE_QUIZ',
  EBOOK_READING = 'EBOOK_READING',
  EVOLVE_INSTRUCTOR_RESOURCE = 'EVOLVE_INSTRUCTOR_RESOURCE',
  EVOLVE_STUDENT_RESOURCE = 'EVOLVE_STUDENT_RESOURCE',
  FOLDER = 'FOLDER',
  SHERPATH_LESSON = 'SHERPATH_LESSON',
  SHERPATH_SIMULATIONS = 'SHERPATH_SIMULATIONS',
  SHERPATH_SKILL = 'SHERPATH_SKILL',
  SIMCHART_CASE_STUDY = 'SIMCHART_CASE_STUDY',
  SIMCHART_CHART = 'SIMCHART_CHART',
  SHADOW_HEALTH_ASSIGNMENT = 'SHADOW_HEALTH_ASSIGNMENT',
  ASSESSMENT_BUILDER_ASSIGNMENT = 'ASSESSMENT_BUILDER_ASSIGNMENT',
  SHERPATH_POWERPOINT = 'SHERPATH_POWERPOINT',
  SHERPATH_GROUP_ACTIVITY = 'SHERPATH_GROUP_ACTIVITY',
  SHERPATH_CASE_STUDY = 'SHERPATH_CASE_STUDY',
  CUSTOM_LINK = 'CUSTOM_LINK',
  OSMOSIS_VIDEO = 'OSMOSIS_VIDEO',
  INTERACTIVE_REVIEW = 'INTERACTIVE_REVIEW'
}

// TODO: These need to be removed from services
export enum DeprecatedSyllabusItemTypeDto {
  EVOLVE_RESOURCE = 'EVOLVE_RESOURCE',
  SIMCHART = 'SIMCHART',
}

export type SyllabusItemTypeDto = ActiveSyllabusItemTypeDto | DeprecatedSyllabusItemTypeDto;
