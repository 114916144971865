import React, { ReactNode } from 'react';
import { ELSIcon } from '../els.components';
import { FlexLayout } from '../flex/FlexLayout.component';
import { FlexItem } from '../flex/FlexItem.component';
import { FlexLayoutModifier } from '../flex/flex.constants';
import { ELSButtonIconPosition } from '../../models/button.models';
import { getEAQIcon } from '../eaq-icon/eaq-icon.utilities';
import { getPlayVideoIcon } from '../play-video-icon/play-video-icon';
import { getFeedbackIcon } from '../feedback-icon/feedback-icon.utilities';
import { getInsertQuestionIcon } from '../insert-question-icon/insert-question-icon.utilities';
import { getAIStarsIcon } from '../ai-icon/ai-icon.utilities';

export interface IconWithTextProps {
  children: ReactNode;
  iconName: string;
  iconPrefix: string;
  iconSize?: string;
  iconPosition?: ELSButtonIconPosition;
  iconClass?: string;
  iconWrapperClass?: string;
}

const IconWithText = (props: IconWithTextProps) => {
  const {
    children,
    iconName,
    iconPrefix,
    iconSize,
    iconPosition,
    iconClass,
    iconWrapperClass
  } = props;

  const positionOfIcon = iconPosition || ELSButtonIconPosition.LEFT;

  const renderIcon = () => {
    switch (iconName) {
      case 'ai-icon':
        return getAIStarsIcon(iconClass, iconWrapperClass);
      case 'feedback-icon':
        return getFeedbackIcon(iconClass)();
      case 'insert-question':
        return getInsertQuestionIcon(iconClass, iconWrapperClass);
      case 'eugene':
        return getEAQIcon(iconClass, iconWrapperClass);
      case 'play-video':
        // TODO: Remove the entire getPlayVideoIcon file after implementing the play-video icon and updating @els/els-styleguide-core to the latest version.
        return getPlayVideoIcon(iconClass, iconWrapperClass);
      default:
        return iconWrapperClass ? (
          <div className={iconWrapperClass}>
            <ELSIcon
              size={iconSize || '1x'}
              prefix={iconPrefix}
              name={iconName}
              customClass={`u-els-display-block ${iconClass || ''}`}
            />
          </div>
        ) : (
          <ELSIcon
            size={iconSize || '1x'}
            prefix={iconPrefix}
            name={iconName}
            customClass={`u-els-display-block ${iconClass || ''}`}
          />
        );
    }
  };

  return (
    <FlexLayout modifiers={[FlexLayoutModifier.GUTTERS_1o2, FlexLayoutModifier.MIDDLE, FlexLayoutModifier.LEFT]}>
      <FlexItem classes={['u-cw-min-width-0']} isRender={positionOfIcon === ELSButtonIconPosition.RIGHT}>
        <div>
          {children}
        </div>
      </FlexItem>
      <FlexItem>
        {renderIcon()}
      </FlexItem>
      <FlexItem classes={['u-cw-min-width-0']} isRender={positionOfIcon === ELSButtonIconPosition.LEFT}>
        <div className="u-els-margin-top-1o8">
          {children}
        </div>
      </FlexItem>
    </FlexLayout>
  );
};

export default IconWithText;
