import React from 'react';
import memoizeOne from 'memoize-one';

export const getFeedbackIcon = (customClass: string) => memoizeOne(() => {
  return (
    <svg width="1rem"
         height="1rem"
         fill="currentColor"
         className={customClass}
         aria-hidden
         viewBox="0 0 64 64">
      <g>
        <rect x="0" width="64" height="64" fill="none" />
      </g>
      <g>
        <g>
          <path
            d="M24.1,38L17,46.1V38h-5.5C7.9,38,5,35.1,5,31.5v-13c0-3.6,2.9-6.5,6.5-6.5h25c3.6,0,6.5,2.9,6.5,6.5v11.3
          c1.6-0.5,3.3-0.7,5-0.8V18.5C48,12.2,42.8,7,36.5,7h-25C5.2,7,0,12.2,0,18.5v13C0,37.8,5.2,43,11.5,43H12v9.8c0,1,0.6,2,1.6,2.3
          c1,0.4,2.1,0.1,2.8-0.7l10-11.5h3.4c0.5-1.8,1.3-3.5,2.3-5H24.1z"
          />
          <path
            d="M48.5,33C39.9,33,33,39.9,33,48.5C33,57.1,39.9,64,48.5,64C57.1,64,64,57.1,64,48.5C64,39.9,57.1,33,48.5,33z
          M48.5,59.1c-5.9,0-10.6-4.7-10.6-10.6c0-5.9,4.7-10.6,10.6-10.6s10.6,4.7,10.6,10.6C59.1,54.4,54.4,59.1,48.5,59.1z"
          />
          <polygon
            className="st1"
            points="51,41 46,41 46,46 41,46 41,51 46,51 46,56 51,56 51,51 56,51 56,46 51,46"
          />
        </g>
      </g>
    </svg>
  );
});
