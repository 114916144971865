import { ELSDropDownOption } from '@els/els-component-form-field-react';
import {
  ClinicalSkillsFilterChangeProps,
  ClinicalSkillsFilterState
} from './clinical-skills-filter.models';
import {
  getEbookStateStateOnChange,
  getSelectedTaxonomiesFromEbookFilterState,
  isEbookSelected,
  isStageChapterSelected as isEbookStageChapterSelected,
} from '../ebook-filter/ebook-filter.utilities';
import { EbookFilterChangeProps } from '../ebook-filter/ebook-filter.models';
import { RecJsonApiDataItem, RecTaxonomyNodeDto } from '../../apis/rec-gateway/rec-gateway.dtos';
import { CatalogWithExternalEntitiesDto } from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { CourseSectionDto } from '../../apis/eols-course-crud/eols-course-crud.dtos';
import { EvolveProductTypeKey } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { mapToIds } from '../../utilities/common.utilities';
import { SELECT_OPTION } from '../../utilities/app.utilities';

export const getClinicalSkillsStateStateOnChange = (props: ClinicalSkillsFilterChangeProps, state: ClinicalSkillsFilterState): ClinicalSkillsFilterState =>
  getEbookStateStateOnChange(props as unknown as EbookFilterChangeProps, state);

export const getSelectedTaxonomiesFromClinicalSkillsFilterState = (state: ClinicalSkillsFilterState): string[] =>
  getSelectedTaxonomiesFromEbookFilterState(state);

export const isClinicalSkillsSelected = (clinicalSkillsTaxonomy: RecTaxonomyNodeDto, state: ClinicalSkillsFilterState): boolean =>
  isEbookSelected(clinicalSkillsTaxonomy, state);

export const isStageChapterSelected = (clinicalSkillsTaxonomy: RecTaxonomyNodeDto, state: ClinicalSkillsFilterState): boolean =>
  isEbookStageChapterSelected(clinicalSkillsTaxonomy, state);

export const getChapterOptions = (
  clinicalSkillsTaxonomy: RecTaxonomyNodeDto,
  catalog: CatalogWithExternalEntitiesDto,
  state: ClinicalSkillsFilterState,
  chapterId: string
): ELSDropDownOption[] => {
  const options = clinicalSkillsTaxonomy.relationships.children.data
    .map((chapter) => {

      const taxonomyNode = catalog.catalog.included.find((node) => {
        return node.id === chapter.id;
      });

      return {
        value: chapter.id,
        name: taxonomyNode.attributes.text,
        taxonomyNode
      };
    })
    .sort((a, b) => {
      return a.taxonomyNode.attributes.displayName.localeCompare(b.taxonomyNode.attributes.displayName);
    });

  const filteredOptions: ELSDropDownOption[] = options.filter((option) => {
    if (!state || !state[clinicalSkillsTaxonomy.id]) {
      return true;
    }

    if (chapterId === option.value) {
      return true;
    }

    return !state[clinicalSkillsTaxonomy.id].chapters
      .map(mapToIds).includes(option.value);
  });

  if (!chapterId) {
    filteredOptions.unshift(SELECT_OPTION);
  }

  return filteredOptions;
};

export const getClinicalSkillsTaxonomies = (props: {
  course: CourseSectionDto;
  catalog: CatalogWithExternalEntitiesDto;
  primaryTaxonomies: PrimaryTaxonomy[];
// eslint-disable-next-line sonarjs/cognitive-complexity
}): RecTaxonomyNodeDto[] => {
  const { catalog, primaryTaxonomies, course } = props;

  if (!catalog || !primaryTaxonomies || !primaryTaxonomies.length) {
    return [];
  }

  const allClinicalSkillsEntitlements = course.entitlements
    && course.entitlements.filter((entitlement) => {
      return entitlement.evolveProductTypeKey === EvolveProductTypeKey.CLINICALSKILLS_IA;
    });

  if (!allClinicalSkillsEntitlements) {
    return [];
  }

  const clinicalSkillsIsbns = allClinicalSkillsEntitlements.map(skill => skill.isbn);

  return catalog.catalog.included.filter((catalogBookTaxon) => {
    return primaryTaxonomies.find((primaryTaxonomy) => {
      if (!primaryTaxonomy
        && !primaryTaxonomy.taxonomy
        && !primaryTaxonomy.taxonomy.data) {
        return false;
      }
      const primaryTaxonomyHasLargestData = primaryTaxonomy.taxonomy.data.reduce((acc, cur) =>
        (!acc.relationships
          && !acc.relationships.children
          && !acc.relationships.children
          && !acc.relationships.children.data
          && acc.relationships.children.data.length > cur.relationships.children.data.length
          ? acc : cur));

      return clinicalSkillsIsbns.includes(primaryTaxonomy.isbn) && primaryTaxonomyHasLargestData.id === catalogBookTaxon.id;
    });
  }).map(result => {
    if (!result
      && !result.relationships
      && !result.relationships.children
      && !result.relationships.children.data) {
      return null;
    }

    const secondLevelRecJsonApiDataItems: RecJsonApiDataItem[] = result.relationships.children.data.reduce((acc, cur) => {
      const recTaxonomyNode = catalog.catalog.included.find(catalogBookTaxon => catalogBookTaxon.id === cur.id);
      if (recTaxonomyNode.attributes.leaf) {
        return [...acc, cur];
      }
      return [...acc, ...recTaxonomyNode.relationships.children.data];
    }, []);
    return {
      ...result,
      relationships: {
        ...result.relationships,
        children: {
          ...result.relationships.children,
          data: secondLevelRecJsonApiDataItems
        }
      }
    };
  });
};
