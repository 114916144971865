import {
  RecContentItemDto,
  RecContentItemTypeDto
} from '../../apis/rec-gateway/rec-gateway.dtos';
import { SelectFolderOption } from '../../components/reoder-dropdowns/reorder-dropdowns.constants';
import { BundleMemberProductTypeKeyDto } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';
import { LANGUAGE_KEY } from '../../translations/message.constants';
import { SyllabusItemTypeConfigMap } from '../../constants/content-type.constants';
import {
  CatalogEvolveResourcePermission,
  CatalogExternalEntityDto,
  CatalogWithExternalEntitiesDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import { ServerConstants } from '../../components/app/server.constants';
import { ELSCommonConfig } from '../../components/els.components';
import { ActiveSyllabusItemTypeDto } from '../../apis/sherpath-syllabus-service/sherpath-syllabus-service.constants';
import { EvolveResourceAssetType } from './catalog.models';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import {
  getEbookReadingTitle,
  getEnhancedLesson,
  getEnhancedLessonClone,
  getEvolveResource,
  getEvolveResourceClone,
  getInteractiveReview,
  getInteractiveReviewClone,
  getOsmosisVideo,
  getOsmosisVideoClone,
  getShadowHealth,
  getShadowHealthClone,
  getSherpathCaseStudy,
  getSherpathCaseStudyClone,
  getSherpathGroupActivity,
  getSherpathGroupActivityClone,
  getSherpathLesson,
  getSherpathLessonClone,
  getSherpathPowerpoint,
  getSherpathPowerpointClone,
  getSherpathSimulation,
  getSherpathSimulationClone,
  getSherpathSkill,
  getSherpathSkillClone,
  getSimchart,
  getSimchartClone
} from './catalog.utilities';

export const ALL_OPTION_VALUE = 'ALL';
export const ALL_OPTION_NAME = 'All';

export const SyllabusFolderOptionType = {
  ADD_FOLDER: {
    name: 'New Folder',
    value: 'ADD_NEW_SYLLABUS_FOLDER',
  },
  SELECT_FOLDER: SelectFolderOption
};

export const PAGINATOR_A11Y_PROPS = {
  name: 'Catalog pagination top',
  pagingUnitName: 'Page',
  morePagingUnit: 'More pages'
};

export enum EbookPageRangeSeparators {
  CHUNK_SEPARATOR = ',',
  RANGE_SEPARATOR = '-',
}

export const CONFIRM_BULK_ADD_MODAL_ID = 'CONFIRM_BULK_ADD_MODAL_ID';

export const DEFAULT_PROMOTION_CODE = '20006';

export const RECENTLY_PUBLISHED_DISPLAY_TIME_IN_DAYS = 14;

export const LOCATION_PATHNAME = 'location.pathname';

export enum CatalogPageParam {
  STATIC_RESOURCE_TYPE = 'staticResourceType',
  TARGET_SYLLABUS_ITEM_ID = 'targetSyllabusItemId',
  REF = 'ref'
}

export const SequenceMappedTypes = [
  RecContentItemTypeDto.SHERPATH_LESSON,
  RecContentItemTypeDto.SHERPATH_SIMULATION,
  RecContentItemTypeDto.OSMOSIS_VIDEO,
];

export enum CatalogItemInclude {
  'OSMOSIS_VIDEO' = 'Osmosis Video'
}

export enum CatalogViewType {
  FOLDER_VIEW = 'FOLDER_VIEW',
  LIST_VIEW = 'LIST_VIEW',
}

export const BundleMemberProductPurchaseDisplayMap: Record<BundleMemberProductTypeKeyDto, LANGUAGE_KEY> = {
  [BundleMemberProductTypeKeyDto.BINDER_READY]: LANGUAGE_KEY.PURCHASE_BINDER_READY_VERSION,
  [BundleMemberProductTypeKeyDto.HARDCOVER]: LANGUAGE_KEY.PURCHASE_HARCOVER_VERSION,
  [BundleMemberProductTypeKeyDto.PAPERBACK]: LANGUAGE_KEY.PURCHASE_PAPERBACK_VERSION,
  [BundleMemberProductTypeKeyDto.SPIRAL_BOUND]: LANGUAGE_KEY.PURCHASE_SPIRAL_BOUND_VERSION
};
export const contentBaseURL = ServerConstants[ELSCommonConfig.appProfile].contentBaseURL as string;
export const recContentItemTypeDtoToContentTypeMap: Record<RecContentItemTypeDto, ActiveSyllabusItemTypeDto> = {
  [RecContentItemTypeDto.ADAPTIVE_LESSON]: ActiveSyllabusItemTypeDto.ADAPTIVE_LESSON,
  [RecContentItemTypeDto.EBOOK_READING]: ActiveSyllabusItemTypeDto.EBOOK_READING,
  [RecContentItemTypeDto.SIM_CHART]: ActiveSyllabusItemTypeDto.SIMCHART_CASE_STUDY, // All Catalog SimCharts are case studies for now
  [RecContentItemTypeDto.SHERPATH_SKILL]: ActiveSyllabusItemTypeDto.SHERPATH_SKILL,
  [RecContentItemTypeDto.SHERPATH_SIMULATION]: ActiveSyllabusItemTypeDto.SHERPATH_SIMULATIONS,
  [RecContentItemTypeDto.SHERPATH_LESSON]: ActiveSyllabusItemTypeDto.SHERPATH_LESSON,
  [RecContentItemTypeDto.SHADOW_HEALTH]: ActiveSyllabusItemTypeDto.SHADOW_HEALTH_ASSIGNMENT,
  [RecContentItemTypeDto.SHERPATH_POWERPOINT]: ActiveSyllabusItemTypeDto.SHERPATH_POWERPOINT,
  [RecContentItemTypeDto.SHERPATH_CASE_STUDY]: ActiveSyllabusItemTypeDto.SHERPATH_CASE_STUDY,
  [RecContentItemTypeDto.OSMOSIS_VIDEO]: ActiveSyllabusItemTypeDto.OSMOSIS_VIDEO,
  [RecContentItemTypeDto.EVOLVE_RESOURCE]: null,
  [RecContentItemTypeDto.QUESTION]: null, // Not an assignable entity
  [RecContentItemTypeDto.HESI_EXAM]: null,
  [RecContentItemTypeDto.SHERPATH_MODULE]: null,
  [RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY]: ActiveSyllabusItemTypeDto.SHERPATH_GROUP_ACTIVITY,
  [RecContentItemTypeDto.SHERPATH_TESTBANK]: null,
  [RecContentItemTypeDto.REVIEW_QUIZ]: ActiveSyllabusItemTypeDto.INTERACTIVE_REVIEW,
};
export const EvolveResourceAssetTypeDisplayMap: Record<EvolveResourceAssetType, string> = {
  [EvolveResourceAssetType.INS_ANIMATIONS_VIDEOS]: 'Animations/Videos',
  [EvolveResourceAssetType.INS_ANSWER_KEY]: 'Answer Key',
  [EvolveResourceAssetType.INS_CASE_STUDIES]: 'Case Studies',
  [EvolveResourceAssetType.INS_CHECKLIST]: 'Checklist',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__ADA_ACCREDITATION_GUIDE]: 'Curricular Resources',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__ANNOUNCEMENTS]: 'Announcements',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__DENTAL_BOARD_OF_CALIFORNIA_GUIDE]: 'Curricular Resources',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__CONTENT_UPDATES]: 'Content Updates',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__CONVERSION_GUIDE]: 'Conversion Guide',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__CURRICULUM_GUIDES]: 'Curriculum Guides',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__CURRICULUM_MAP]: 'Curriculum Map',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__GLOSSARY]: 'Glossary',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__LEARNING_OBJECTIVES]: 'Learning Objectives',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__NURSING_SKILLS_ONLINE_READING_ASSIGNMENTS]: 'Nursing Skills Online Reading Assignments',
  [EvolveResourceAssetType.INS_CURRICULAR_RESOURCES__OUTLINES_SUMMARIES]: 'Outline/Summaries',
  [EvolveResourceAssetType.INS_DOWNLOAD]: 'Download by Resource Type',
  [EvolveResourceAssetType.INS_DOWNLOAD_BY_RESOURCE_TYPE]: 'Download by Resource Type',
  [EvolveResourceAssetType.INS_EAB_RESOURCE]: 'Elsevier Assessment Builder Resource',
  [EvolveResourceAssetType.INS_EXEMPLARS]: 'Exemplars',
  [EvolveResourceAssetType.INS_GENERAL_RESOURCES__CREDITS]: 'Credits',
  [EvolveResourceAssetType.INS_GENERAL_RESOURCES__OTHER_RESOURCES]: 'Other Resources',
  [EvolveResourceAssetType.INS_IMAGE_COLLECTION]: 'Image Collection',
  [EvolveResourceAssetType.INS_INSTRUCTOR_LED_ASSESSMENTS__CHAPTER_PRETESTS]: 'Chapter Pretests',
  [EvolveResourceAssetType.INS_INSTRUCTOR_LED_ASSESSMENTS__QUESTION]: 'Question',
  [EvolveResourceAssetType.INS_INSTRUCTOR_LED_ASSESSMENTS__QUESTIONS]: 'Questions',
  [EvolveResourceAssetType.INS_INSTRUCTOR_LED_ASSESSMENTS__TEST_BANK]: 'Test Bank',
  [EvolveResourceAssetType.INS_INTERRELATED_CONCEPTS]: 'Interrelated Concepts',
  [EvolveResourceAssetType.INS_POWERPOINT_SLIDES]: 'PowerPoint Slides',
  [EvolveResourceAssetType.INS_TEACH__2_7_HANDOUTS]: 'TEACH Handout',
  [EvolveResourceAssetType.INS_TEACH__2_7_LESSON_PLANS]: 'TEACH Lesson Plan',
  [EvolveResourceAssetType.INS_TEACH__2_7_PRETESTS]: 'TEACH Pretest',
  [EvolveResourceAssetType.INS_TEACH__2_7_STUDENT_HANDOUTS]: 'TEACH Student Handout',
  [EvolveResourceAssetType.INS_TEACH__2_7_WORKBOOK_ANSWER_KEY]: 'TEACH Workbook Answer Key',
  [EvolveResourceAssetType.INS_TEACH__FOR_NURSES]: 'TEACH for Nurses',
  [EvolveResourceAssetType.STU_ACTIVITIES__BLOG]: 'Blog',
  [EvolveResourceAssetType.STU_ACTIVITIES__DENTRIX_PRACTICE_MANAGEMENT_SOFTWARE]: 'Activities',
  [EvolveResourceAssetType.STU_ACTIVITIES__GAMES]: 'Games',
  [EvolveResourceAssetType.STU_ACTIVITIES__INTRODUCTION_TO_DENTRIX_PRACTICE_MANAGEMENT_SOFTWARE]: 'Activities',
  [EvolveResourceAssetType.STU_ACTIVITIES__REFERENCE_CARDS]: 'Reference Cards',
  [EvolveResourceAssetType.STU_ACTIVITIES__TOOLBOX]: 'Toolbox',
  [EvolveResourceAssetType.STU_ANIMATIONS_VIDEOS]: 'Animations/Videos',
  [EvolveResourceAssetType.STU_ANSWER_KEY]: 'Answer Key',
  [EvolveResourceAssetType.STU_AUDIO_CLIPS]: 'Audio Clips',
  [EvolveResourceAssetType.STU_CALCULATORS]: 'Calculators',
  [EvolveResourceAssetType.STU_CARE_PLANNING]: 'Care Planning',
  [EvolveResourceAssetType.STU_CARE_PLANNING__CONCEPTUAL]: 'Conceptual Care Plan Creator',
  [EvolveResourceAssetType.STU_CASE_STUDIES]: 'Case Studies',
  [EvolveResourceAssetType.STU_CHECKLISTS]: 'Checklists',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__ANNOUNCEMENTS]: 'Announcements',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__APPENDICES]: 'Appendices',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__APPLICATIONS]: 'Applications',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__AUDIO_GLOSSARY]: 'Audio Glossary',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__CANADIAN_CONTENT_CORNER]: 'General Resources',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__CLINICAL_REFERENCES]: 'Clinical References',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__CONTENT_UPDATES]: 'Content Updates',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__CREDITS]: 'Credits',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__GLOSSARY]: 'Glossary',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__HELPFUL_TIPS_FOR_LEARNERS]: 'Helpful Tips for Learners',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__INTERACTIVE_DENTAL_OFFICE_CONTENT_MAPPING]: 'General Resources',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__NURSING_PROCESS]: 'Nursing Process',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__OTHER_RESOURCES]: 'Other Resources',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__WEBLINKS]: 'Weblinks',
  [EvolveResourceAssetType.STU_KEY_POINTS]: 'Key Points',
  [EvolveResourceAssetType.STU_MOBILE_RESOURCES]: 'Mobile Resources',
  [EvolveResourceAssetType.STU_NURSING_SKILLS]: 'Nursing Skills',
  // eslint-disable-next-line sonarjs/no-duplicate-string
  [EvolveResourceAssetType.STU_REVIEW_QUESTIONS__FOR_EXAMINATION_PREPARATION]: 'Review Questions',
  [EvolveResourceAssetType.STU_REVIEW_QUESTIONS__NCLEX_STYLE_REVIEW_QUESTIONS]: 'Review Questions',
  [EvolveResourceAssetType.STU_REVIEW_QUESTIONS__PRACTICE_EXAMS]: 'Review Questions',
  [EvolveResourceAssetType.STU_REVIEW_QUESTIONS__REVIEW_QUESTIONS]: 'Review Questions',
  [EvolveResourceAssetType.STU_REVIEW_QUESTIONS__REVIEW_QUESTIONS_AND_ANSWERS]: 'Review Questions',
  [EvolveResourceAssetType.STU_REVIEW_QUESTIONS__REVIEW_QUESTIONS_PDF]: 'Review Questions',
  [EvolveResourceAssetType.STU_GENERAL_RESOURCES__CHAPTER_SUMMARIES]: 'Chapter Summaries',
  [EvolveResourceAssetType.STU_TUTORIALS]: 'Tutorials',
};
export const EvolveResourceAssetFilterType: Record<EvolveResourceAssetType, string> = Object.entries(EvolveResourceAssetTypeDisplayMap)
  .reduce((acc, [itemKey, itemValue]) => {
    if (itemKey.includes('INS')) {
      return { ...acc, [itemKey]: `${ActiveSyllabusItemTypeDto.EVOLVE_INSTRUCTOR_RESOURCE}_${itemValue}` };
    }
    return { ...acc, [itemKey]: `${ActiveSyllabusItemTypeDto.EVOLVE_STUDENT_RESOURCE}_${itemValue}` };
  }, {} as Record<EvolveResourceAssetType, string>);
export const RecContentTypeSortOrder: RecContentItemTypeDto[] = Object.values(SyllabusItemTypeConfigMap)
  .filter((item) => {
    return item.recContentType;
  })
  .sort((a, b) => {
    return a.sortOrder - b.sortOrder;
  })
  .reduce((acc, item) => {
    if (acc.indexOf(item.recContentType) === -1) {
      return [...acc, item.recContentType];
    }
    return acc;
  }, []);
export const evolveResourceTypeSortMap = [
  CatalogEvolveResourcePermission.INSTRUCTOR,
  CatalogEvolveResourcePermission.STUDENT,
];

export enum CatalogFilterCollapseKeys {
  SECTION = 'SECTION',
  BOOK_SECTION = 'BOOK_SECTION',
  RESOURCE_TYPE = 'RESOURCE_TYPE',
  PRODUCT = 'PRODUCT',
  STATUS = 'STATUS',
  GRADING = 'GRADING'
}

type RecContentItemTitleHandlerProps = {
  contentItem: RecContentItemDto;
  catalog: CatalogWithExternalEntitiesDto;
  primaryTaxonomies: PrimaryTaxonomy[];
  maxLength: number;
}

export const RecContentItemTitleHandlerMap: Record<RecContentItemTypeDto, (props: RecContentItemTitleHandlerProps) => string> = {
  [RecContentItemTypeDto.QUESTION]: null,
  [RecContentItemTypeDto.HESI_EXAM]: null,
  [RecContentItemTypeDto.SHERPATH_TESTBANK]: null,
  [RecContentItemTypeDto.SHERPATH_MODULE]: null,
  [RecContentItemTypeDto.EBOOK_READING]: (props: RecContentItemTitleHandlerProps) => {
    return getEbookReadingTitle(props.contentItem, props.primaryTaxonomies);
  },
  [RecContentItemTypeDto.ADAPTIVE_LESSON]: (props: RecContentItemTitleHandlerProps) => {
    const adaptiveLesson = getEnhancedLesson(props.contentItem, props.catalog);
    if (adaptiveLesson) {
      return adaptiveLesson.entity.attributes.text || adaptiveLesson.entity.attributes.displayName;
    }
    return null;
  },
  [RecContentItemTypeDto.EVOLVE_RESOURCE]: (props: RecContentItemTitleHandlerProps) => {
    const evolveResource = getEvolveResource(props.contentItem, props.catalog);
    if (evolveResource) {
      return evolveResource._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHADOW_HEALTH]: (props: RecContentItemTitleHandlerProps) => {
    const shadowHealth = getShadowHealth(props.contentItem, props.catalog);
    if (shadowHealth) {
      return shadowHealth._parsedData.assignmentTitle;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_SKILL]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathSkill = getSherpathSkill(props.contentItem, props.catalog);
    if (sherpathSkill) {
      return sherpathSkill._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_SIMULATION]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathSimulation = getSherpathSimulation(props.contentItem, props.catalog);
    if (sherpathSimulation) {
      return sherpathSimulation._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_LESSON]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathLesson = getSherpathLesson(props.contentItem, props.catalog);
    if (sherpathLesson) {
      return sherpathLesson._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SIM_CHART]: (props: RecContentItemTitleHandlerProps) => {
    const simChart = getSimchart(props.contentItem, props.catalog);
    if (simChart) {
      return simChart._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_POWERPOINT]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathPowerpoint = getSherpathPowerpoint(props.contentItem, props.catalog);
    if (sherpathPowerpoint) {
      return sherpathPowerpoint._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathGroupActivity = getSherpathGroupActivity(props.contentItem, props.catalog);
    if (sherpathGroupActivity) {
      return sherpathGroupActivity._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_CASE_STUDY]: (props: RecContentItemTitleHandlerProps) => {
    const sherpathCaseStudy = getSherpathCaseStudy(props.contentItem, props.catalog);
    if (sherpathCaseStudy) {
      return sherpathCaseStudy._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.OSMOSIS_VIDEO]: (props: RecContentItemTitleHandlerProps) => {
    const osmosisVideo = getOsmosisVideo(props.contentItem, props.catalog);
    if (osmosisVideo) {
      return osmosisVideo._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.REVIEW_QUIZ]: (props: RecContentItemTitleHandlerProps) => {
    const interactiveReview = getInteractiveReview(props.contentItem, props.catalog);
    if (interactiveReview) {
      return interactiveReview._parsedData.title;
    }
    return null;
  },
};

type RecContentItemTitleHandlerPropsClone = {
  contentItem: RecContentItemDto;
  externalEntitiesMap: Map<string, CatalogExternalEntityDto>;
  primaryTaxonomies: PrimaryTaxonomy[];
  maxLength: number;
}

export const RecContentItemTitleHandlerMapClone: Record<RecContentItemTypeDto, (props: RecContentItemTitleHandlerPropsClone) => string> = {
  [RecContentItemTypeDto.QUESTION]: null,
  [RecContentItemTypeDto.HESI_EXAM]: null,
  [RecContentItemTypeDto.SHERPATH_TESTBANK]: null,
  [RecContentItemTypeDto.SHERPATH_MODULE]: null,
  [RecContentItemTypeDto.EBOOK_READING]: (props: RecContentItemTitleHandlerPropsClone) => {
    return getEbookReadingTitle(props.contentItem, props.primaryTaxonomies);
  },
  [RecContentItemTypeDto.ADAPTIVE_LESSON]: (props: RecContentItemTitleHandlerPropsClone) => {
    const adaptiveLesson = getEnhancedLessonClone(props.contentItem, props.externalEntitiesMap);
    if (adaptiveLesson) {
      return adaptiveLesson.entity.attributes.text || adaptiveLesson.entity.attributes.displayName;
    }
    return null;
  },
  [RecContentItemTypeDto.EVOLVE_RESOURCE]: (props: RecContentItemTitleHandlerPropsClone) => {
    const evolveResource = getEvolveResourceClone(props.contentItem, props.externalEntitiesMap);
    if (evolveResource) {
      return evolveResource._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHADOW_HEALTH]: (props: RecContentItemTitleHandlerPropsClone) => {
    const shadowHealth = getShadowHealthClone(props.contentItem, props.externalEntitiesMap);
    if (shadowHealth) {
      return shadowHealth._parsedData.assignmentTitle;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_SKILL]: (props: RecContentItemTitleHandlerPropsClone) => {
    const sherpathSkill = getSherpathSkillClone(props.contentItem, props.externalEntitiesMap);
    if (sherpathSkill) {
      return sherpathSkill._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_SIMULATION]: (props: RecContentItemTitleHandlerPropsClone) => {
    const sherpathSimulation = getSherpathSimulationClone(props.contentItem, props.externalEntitiesMap);
    if (sherpathSimulation) {
      return sherpathSimulation._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_LESSON]: (props: RecContentItemTitleHandlerPropsClone) => {
    const sherpathLesson = getSherpathLessonClone(props.contentItem, props.externalEntitiesMap);
    if (sherpathLesson) {
      return sherpathLesson._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SIM_CHART]: (props: RecContentItemTitleHandlerPropsClone) => {
    const simChart = getSimchartClone(props.contentItem, props.externalEntitiesMap);
    if (simChart) {
      return simChart._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_POWERPOINT]: (props: RecContentItemTitleHandlerPropsClone) => {
    const sherpathPowerpoint = getSherpathPowerpointClone(props.contentItem, props.externalEntitiesMap);
    if (sherpathPowerpoint) {
      return sherpathPowerpoint._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_GROUP_ACTIVITY]: (props: RecContentItemTitleHandlerPropsClone) => {
    const sherpathGroupActivity = getSherpathGroupActivityClone(props.contentItem, props.externalEntitiesMap);
    if (sherpathGroupActivity) {
      return sherpathGroupActivity._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.SHERPATH_CASE_STUDY]: (props: RecContentItemTitleHandlerPropsClone) => {
    const sherpathCaseStudy = getSherpathCaseStudyClone(props.contentItem, props.externalEntitiesMap);
    if (sherpathCaseStudy) {
      return sherpathCaseStudy._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.OSMOSIS_VIDEO]: (props: RecContentItemTitleHandlerPropsClone) => {
    const osmosisVideo = getOsmosisVideoClone(props.contentItem, props.externalEntitiesMap);
    if (osmosisVideo) {
      return osmosisVideo._parsedData.title;
    }
    return null;
  },
  [RecContentItemTypeDto.REVIEW_QUIZ]: (props: RecContentItemTitleHandlerPropsClone) => {
    const interactiveReview = getInteractiveReviewClone(props.contentItem, props.externalEntitiesMap);
    if (interactiveReview) {
      return interactiveReview._parsedData.title;
    }
    return null;
  },
};
