import {
  ELSRestClientHelper,
  ELSCommonConfig,
  ELSTokenHelper,
  ELSLoggingService
} from '@els/els-ui-common-react';
import { AxiosResponse } from 'axios';

const fileName = 'api.utilities';

const eolsBaseApiUrl = () => `${ELSCommonConfig.buildUrl}/api`;

const eolsBaseUrlWithoutApi = () => `${ELSCommonConfig.buildUrl}`;

export const buildUrlFromBase = (url: string, noApiString = false) => {
  if (noApiString) {
    return `${eolsBaseUrlWithoutApi()}${url}`;
  }
  return `${eolsBaseApiUrl()}${url}`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withApiErrorLoggingOnly = <T extends any>(promiseFunction: () => Promise<AxiosResponse<T>>) =>
  promiseFunction()
    .then(res => res.data)
    .catch((error: Error) => {
      // https://github.com/elsevierPTG/els-ui-common-react/blob/develop/module/services/LoggingService.js
      ELSLoggingService.error(fileName, error.message, error);
      throw error;
    });

export const getDefaultRequestHeaders = (headers = {}) => {
  return {
    Authorization: `Bearer ${ELSTokenHelper.getToken()}`,
    accept: 'application/json, text/plain, */*',
    ...headers
  };
};

export const getDefaultRequestOptions = (options = {}, headers = {}) => {
  return {
    headers: getDefaultRequestHeaders(headers),
    ...options,
  };
};

type eolsBaseApi = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [K: string]: <T extends any>(url: string, options?: object, headers?: object) => Promise<AxiosResponse<T>>;
}

const eolsBaseApi: eolsBaseApi = {
  get: (url, options = {}, headers = {}) => ELSRestClientHelper.get(buildUrlFromBase(url), getDefaultRequestOptions(options, headers)),
  post: (url, options = {}, headers = {}) => ELSRestClientHelper.post(buildUrlFromBase(url), getDefaultRequestOptions(options, headers)),
  postWithoutApiString: (url, options = {}, headers = {}) => ELSRestClientHelper.post(buildUrlFromBase(url, true), getDefaultRequestOptions(options, headers)),
  put: (url, options = {}, headers = {}) => ELSRestClientHelper.put(buildUrlFromBase(url), getDefaultRequestOptions(options, headers)),
  patch: (url, options = {}, headers = {}) => ELSRestClientHelper.patch(buildUrlFromBase(url), getDefaultRequestOptions(options, headers)),
  delete: (url, options = {}, headers = {}) => ELSRestClientHelper.delete(buildUrlFromBase(url), getDefaultRequestOptions(options, headers))
};

export {
  withApiErrorLoggingOnly,
  eolsBaseApi
};
