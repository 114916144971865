/* eslint-disable max-len */
import memoizeOne from 'memoize-one';
import React from 'react';

export const getAIStarsIcon = memoizeOne((iconClass: string, iconWrapperClass: string) => {
  return (
    <div className={iconWrapperClass || ''}>
      <svg
        name="ai-stars"
        viewBox="0 0 44.138 44.13821"
        fill="currentColor"
        width="1rem"
        height="1rem"
        className={`o-els-icon-svg o-els-icon-svg--3o4 u-els-display-block ${iconClass || ''}`}
      >
        <path
          d="m 18.997,6.81751 2.9,13.549 c 0.102,0.4568 0.333,0.8747 0.666,1.2034 0.333,0.3287 0.753,0.5546 1.211,0.6502 l 13.547,2.9089 c 0.055,0.0108 0.105,0.0406 0.141,0.0841 0.036,0.0435 0.055,0.0982 0.055,0.1545 0,0.0564 -0.019,0.1111 -0.055,0.1546 -0.036,0.0435 -0.086,0.0728 -0.141,0.0836 l -13.547,2.9123 c -0.461,0.0977 -0.883,0.327 -1.217,0.6603 -0.333,0.3332 -0.562,0.756 -0.66,1.2171 l -2.909,13.5465 c -0.011,0.0553 -0.04,0.105 -0.084,0.1408 -0.043,0.0358 -0.098,0.0554 -0.154,0.0554 -0.057,0 -0.111,-0.0196 -0.155,-0.0554 -0.043,-0.0358 -0.073,-0.0855 -0.084,-0.1408 l -2.912,-13.5465 c -0.095,-0.4581 -0.321,-0.879 -0.65,-1.212 -0.329,-0.333 -0.747,-0.564 -1.203,-0.6654 l -13.549,-2.9 C 0.141,25.60731 0.091,25.57741 0.055,25.53391 0.019,25.49041 0,25.43581 0,25.37941 c 0,-0.0564 0.019,-0.1108 0.055,-0.1543 0.036,-0.0435 0.086,-0.0731 0.142,-0.0839 l 13.549,-2.9211 c 0.454,-0.099 0.87,-0.3263 1.198,-0.6549 0.329,-0.3285 0.556,-0.7447 0.655,-1.1987 l 2.921,-13.549 c 0.011,-0.0553 0.041,-0.1054 0.084,-0.1412 0.044,-0.0359 0.098,-0.0552 0.154,-0.0552 0.057,0 0.111,0.0193 0.155,0.0552 0.043,0.0358 0.073,0.0859 0.084,0.1412 z"
        />
        <path
          d="m 34.877,0.09824 1.45,6.77447 c 0.051,0.2284 0.167,0.4373 0.333,0.6017 0.167,0.1644 0.377,0.2773 0.606,0.3251 l 6.773,1.4544 c 0.028,0.0054 0.053,0.0204 0.071,0.0421 0.018,0.0218 0.028,0.0491 0.028,0.0773 0,0.0281 -0.01,0.0555 -0.028,0.0772 -0.018,0.0218 -0.043,0.0365 -0.071,0.0419 l -6.773,1.4561 c -0.23,0.0488 -0.442,0.1635 -0.608,0.3301 -0.167,0.1667 -0.282,0.3781 -0.331,0.6086 l -1.454,6.7733 c -0.005,0.0276 -0.02,0.0524 -0.042,0.0703 -0.022,0.018 -0.049,0.0278 -0.077,0.0278 -0.028,0 -0.056,-0.0098 -0.078,-0.0278 -0.021,-0.0179 -0.036,-0.0427 -0.042,-0.0703 l -1.456,-6.7733 c -0.047,-0.229 -0.16,-0.4395 -0.325,-0.606 -0.164,-0.1665 -0.373,-0.282 -0.601,-0.3327 l -6.775,-1.45 c -0.027,-0.0054 -0.053,-0.0203 -0.07,-0.0421 -0.018,-0.0217 -0.028,-0.0491 -0.028,-0.0772 0,-0.0282 0.01,-0.0554 0.028,-0.0772 0.017,-0.0217 0.043,-0.0365 0.07,-0.0419 l 6.775,-1.4606 c 0.227,-0.0495 0.435,-0.1631 0.599,-0.3274 0.164,-0.1643 0.278,-0.3724 0.327,-0.5994 L 34.639,0.09824 C 34.644,0.07059 34.659,0.04551 34.681,0.0276 34.703,0.00968 34.73,0 34.758,0 c 0.028,0 0.056,0.0097 0.077,0.0276 0.022,0.01791 0.037,0.04299 0.042,0.07064 z"
        />
      </svg>
    </div>
  );
});
