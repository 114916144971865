import { ELSCommonUIConstants } from '@els/els-ui-common-react';

export enum CoursewareUserHistoryStateKey {
  IS_COURSE_OWNER = 'IS_COURSE_OWNER',
  DISMISSED_LATE_ASSIGNMENTS = 'DISMISSED_LATE_ASSIGNMENTS',
  DISMISSED_NEW_CATALOG_ITEMS = 'DISMISSED_NEW_CATALOG_ITEMS',
  DISMISSED_UPCOMING_CATALOG_ITEMS = 'DISMISSED_UPCOMING_CATALOG_ITEMS',
  IS_SHADOW_HEALTH_ORIENTATION_MSG_DISMISSED = 'IS_SHADOW_HEALTH_ORIENTATION_MSG_DISMISSED',
  COLLAPSED_FOLDERS = 'COLLAPSED_FOLDERS',
  DEFAULT_COURSE_PLAN_TAB = 'DEFAULT_COURSE_PLAN_TAB',
  CATALOG_STATE = 'CATALOG_STATE',
}

export const CoursewareUserHistoryStateKeyRoleMap: Record<CoursewareUserHistoryStateKey, string[]> = {
  [CoursewareUserHistoryStateKey.IS_COURSE_OWNER]: [ELSCommonUIConstants.userType.Instructor],
  [CoursewareUserHistoryStateKey.DISMISSED_LATE_ASSIGNMENTS]: [ELSCommonUIConstants.userType.Student],
  [CoursewareUserHistoryStateKey.DISMISSED_NEW_CATALOG_ITEMS]: [ELSCommonUIConstants.userType.Instructor],
  [CoursewareUserHistoryStateKey.DISMISSED_UPCOMING_CATALOG_ITEMS]: [ELSCommonUIConstants.userType.Instructor],
  [CoursewareUserHistoryStateKey.IS_SHADOW_HEALTH_ORIENTATION_MSG_DISMISSED]: [ELSCommonUIConstants.userType.Instructor],
  [CoursewareUserHistoryStateKey.COLLAPSED_FOLDERS]: [ELSCommonUIConstants.userType.Instructor, ELSCommonUIConstants.userType.Student],
  [CoursewareUserHistoryStateKey.DEFAULT_COURSE_PLAN_TAB]: [ELSCommonUIConstants.userType.Instructor, ELSCommonUIConstants.userType.Student],
  [CoursewareUserHistoryStateKey.CATALOG_STATE]: [ELSCommonUIConstants.userType.Instructor, ELSCommonUIConstants.userType.Student],
};

export interface EolsUserHistoryResponseDto {
  id: number;
  stateKey: string;
  stateInfo: string;
  courseSectionId: number;
  instanceId?: string;
}

export interface EolsUserHistoryDto {
  id?: number;
  stateKey: string;
  stateInfo: string;
  courseSectionId: number;
  instanceId?: string;
  eolsUser: {
    id: number;
  };
}

export interface EolsLessonStatusAttributeRequest {
  attributeContents: string;
  userId: number;
  lessonId: string;
  attributeName: string;
  courseSectionId?: number;
  instanceId?: string;
}
