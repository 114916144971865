import {
  eolsBaseApi,
  withApiErrorLoggingOnly
} from '../../utilities/api.utilities';
import { addSearchParams } from '../../utilities/app.utilities';
import {
  EvolveProductDto,
  SherpathSchedulesDto
} from './sherpath-app-facade-service.dtos';
import {
  NewTokenDto
} from '../sherpath-course-management-service/sherpath-course-management-service.dtos';

export const fetchSherpathSchedules = (courseSectionId: string, tokenDto: NewTokenDto): Promise<SherpathSchedulesDto> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get<SherpathSchedulesDto>(
      addSearchParams('/coursePlanning/schedules', { courseSectionId }),
      {
        ignoreToken: true,
      },
      { Authorization: `Bearer ${tokenDto.token}` }
    );
  });
};

export const fetchEvolveProducts = (courseSectionId: string, isbns: string[]): Promise<EvolveProductDto[]> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/sherpath/courseSections/${courseSectionId}/evolve/product/v3`, {
      isbns
    }));
  });
};

export const fetchSherpathClassicGradeExport = (courseSectionId: string, timeZoneOffset: string): Promise<ArrayBuffer> => {
  return withApiErrorLoggingOnly(() => {
    return eolsBaseApi.get(addSearchParams(`/sherpath/courseSections/${courseSectionId}/grades`, {
      timeZoneOffset
    }), {
      responseType: 'arraybuffer'
    });
  });
};
