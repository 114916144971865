import React, { Component } from 'react';
import { ELSDropDown, ELSDropDownOption } from '@els/els-component-form-field-react';
import { ELSButton } from '@els/els-component-button-react';
import { connect, ConnectedProps } from 'react-redux';
import { compose } from 'recompose';
import { FlexLayout } from '../../components/flex/FlexLayout.component';
import { FlexLayoutModifier } from '../../components/flex/flex.constants';
import { FlexItem } from '../../components/flex/FlexItem.component';
import { ELSButtonSize, ELSButtonType } from '../../models/button.models';
import { cwSelectors } from '../../redux/courseware/courseware.selectors';

export const ProductSelectModalId = 'PRODUCT_SELECT_MODAL';

const mapStateToProps = state => ({
  isLoading: cwSelectors.getIsLoading(state),
});

export interface ProductSelectModalPropsOnly {
  options: ELSDropDownOption[];
  onClickConfirm: Function;
}

export interface ProductSelectModalState {
  productIsbn: string;
}

const connector = connect(mapStateToProps, null);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type ProductSelectModalProps = PropsFromRedux & ProductSelectModalPropsOnly;

export class ProductSelectModal extends Component<ProductSelectModalProps, ProductSelectModalState> {

  constructor(props) {
    super(props);
    this.state = {
      productIsbn: '',
    };
  }

  onChange = (event, productIsbn) => {
    this.setState({
      productIsbn
    });
  }

  isSubmitButtonDisabled = () => {
    const {
      isLoading,
    } = this.props;

    const {
      productIsbn,
    } = this.state;
    return !productIsbn || !productIsbn.length || isLoading;
  }

  render() {

    const {
      options,
      onClickConfirm,
    } = this.props;

    const {
      productIsbn,
    } = this.state;

    return (
      <div>
        <div className="u-els-font-size-h3">Product Select</div>
        <div className="o-els-container">
          <ELSDropDown options={options}
                       value={productIsbn}
                       name="PRODUCT_SELECT"
                       changeHandler={this.onChange}>
            Select Product
          </ELSDropDown>
        </div>
        <div className="u-els-margin-top-2x">
          <FlexLayout modifiers={[
            FlexLayoutModifier.GUTTERS,
            FlexLayoutModifier.WRAP,
            FlexLayoutModifier.RIGHT
          ]}>
            <FlexItem>
              <ELSButton type={ELSButtonType.PRIMARY}
                         isDisabled={this.isSubmitButtonDisabled()}
                         size={ELSButtonSize.SMALL}
                         onClick={() => onClickConfirm(productIsbn)}>
                Select
              </ELSButton>
            </FlexItem>
          </FlexLayout>
        </div>
      </div>
    );
  }
}

const enhancers = [
  connector,
];

export default compose<{}, ProductSelectModalPropsOnly>(...enhancers)(ProductSelectModal);
