import { ELSStepperItem } from '@els/els-component-shared-ts-react';

export const EVOLVE_RESOURCE_ASSIGNMENT_EDITOR_PAGE_TITLE = 'Evolve Resource Assignment';
export const EVOLVE_STUDENT_RESOURCE_ASSIGNMENT_EDITOR_PAGE_TITLE = 'Evolve Student Resource Assignment';
export const EVOLVE_INSTRUCTOR_RESOURCE_ASSIGNMENT_EDITOR_PAGE_TITLE = 'Evolve Instructor Resource Assignment';

export enum EvolveResourceStep {
  STEP1 = 'STEP1',
}

export const EvolveDefaultSteps: ELSStepperItem[] = [
  {
    heading: 'Assignment',
    id: EvolveResourceStep.STEP1,
    isActive: false,
    isClickable: false,
    isComplete: false,
    isReady: false,
    showActionButtons: true,
    subtitle: 'Assignment',
    title: 'Step 1',
  }
];
