import {
  SyllabusItemDto,
  SyllabusItemExternalIdDto
} from '../sherpath-syllabus-service/sherpath-syllabus-service.dtos';
import {
  ELSCourseSectionDto,
  ELSCourseSectionLiteDto
} from '../../models/els.dtos';
import { AssignmentDto } from '../eols-assessment-service/eols-assessment-service.dtos';
import {
  RecCatalogDto,
  RecTaxonomyNodeAttributesNodeTypeDto,
  RecTaxonomyNodeRelationshipsDto
} from '../rec-gateway/rec-gateway.dtos';
import {
  ResourceDto,
  SequenceDto,
} from '../ocs-api-service/ocs-api-service.dtos';

export interface SyllabusFetchSearchParams {
  externalIds: string;
}

export interface ExternalEntityDto {
  entity: Partial<AssignmentDto> | ELSCourseSectionLiteDto;
  externalIdentifier: SyllabusItemExternalIdDto;
}

export interface CourseManagementSyllabusItemsDto {
  externalEntities: ExternalEntityDto[];
  syllabusItems: SyllabusItemDto[];
}

export interface ChapterDto {
  pages: string[];
  vtwId: string[];
  chapterTitle: string;
  chapterNumber: string;
}

export interface EbookDto {
  isbn: string;
  title: string;
  edition: string;
  chapters: ChapterDto[];
  displayAuthor: string;
}

export interface VitalSourceDto {
  signInUrl: string;
  expires: string;
}

export interface UserDto {
  courseSections: ELSCourseSectionDto[];
  emailAddress: string;
  eolsUserHistory: {};
  eolsUserRoles: [];
  externalId: string;
  firstName: string;
  id: number;
  lastName: string;
  lastSeenDate: string;
  lmsExternalId: string;
  migrated: boolean;
  type: 'Ins' | 'Stu';
  updatedAt: string;
  username: string;
}

export interface SyllabusAssignmentDto {
  assignment: Partial<AssignmentDto>;
  syllabusItemDTO?: SyllabusItemDto;
}

// this should be equal to TaxonomyNodeDto but isLeaf and isRoot is different. Reach out to Rec team to correct
export type CatalogAdaptiveLessonExternalEntityDto = CatalogExternalEntityBase & {
  entity: {
    id: string;
    type: string;
    attributes: Partial<{
      id: string;
      vtwId: string;
      level: number;
      displayName: string;
      displayOrder: number;
      text: string;
      isLeaf: boolean;
      isRoot: boolean;
      nodeType: RecTaxonomyNodeAttributesNodeTypeDto;
      containsVideo?: string;
      hasDuration?: string;
    }>;
    relationships: Partial<RecTaxonomyNodeRelationshipsDto>;
  };
}

export type CatalogEvolveResourceExternalEntityDto = CatalogExternalEntityBase & {
  entity: ResourceDto;
}

export type CatalogSherpathPowerpointExternalEntityDtoParsed = CatalogEvolveResourceExternalEntityDto & {
  _parsedData: {
    title: string;
    url: string;
    displayOrder: string;
    resourceData: object;
    resourceType: string;
    learningDuration: string;
    _fullURL?: string;
  };
}

export type CatalogSherpathGroupActivityExternalEntityDtoParsed = CatalogEvolveResourceExternalEntityDto & {
  _parsedData: {
    url: string;
    title: string;
    displayOrder: string;
    resourceData: object;
    resourceType: string;
    learningDuration: string;
  };
}

export type CatalogSherpathCaseStudyExternalEntityDtoParsed = CatalogEvolveResourceExternalEntityDto & {
  _parsedData: {
    url: string;
    title: string;
    displayOrder: string;
    resourceData: object;
    resourceType: string;
    learningDuration: string;
  };
}

export type CatalogEvolveResourceExternalEntityDtoParsed = CatalogEvolveResourceExternalEntityDto & {
  _parsedData: {
    fileName: string;
    linkUrl: string;
    eolsContentPath?: string;
    permission: CatalogEvolveResourcePermission;
    subtitle: string;
    title: string;
    type: string;
    assetType: string;
  };
}

export type CatalogShadowHealthExternalEntityDto = CatalogExternalEntityBase & {
  entity: ResourceDto;
};

export type SherpathModuleExternalEntityDto = CatalogExternalEntityBase & {
  entity: string;
};

export type SherpathModuleExternalEntityDtoParsed = SherpathModuleExternalEntityDto & {
  _parsedData: SequenceDto;
};

export type CatalogShadowHealthExternalEntityDtoParsed = CatalogShadowHealthExternalEntityDto & {
  _parsedData: {
    assignmentSubjectTag: string;
    assignmentTitle: string;
    assignmentVersion: string;
    isOrientation: boolean;
    order: number;
  };
};

export type CatalogSherpathLessonExternalEntityDto = CatalogExternalEntityBase & {
  entity: {
    active: unknown;
    id: string;
    insertTimestamp: string;
    isActive: boolean;
    learningObjectList: unknown;
    resourceList: unknown;
    sloLessonList: unknown;
    topicList: unknown;
    updateTimestamp: string;
    vtwId: string;
    data: string;
  };
}

export type CatalogSherpathLessonExternalEntityDtoParsed = CatalogSherpathLessonExternalEntityDto & {
  _parsedData: {
    lessonDuration: number;
    title: string;
    osmosisVideos?: {
      osmosisVideoExternalId: string;
      pageVtwId: string;
      topicVtwId: string;
    }[];
  };
}

export type CatalogSherpathSkillExternalEntityDto = CatalogExternalEntityBase & {
  entity: {
    data: string;
  };
}

export type CatalogOsmosisVideoExternalEntityDto = CatalogExternalEntityBase & {
  entity: {
    id: string;
    insertTimestamp: string;
    updateTimestamp: string;
    data: string;
    vtwId: string;
  };
}

export type CatalogInteractiveReviewExternalEntityDto = CatalogExternalEntityBase & {
  entity: {
    data: string;
    id: string;
    insertTimestamp: string;
    resourceType: string;
    updateTimestamp: string;
    vtwId: string;
  };
}

export type CatalogSherpathSkillExternalEntityDtoParsed = CatalogSherpathSkillExternalEntityDto & {
  _parsedData: {
    title: string;
    keyId: string;
    lessonDuration: number;
  };
}

export type CatalogSherpathSimulationExternalEntityDto = CatalogExternalEntityBase & {
  entity: {
    '@UUID': string;
    available: boolean;
    conceptKeywordLearningObjectList: unknown;
    conceptLearningObjectList: unknown;
    data: string;
    id: string;
    insertTimestamp: string;
    isAvailable: boolean;
    sloLearningObjectList: unknown;
    updateTimestamp: string;
    vtwId: string;
  };
}

export type CatalogSherpathSimulationExternalEntityDtoParsed = CatalogSherpathSimulationExternalEntityDto & {
  _parsedData: {
    title: string;
    contentBundle: {
      contentType: string;
      filename: string;
      type: string;
      vtwId: string;
    }[];
    keywords: unknown[];
    learningDuration: number;
    learningObjectType: string;
    learningStyle: string;
    metaData: unknown[];
  };
}

export type CatalogSimChartExternalEntityDto = CatalogExternalEntityBase & {
  entity: string;
}

export type CatalogSimChartExternalEntityDtoParsed = CatalogSimChartExternalEntityDto & {
  _parsedData: {
    title: string;
    metadata: {
      tags: [];
      keyValues: [];
      taxonomies: {
        category: {
          id: number;
          title: string;
          parentID: number;
        }[];
        categoryTree: {
          id: number;
          title: string;
        };
      }[];
    };
    lessonDuration: number;
  };
}

export type CatalogOsmosisVideoExternalEntityDtoParsed = CatalogOsmosisVideoExternalEntityDto & {
  _parsedData: {
    title: string;
    videoDuration: string;
  };
}

export type CatalogInteractiveReviewExternalEntityDtoParsed = CatalogInteractiveReviewExternalEntityDto & {
  _parsedData: {
    questionIdList: string[];
    title: string;
    topicVtwId: string;
  };
}

export type CatalogExternalEntityBase = {
  catalogItemId: string;
}

export type CatalogExternalEntityDto = CatalogAdaptiveLessonExternalEntityDto
  | CatalogEvolveResourceExternalEntityDto
  | CatalogShadowHealthExternalEntityDto
  | CatalogSherpathLessonExternalEntityDto
  | CatalogSherpathSkillExternalEntityDto
  | CatalogSherpathSimulationExternalEntityDto
  | CatalogSimChartExternalEntityDto
  | CatalogOsmosisVideoExternalEntityDto
  | CatalogInteractiveReviewExternalEntityDto;

export type CatalogExternalEntityDtoParsed = CatalogAdaptiveLessonExternalEntityDto
  | CatalogEvolveResourceExternalEntityDtoParsed
  | CatalogShadowHealthExternalEntityDtoParsed
  | CatalogSherpathLessonExternalEntityDtoParsed
  | CatalogSherpathSkillExternalEntityDtoParsed
  | CatalogSherpathSimulationExternalEntityDtoParsed
  | CatalogSherpathPowerpointExternalEntityDtoParsed
  | CatalogSherpathGroupActivityExternalEntityDtoParsed
  | CatalogSherpathCaseStudyExternalEntityDtoParsed
  | CatalogSimChartExternalEntityDtoParsed
  | CatalogOsmosisVideoExternalEntityDtoParsed
  | CatalogInteractiveReviewExternalEntityDtoParsed;

export enum CatalogEvolveResourcePermission {
  STUDENT = 'student',
  INSTRUCTOR = 'instructor'
}

export interface CatalogWithExternalEntitiesDto {
  catalog: RecCatalogDto;
  externalEntities: CatalogExternalEntityDto[];
}

export type AccessTokenRequestDto = {
  userId: number;
  courseId: number;
  sessionId: string;
  appParams: {
    isbns: string[];
    appId: string;
    authorizedIsbns: string[];
  };
}

export type NewTokenDto = {
  cookies: object[];
  expireDate: string;
  token: string;
  userRoles: unknown;
}

export enum SherpathContentTypeDto {
  CASE_STUDY = 'case-study',
  POWERPOINT = 'powerpoint',
  GROUP_ACTIVITY = 'group-activity',
  SKILL = 'skill',
  SIMULATION = 'simulation',
  LESSON = 'lesson',
  EBOOK = 'ebook',
}

export interface SherpathClassicContentItemDto {
  assessment: unknown;
  content: {
    contentDetail: {
      description: string;
      id: number;
      overrides: string;
    };
    data: string;
    dueDate: unknown;
    gradePoints: unknown;
    gradeType: unknown;
    id: number;
    startDate: string; // "2022-02-15T14:07:15.945+0000"
    type: 'resource' | 'ebook';
    vtwId: string;
  };
  displayOrder: number;
  gradeType: unknown;
  id: number;
  sendResultToGradebook: unknown;
  type: SherpathContentTypeDto;
}

export interface SherpathClassicModuleDto {
  assessment: {
    answersRandomized: boolean;
    createdAt: unknown;
    dueDate: string; // "2022-02-18T04:59:00.000+0000"
    estimatedTime: unknown;
    examStartDate: string; // "2022-02-15T14:08:13.805+0000"
    gradePoints: number;
    gradeType: string;
    id: number;
    questions: unknown[];
    questionsRandomized: boolean;
    sendResultToGradebook: boolean;
    startDate: string; // "2022-02-14T05:00:00.000+0000"
    title: string;
    type: 'instructor_created';
    updatedAt: unknown;
  };
  content: {
    id: number;
    vtwId: string;
    type: 'module';
    startDate: unknown;
    dueDate: unknown;
    contentDetail: unknown;
    data: string;
    gradePoints: unknown;
    gradeType: unknown;
  };
  contentScheduleDetail: SherpathClassicContentItemDto[];
  displayOrder: number;
  id: number;
  isOriginal: boolean;
  scheduleId: number;
}

export interface CourseCopyPreviewDto {
  resources: SherpathClassicModuleDto[];
  syllabusItemResponse: CourseManagementSyllabusItemsDto;
  syllabusItemsNotToBeCopied: SyllabusItemDto[];
  retiredCatalogSyllabusItems: SyllabusItemDto[];
}

export type OsmosisTokenDto = {
  authToken: string;
  expires: string;
}
