import moment from 'moment';
import {
  get,
} from 'lodash';
import {
  CatalogWithExternalEntitiesDto,
  SyllabusAssignmentDto
} from '../../apis/sherpath-course-management-service/sherpath-course-management-service.dtos';
import {
  getLocalDateFromServicesUTC,
  isValidDate
} from '../../utilities/app.utilities';
import {
  SHERPATH_LESSON_EDITOR_PAGE_TITLE,
  SherpathLessonDefaultSteps,
  SherpathLessonStep,
} from './sherpath-lesson.constants';
import {
  BaseAssignmentEditorProps,
  BaseAssignmentEditorState
} from '../../hocs/with-base-assignment-editor/withBaseAssignmentEditor.hoc';
import {
  getDefaultAssignmentEditorState,
  getTopicIdFromVtwId,
  updateStepsOnNav,
} from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.utilities';
import { AssignmentFormError } from '../../hocs/with-base-assignment-editor/with-base-assignment-editor.models';
import {
  AssignmentDto,
  AssignmentType
} from '../../apis/eols-assessment-service/eols-assessment-service.dtos';
import { RecContentItemDto } from '../../apis/rec-gateway/rec-gateway.dtos';
import { PrimaryTaxonomy } from '../../apis/rec-gateway/rec-gateway.models';
import { SherpathLessonTopic } from '../../apis/ocs-api-service/ocs-api-service.dtos';
import {
  getSubtitle,
  getTitle
} from '../catalog/catalog.utilities';
import {
  MAX_ASSIGNMENT_TITLE_CHAR_LENGTH
} from '../../components/assignment-editor/assignment-editor.constant';
import { MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH } from '../course-plan/syllabus.constants';
import { getBooleanFromGroupFeatureFlagWithFallbackToGlobal } from '../../utilities/featureFlag.utilities';
import { FEATURE_FLAG } from '../../apis/eols-features-api/eols-features-api.constants';
import { isGraded, isValidGradePoints } from '../../components/assignment-editor/assignment-editor.utilities';
import { EvolveProductDto } from '../../apis/sherpath-app-facade-service/sherpath-app-facade-service.dtos';

export const getSherpathLessonDefaultState = () => {
  return {
    ...getDefaultAssignmentEditorState(),
    assignmentType: AssignmentType.LESSONS,
    pageTitle: SHERPATH_LESSON_EDITOR_PAGE_TITLE,
    hasDueDate: true,
    showDueDate: true,
    showGrading: true,
    showDisplayName: true,
    steps: updateStepsOnNav(SherpathLessonDefaultSteps, 0),
    isReadOnlyDisplayName: false
  };
};

export const validateSherpathLesson = (
  syllabusAssignmentDto: SyllabusAssignmentDto,
  baseState: BaseAssignmentEditorState,
  baseProps: BaseAssignmentEditorProps
): AssignmentFormError[] => {
  const { messages } = baseProps;
  const errors = [];

  const assignmentGoals = get(syllabusAssignmentDto, 'assignment.assignmentGoals', []);

  if (!assignmentGoals.length) {
    errors.push({
      message: messages.ASSIGNMENT_CONTENT_ID_IS_EMPTY,
      stepId: SherpathLessonStep.STEP1
    });
  }

  if (!syllabusAssignmentDto.assignment.title || !syllabusAssignmentDto.assignment.title.length) {
    errors.push({
      message: messages.ASSIGNMENT_TITLE_IS_EMPTY,
      stepId: SherpathLessonStep.STEP1
    });
  }

  const availableDate = moment(syllabusAssignmentDto.assignment.availableDate).toDate();
  if (baseState.hasAvailableDate && !isValidDate(availableDate)) {
    errors.push({
      message: messages.INVALID_AVAILABILITY_DATE,
      stepId: SherpathLessonStep.STEP1
    });
  }

  const dueDate = moment(syllabusAssignmentDto.assignment.dueDate).toDate();
  if (baseState.hasDueDate && !isValidDate(dueDate)) {
    errors.push({
      message: messages.INVALID_DUE_DATE,
      stepId: SherpathLessonStep.STEP1
    });
  }

  if (baseState.hasDueDate
    && moment(getLocalDateFromServicesUTC(syllabusAssignmentDto.assignment.dueDate))
      .isSameOrBefore(moment(getLocalDateFromServicesUTC(syllabusAssignmentDto.assignment.availableDate)))) {
    errors.push({
      message: messages.DUE_DATE_BEFORE_AVAILABLE_DATE_ERROR_MESSAGE,
      stepId: SherpathLessonStep.STEP1
    });
  }

  if (
    baseState.assignment
    && baseState.assignment.title
    && baseState.assignment.title.length > MAX_ASSIGNMENT_TITLE_CHAR_LENGTH
  ) {
    errors.push({
      message: messages.ENTRY_EXCEEDS_LENGTH_LIMIT,
      stepId: SherpathLessonStep.STEP1,
    });
  }

  const {
    featureFlagsGrouped,
    courseSectionId
  } = baseProps;

  const showGradePoints = featureFlagsGrouped && courseSectionId ? getBooleanFromGroupFeatureFlagWithFallbackToGlobal(
    featureFlagsGrouped,
    FEATURE_FLAG.IS_CUSTOM_GRADE_POINTS_ENABLED,
    courseSectionId
  ) : false;

  if (
    baseState.assignment
    && showGradePoints
    && isGraded(baseState.assignment)
    && !isValidGradePoints(baseState.assignment.gradePoints)
  ) {
    errors.push({
      message: messages.INVALID_GRADE_POINTS,
      stepId: SherpathLessonStep.STEP1,
    });
  }

  return errors;
};

export const getSherpathLessonAssignmentInfo = (
  catalog: CatalogWithExternalEntitiesDto,
  contentItem: RecContentItemDto,
  primaryTaxonomies: PrimaryTaxonomy[],
  assignment: AssignmentDto,
  lessonTopics: string[],
  evolveProducts: EvolveProductDto[]
): {
  assignmentTopics: AssignmentDto['assignmentTopics'];
  assignmentGoals: AssignmentDto['assignmentGoals'];
  title: AssignmentDto['title'];
  subtitle: AssignmentDto['subtitle'];
  contentId: AssignmentDto['contentId'];
} => {
  const assignmentTopics: AssignmentDto['assignmentTopics'] = lessonTopics.map((item) => {
    const topic: SherpathLessonTopic = JSON.parse(item);
    return {
      id: getTopicIdFromVtwId(assignment, topic.topicId),
      text: topic.topicTitle,
      vtwId: topic.topicId
    };
  });

  return {
    assignmentTopics,
    assignmentGoals: [{
      id: null,
      goal: 100,
      vtwId: contentItem ? contentItem.attributes.contentId : null,
      text: null,
    }],
    title: contentItem ? getTitle(contentItem, catalog, primaryTaxonomies, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : null,
    subtitle: contentItem ? getSubtitle(contentItem, catalog, primaryTaxonomies, evolveProducts, MAX_SYLLABUS_ITEM_TITLE_CHAR_LENGTH) : null,
    contentId: contentItem ? contentItem.attributes.contentId : null
  };
};
